import React, { useState } from 'react'
import clsx from 'clsx';
import Button from '../../../button';
import { toMoney } from 'vanilla-masker'
import api from '../../../../services/api';
import Loader from "react-loader-spinner";
import toast from 'react-hot-toast';

const PlansSection = (props) => {
  const [isCancelingSubscription, setIsCancelingSubscription] = useState(false)

  const confirmCancelHandler = ()  => {
    props.setShowConfirmCancelPlan(true)
  }

  const dismissCancelHandler = ()  => {
    props.setShowConfirmCancelPlan(false)
  }

  const cancelPlanHandler = async () => {
    try {
      setIsCancelingSubscription(true)
      
      const hash = window.localStorage.getItem('@cov/hash')
      await api.delete(`/app/plano/${hash}`)

      if (props.isUserInFreeTrial) {
        if (window.in_native_app) {
          window.ReactNativeWebView.postMessage(JSON.stringify({
            type: 'CLEAR_DATABASE',
          }))
        }
      }

      dismissCancelHandler()
    } catch {
      toast.error("Não foi possível cancelar sua assinatura, por favor tente novamente mais tarde.")
    } finally {
      setIsCancelingSubscription(false)
    }
  }

  if (props.showConfirmCancelPlan) {
    return (
      <div className='w-full'>
        <h3 className='font-semibold'>Minha assinatura</h3>
        <div className='p-4 mt-4 border rounded-lg border-slate-300'>
          <h4 className='text-2xl font-bold'>Cancelar assinatura</h4>
          <p>Tem certeza que deseja cancelar sua assinatura?</p>
          {props.isUserInFreeTrial ? (
            <p className='mt-1'>Ao cancelar sua assinatura, todos os livros baixados serão removidos.</p>
          ): null}

          <div className='w-full flex flex-row justify-center items-center mt-8 space-x-4'>
            <button
              disabled={isCancelingSubscription}
              onClick={cancelPlanHandler}
              type="button"
              className="w-44 underline text-green-primary bg-transparent focus:ring-0 rounded-full text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none font-bold"
            >
              {isCancelingSubscription ? (
                <Loader type="Oval" color="#000000" height={24} width={24} />
              ) : "Sim"}
            </button>

            <div className='w-44'>
              <Button onClick={dismissCancelHandler}>Não</Button>
            </div>
          </div>

          <p className='mt-2 text-sm'>Ao cancelar sua assinatura, ela permanecerá ativa até o fim do período vigente, mas não será renovada posteriormente.</p>
        </div>
      </div>
    )
  }

  return (
    <div className='w-full'>
      <h3 className='font-semibold'>Minha assinatura</h3>
      <div className="w-full grid grid-cols-1 gap-x-4 text-sm mt-4">
        <div className={clsx("w-full bg-slate-200 rounded-lg border-2 border-transparent flex flex-col justify-start items-stretch p-4", props.currentPlanId && "border-green-primary")}>
          <div className='flex flex-col justify-center items-center space-y-2'>
            <div className="flex flex-row justify-center items-center">
              <span className="font-bold text-lg mr-1">
                {toMoney(props.plan.valor, {
                  unit: 'R$'
                })}
              </span>
              <span className="font-bold text-sm">{`- ${props.plan.plano}`}</span>
            </div>

            {!!props.hasUserASubscription ? (
              <button
                onClick={confirmCancelHandler}
                className="w-full h-10 text-white bg-red-600 focus:ring-0 rounded-full text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none font-bold"
              >
                Cancelar
              </button>
            ) : (
              <p className='font-bold text-sm text-center'>Para reativar sua assinatura, basta preencher o formulário abaixo.</p>
            )}

          </div>

          {props.plan?.beneficios?.length ? (
            <div className="text-left mt-3">
              <p className="text-lg font-bold">Benefícios</p>

              <div className='grid grid-cols-2 gap-x-4'>
                {props.plan?.beneficios?.map(benefit => (
                  <div key={benefit.beneficio}>
                    <p className='text-xs'>{benefit.beneficio}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default PlansSection
