import React from "react";
import "./../../assets/styles/MainTitle.css";

const MainTitle = props => {
  const { setIsOpenSurprise, onSurpriseComplete } = props;
  function handleClick() {
    setIsOpenSurprise(true);
    return setTimeout(onSurpriseComplete, 1500);
  }

  let BOOK = 0;
  const currentBookID = props.currentBookID;
  for (let i = 0; i < props.payload.listData.length; i++) {
    const element = props.payload.listData[i];
    if (element.bookId === currentBookID) {
      BOOK = i;
    }
  }

  const ITEM = props.payload.listData[BOOK];
  const isFirstVisit = props.appState === "firsttimevisit" ? true : false;
  const title = isFirstVisit ? "" : "Aproveite sua aventura";
  const subtitle = isFirstVisit ? " " : ITEM.bookMonthName;

  return (
    <div id="main_title" className="main_title">
      {props.isLoggedIn ? <h1 className="font-bold text-xl text-white mb-6">Olá, {props?.subscriberData?.nome ?? 'navegante'}</h1> : null}
      
      <p className="main_title_text">
        <span onClick={() => handleClick()} style={{ cursor:"pointer" }} className="text-white">
          {title}
        </span>
      </p>
      <p className="main_title_subtext capitalize text-black text-2xl">{subtitle.toLowerCase()}</p> 
    </div>
  );
};

export default MainTitle;
