import React, {  } from 'react';
import logoSvg from './../../assets/images/logo.svg'
import './../../assets/styles/Logo.css'


const Logo = ({appState}) => {
    return (
        <img className={["landingpage", "login", "registration"].includes(appState)  ? "main_logo": "logo_none" } src={logoSvg} /> 
    );
}

export default Logo;