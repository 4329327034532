import React, { Component, createRef } from 'react';
import api from './services/api';
import AuthPage from './components/_authpage';
import Error404 from './components/_error404';
import LandingPage from './components/_landingpage';
import SimpleLoader from './components/_loader';
import Reader from './components/_reader';
import DataManager from './services/dataManager';
import smoothscroll from 'smoothscroll-polyfill';
import './assets/styles/App.css';
import { isIOSDevice } from './utils/device';
import { Regulamento } from './components/regulamento';
import InternalSongs from './components/internalSongs';
import { BsX } from "react-icons/bs";
import { pauseSong, playSong } from './utils/song';
import songsName from './enums/songsName';
import Footer from './components/footer';
import toast, { Toaster } from 'react-hot-toast';
import { useNetworkStatus } from './hooks/useNetworkStatus';
import Tutorial from './components/tutorial';
import clsx from 'clsx';

let DATA_MANAGER = DataManager;
const START_DELAY = 2000;
const currentPathname = window.location.pathname;
const IS_REGULATION = currentPathname === '/regulamento';
const HAS_SUBDOMAIN = currentPathname !== '/' && currentPathname.length > 1;

class AppOld extends Component {
	constructor(props) {
		super(props);
		this.state = {
			subscriberData: {
				nome: window.localStorage.getItem('@cov/nome'),
				telefone: window.localStorage.getItem('@cov/telefone'),
				email: window.localStorage.getItem('@cov/email'),
			},
			loginProvider: window.localStorage.getItem('@cov/loginProvider'),
			hasUserASubscription: false,
			isUserInFreeTrial: false,
			appState: 'inicializing',
			reader: false,
			installButton: false,
			isIos: false,
			showGames: false,
			gameManagerUri: null,
			iframeHeight: '40rem',
			offlineBooksIds: new Set(),
			downloadingBooksIds: new Set(),
			lastDownloadedBooksIds: new Set(),
			offlineBook: null,
			currentBookId: null,
			currentSlideId: 0,
			isDrawerOpened: false,
			showAboutModal: false,
			showQuestionsModal: false,
			showContactUsModal: false,
			showProfileModal: false,
			showPaymentModal: false,
			showTermsOfUse: false,
			showPrivacyPolicy: false,
			showLoginModal: false,
			showRegistrationModal: false,
			showKnowMore: false,
			isLoggedIn: !!window.localStorage.getItem('@cov/hash'),
			openingBookId: null,
			isSongDisabled: true
		};

		this.internalSongsRef = createRef();
		this.iframeRef = createRef()

		// kick off the polyfill!
		smoothscroll.polyfill();
	}
	installPrompt = null;

	checkIfUserHasScubscription = async () => {
		try {
			const hash = window.localStorage.getItem('@cov/hash');
			if (hash) {
				const { data } = await api.get(`/app/plano/${hash}`)
				this.setState({
					isUserInFreeTrial: data.isFreeTrial,
					hasUserASubscription: true
				})
			}
		} catch {
			this.setState({
				hasUserASubscription: false
			})
		}
	}

	async componentDidMount() {
		const hash = window.location.hash;
		const search = window.location.search;

		this.checkIfUserHasScubscription()

		let page;

		if (hash?.length) {
			const urlParams = new URLSearchParams(hash.substring(1));
			page = urlParams.get("page")
		} else {
			const urlParams = new URLSearchParams(search);
			page = urlParams.get("page")
		}

		if (page === "cadastro") {
			this.setState({
				showRegistrationModal: true
			})

		}

		if (HAS_SUBDOMAIN) {
			const token = currentPathname.replace(/[/"]/g, '');
			await window.localStorage.setItem('@cov/hash', token);
			this.setState({
				isLoggedIn: true
			})
		}

		this.checkToOpenGames()
		this.inicialize();

		if (window.in_native_app) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				type: 'GET_SAVED_BOOKS_IDS',
			}))
		}

		document.addEventListener('message', event => {
			let data = event.data

			if (typeof data === 'string') {
				data = JSON.parse(data)
			}

			if (data.type === 'BACK_BUTTON_PRESSED') {
				this.setState({
					reader: false,
					isDrawerOpened: false,
					showAboutModal: false,
					showQuestionsModal: false,
					showContactUsModal: false,
					showProfileModal: false,
					showPaymentModal: false,
					showTermsOfUse: false,
					showPrivacyPolicy: false,
					showLoginModal: false,
					showRegistrationModal: false,
				})

				if (this.state.showGames) {
					this.changeURL('#')
				}
			}

			if (data.type === 'UPDATE_ONLINE_STATUS') {
				this.props.updateOnlineStatus(data.payload)
			}

			if (data.type === 'OFFLINE_BOOK_SAVED') {
				toast('Seu livro foi baixado com sucesso!');

				this.setState(prevState => {
					const downloadingBooksIds = Array.from(prevState.downloadingBooksIds).filter(id => id !== data.payload.id)
					return {
						downloadingBooksIds: new Set(downloadingBooksIds),
						offlineBooksIds: new Set(prevState.offlineBooksIds).add(data.payload.id),
					}
				})

				const token = window.localStorage.getItem('@cov/hash')
				api.post('/app/acao', {
					assinantesid: token,
					livrosid: data.payload.id,
					baixado: 1
				})

				if (this.state.currentBookId === data.payload.id) {
					window.ReactNativeWebView.postMessage(JSON.stringify({
						type: 'GET_OFFLINE_BOOK',
						payload: {
							id: data.payload.id
						}
					}))
				}
			}

			if (data.type === 'DOWNLOAD_BOOK_ERROR') {
				toast(`Falha ao baixar o livro ${data.payload.bookTitle}, por favor tente novamente mais tarde`, {
					duration: 5000
				});

				this.setState(prevState => {
					const downloadingBooksIds = Array.from(prevState.downloadingBooksIds).filter(id => id !== data.payload.id)
					return {
						downloadingBooksIds: new Set(downloadingBooksIds)
					}
				})
			}

			if (data.type === 'OFFLINE_BOOK') {
				if (window._tempData && window._tempData[data.payload.id]) {
					this.setState(prevState => ({
						offlineBook: data.payload.id !== prevState.currentBookId ? null : window._tempData[data.payload.id],
						openingBookId: null
					}))

					if (data.payload.shouldOpenBook == true) {
						this.changeReader(data.payload.id)
					}
				}
			}

			if (data.type === 'SAVED_BOOKS_IDS') {
				this.setState(prevState => {
					const offlineBooksIds = new Set(prevState.offlineBooksIds)
					data.payload.booksIds.forEach(id => offlineBooksIds.add(id));

					return {
						offlineBooksIds,
					}
				});
			}

			if (data.type === 'UPDATE_SAVED_BOOKS_IDS') {
				this.setState(() => {
					const offlineBooksIds = new Set()
					data.payload.booksIds.forEach(id => offlineBooksIds.add(id));

					return {
						offlineBooksIds,
					}
				});
			}
		})

		window.addEventListener('message', (event) => {
			let data = event.data

			if (typeof data === 'string') {
				data = JSON.parse(data)
			}

			if (data.type === 'ADJUST_SIZE') {
				const offset = 80
				const newHeight = data.payload + offset
				this.setState({ iframeHeight: `${newHeight}px` })
			}

			if (data.type === 'ADJUST_SCROLL') {
				this.alignScroll()
			}

			if (data.type === 'CHANGE_URL') {
				let url = '#jogos'

				if (data.payload) {
					url = `#jogos-${data.payload}`
				}

				if (data.payload) {
					pauseSong(songsName.MAIN_BACKGROUND)
				} else {
					playSong(songsName.MAIN_BACKGROUND)
				}

				this.changeURL(url)
			}

			if (data.type === 'FAILED_TO_LOAD_GAMES') {
				setTimeout(() => {
					toast('Desculpe, tivemos um problema para carregar os jogos!', {
						duration: 5000,
					})

					this.closeGameHandler()
				}, 1000)
			}

			if ('scrollRestoration' in window.history) {
				window.history.scrollRestoration = 'manual';
			}
		})

		const originalPushState = window.history.pushState;
		window.history.pushState = (...args) => {
			originalPushState.apply(window.history, args);
			window.dispatchEvent(new Event('pushstate'));
		};

		window.addEventListener('popstate', this.onUrlChange);
		window.addEventListener('pushstate', this.onUrlChange);
	}

	checkToOpenGames() {
		const name = window.location.hash.replace('#', '')

		if (name.includes('#jogos')) {
			if (!this.state.showGames) {
				this.openGameHandler()
			}

			const game = name.split('-')[1]

			if (game) {
				this.iframeRef.current.addEventListener('load', () => {
					this.iframeRef.current.contentWindow.postMessage({
						type: 'CHANGE_PAGE',
						payload: game
					}, '*')
				})
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('popstate');
		window.removeEventListener('pushstate');

		if ('scrollRestoration' in window.history) {
			window.history.scrollRestoration = 'auto';
		}
	}

	onUrlChange = () => {
		const name = window.location.hash.replace('#', '')

		if (!name) {
			if (this.state.showGames) {
				this.closeGameManager()
			}
		} else if (name.includes('jogos')) {
			if (!this.state.showGames) {
				this.openGameManager()
			}

			setTimeout(() => {
				if (name === 'jogos') {
					this.iframeRef.current.contentWindow.postMessage({
						type: 'CHANGE_PAGE',
						payload: undefined
					}, '*')
				} else if (name.includes('-')) {
					const game = name.split('-')[1]
					this.iframeRef.current.contentWindow.postMessage({
						type: 'CHANGE_PAGE',
						payload: game
					}, '*')
				}
			}, 100)
		}
	}

	changeURL = (data) => {
		if (window.location.hash !== data) {
			window.history.pushState(null, null, data)
		}
	}

	installApp = async () => {
		if (!this.installPrompt) return false;
		this.installPrompt.prompt();
		let outcome = await this.installPrompt.userChoice;
		if (outcome.outcome == 'accepted') {
			console.log('App Installed');
		} else {
			console.log('App not installed');
		}
		// Remove the event reference
		this.installPrompt = null;
		// Hide the button
		this.setState({
			installButton: false
		});
	};

	handleOpenReader = (readerState) => {
		this.setState({ appState: 'reader' })
	};

	inicialize() {
		const hash = window.localStorage.getItem('@cov/hash')

		this.setState({
			appState: 'loading'
		});

		api
			.get(`/covportfolio/${hash ?? ''}`)
			.then((response) => {
				if (!'status' in response) {
					throw 'status {str} not found';
				}
				if (response.status === 200 || response.status === 201) {
					if ('listData' in response.data && response.data.listData.length === 0) {
						throw `listData {arr} not found`;
					}

					DATA_MANAGER = new DataManager(response.data);
					if (response.data.game) {
						this.setState({ gameManagerUri: response.data.gameURL })
					}
					return this.loadFromAutoStart();
				} else {
					throw `status: ${response.status}`;
				}
			})
			.catch((error) => {
				this.logout()
			});
	}

	logout = () => {
		window.localStorage.removeItem('@cov/hash');
		window.localStorage.removeItem('@cov/nome');
		window.localStorage.removeItem('@cov/telefone');
		window.localStorage.removeItem('@cov/email');
		window.localStorage.removeItem('@cov/loginProvider')

		this.setState({
			isLoggedIn: false
		})

		// if (window.in_native_app) {
		// 	window.ReactNativeWebView.postMessage(JSON.stringify({
		// 		type: 'CLEAR_DATABASE',
		// 	}))
		// }
	}

	loadFromLogin = () => {
		setTimeout(() => {
			this.setState({ appState: 'login' });
		}, START_DELAY);
	}

	loadFromAutoStart = () => {
		setTimeout(() => this.setState({ appState: 'firsttimevisit' }), START_DELAY);
	};

	onPortfolioLoaded = (data) => {
		DATA_MANAGER = new DataManager(data);
		if (data.game) {
			this.setState({ gameManagerUri: data.gameURL })
		}
	}

	loadFromSurpriseToLandingpage = () => {
		api
			.post(
				'/opencollection',
				{
					token: DATA_MANAGER.token,
					mes: DATA_MANAGER.currentMonthName,
					ano: DATA_MANAGER.currentYear,
					produto: 'L'
				},
				{
					timeout: 20000,
					headers: {
						'Content-Type': 'application/json'
					}
				}
			)
			.then((success) => { })
			.catch((error) => { })
			.finally(() => {
				this.setState({ appState: 'landingpage' }, () => {
					this.changeURL('#')
				});
			});
	};

	loadFromSurpriseToLandingpageDirect = () => {
		setTimeout(() => this.setState({ appState: 'landingpage' }, () => {
			this.changeURL('#')
		}), 100);
	};

	loadFromBoatToLandingpageDirect = () => {
		setTimeout(() => this.setState({ appState: 'landingpage' }, () => {
			this.changeURL('#')
			// It's required for cache getportfolio api request, looks the first request isn't cached by service worker
			const hash = window.localStorage.getItem('@cov/hash');
			api.get(`/covportfolio/${hash ?? ''}`)

			if (window.in_native_app) {
				window.ReactNativeWebView.postMessage(JSON.stringify({
					type: 'LOADED',
				}))
			}
		}), 1500);
	};

	loadFromCredentialsPayload = (payload) => {
		DATA_MANAGER = new DataManager(payload);

		if (payload.game) {
			this.setState({ gameManagerUri: payload.gameURL })
		}

		window.localStorage.setItem('@cov/hash', DATA_MANAGER.token);
		this.setState({
			isLoggedIn: true
		})

		// rola scroll ate o topo
		window.scrollTo(0, 0);
	};

	toggleFullScreen() {
		var doc = window.document;
		var docEl = doc.documentElement;

		var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
		var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

		if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
			requestFullScreen.call(docEl);
		}
		else {
			cancelFullScreen.call(doc);
		}
	}

	changeReader = (bookId) => {
		if (!this.state.reader) {
			this.setState({ reader: bookId }, () => {
				if (window.in_native_app) {
					window.ReactNativeWebView.postMessage(JSON.stringify({
						type: 'OPEN_READER',
					}))
				}

				if (!isIOSDevice && !window.in_native_app) {
					this.toggleFullScreen()
				}
			});
		} else {
			this.setState({ reader: false }, () => {
				if (window.in_native_app) {
					window.ReactNativeWebView.postMessage(JSON.stringify({
						type: 'CLOSE_READER',
					}))
				}

				if (!isIOSDevice && !window.in_native_app) {
					!!document.fullscreenElement && this.toggleFullScreen()
				}
			});
		}
	};

	disableInstallButton = () => {
		this.setState({ installButton: false });
	};

	alignScroll = () => {
		const targetView = document.getElementById('game-manager');
		const targetPosition = targetView.getBoundingClientRect().top + window.pageYOffset;
		window.scrollTo({
			left: 0,
			top: targetPosition,
		});
	}

	openGameManager = () => {
		this.setState({ showGames: true })

		setTimeout(() => {
			playSong(songsName.OPEN_GAME)
			const gameManagerElement = window.document.getElementById('game-manager-wrapper')
			gameManagerElement.style.display = 'block'
			setTimeout(() => {
				gameManagerElement.style.opacity = 1
			}, 200)

			const ladingPageElement = window.document.getElementById('ladingpage')
			setTimeout(() => {
				ladingPageElement.style.display = 'none'
				ladingPageElement.style.opacity = 0
			}, 600)
		}, 200)
	}

	closeGameManager = () => {
		const ladingPageElement = window.document.getElementById('ladingpage')
		ladingPageElement.style.display = 'block'
		ladingPageElement.style.opacity = 1

		const gameManagerElement = window.document.getElementById('game-manager-wrapper')
		gameManagerElement.style.opacity = 0

		setTimeout(() => {
			gameManagerElement.style.display = 'none'
		}, 700)

		setTimeout(() => {
			playSong(songsName.CLOSE_GAME)
			this.setState({ showGames: false })
		}, 200)

		window.scrollTo(0, 0)
	}

	openGameHandler = () => {
		this.changeURL('#jogos')
	}

	closeGameHandler = () => {
		this.changeURL('#')
	}

	downloadBook = (bookId, book) => {
		toast('Seu livro está sendo baixado. Não feche o app', {
			duration: 5000
		})

		const num_pages = book.num_pages || 0;
		const path = book.path || "https://..../";
		const prefix = book.prefix || "img_";
		const type = book.formato || "jpg";

		const pages = Array.from({ length: num_pages }).map((_, index) => `${index < 10 ? "0" : ""}${index}`)

		const images = pages.map(id => `${path}${prefix}${id}.${type}`)

		const bookAudioPath = book.bookAudiopath
		const bookAudioPrefix = book.bookPrefixAudio
		const bookAudioFormat = book.bookFormatAudio
		const bookAudioPages = book.bookAudiopages.split(",")
		const bookAudioPagesFx = book.bookAudiopagesfx?.split(";") ?? []

		const audios = bookAudioPages.map((id) => {
			return {
				id,
				audio: `${bookAudioPath}${bookAudioPrefix}_${id}.${bookAudioFormat}`
			}
		})

		const fx = bookAudioPagesFx?.map(item => {
			const id = item.split("=")[0]
			const letters = item.split("=")[1].split(",")

			const fxItems = letters.map(letter => {
				return `${bookAudioPath}${bookAudioPrefix}_${id}_${letter}.${bookAudioFormat}`
			})

			return {
				id,
				audios: fxItems
			}
		})

		const payload = {
			type: 'SAVE_BOOK_OFFLINE',
			payload: {
				id: bookId,
				bookTitle: book.bookTitle,
				images,
				audios,
				fx
			}
		}

		window.ReactNativeWebView.postMessage(JSON.stringify(payload));

		this.setState(prevState => ({
			downloadingBooksIds: new Set(prevState.downloadingBooksIds).add(bookId)
		}))
	}

	removeDownloadedBook = (book) => {
		const payload = {
			type: 'REMOVE_DOWNLOADED_BOOK',
			payload: {
				id: book.bookId,
				bookTitle: book.bookTitle,
			}
		}

		window.ReactNativeWebView.postMessage(JSON.stringify(payload));

		const token = window.localStorage.getItem('@cov/hash')
		api.post('/app/acao', {
			assinantesid: token,
			livrosid: book.bookId,
			deletado: 1
		})
	}

	changeCurrentBookId = (bookdId) => {
		this.setState(prevState => ({
			offlineBook: prevState.offlineBook && prevState.offlineBook.id !== bookdId ? null : prevState.offlineBook,
			currentBookId: bookdId
		}))
	}

	changeToLogin = () => {
		this.setState({
			appState: 'login'
		})
	}

	changeToRegister = () => {
		this.setState({
			appState: 'registration'
		})
	}

	openBookFromDownloaded = (currentBookId) => {
		this.setState({
			currentBookId,
			openingBookId: currentBookId
		})

		window.ReactNativeWebView.postMessage(JSON.stringify({
				type: 'GET_OFFLINE_BOOK',
				payload: {
					id: currentBookId,
					shouldOpenBook: true
				}
		}))
	}

	changeCurrentSlideId = (idx) => {
		this.setState({
			currentSlideId: idx
		})
	}

	openDrawerHandler = () => {
		this.setState({
			isDrawerOpened: true
		})
	}

	closeDrawerHandler = () => {
		this.setState({
			isDrawerOpened: false
		})
	}

	openAboutModalHandler = () => {
		this.closeDrawerHandler()

		this.setState({
			showAboutModal: true
		})
	}

	closeAboutModalHandler = () => {
		this.setState({
			showAboutModal: false
		})
	}

	openQuestionsModalHandler = () => {
		this.closeDrawerHandler()

		this.setState({
			showQuestionsModal: true
		})
	}

	closeQuestionsModalHandler = () => {
		this.setState({
			showQuestionsModal: false
		})
	}

	openContactUsModalHandler = () => {
		this.closeDrawerHandler()

		this.setState({
			showContactUsModal: true
		})
	}

	closeContactUsModalHandler = () => {
		this.setState({
			showContactUsModal: false
		})
	}

	openProfileModalHandler = () => {
		this.closeDrawerHandler()

		this.setState({
			showProfileModal: true
		})
	}

	closeProfileModalHandler = () => {
		this.setState({
			showProfileModal: false
		})
	}

	openPaymentModalHandler = () => {
		this.closeDrawerHandler()

		this.setState({
			showPaymentModal: true
		})
	}

	closePaymentModalHandler = () => {
		this.setState({
			showPaymentModal: false
		})
	}

	openTermsOfUseModalHandler = () => {
		this.closeDrawerHandler()

		this.setState({
			showTermsOfUse: true
		})
	}

	closeTermsOfUseModalHandler = () => {
		this.setState({
			showTermsOfUse: false
		})
	}

	openPrivacyPolicyModalHandler = () => {
		this.closeDrawerHandler()

		this.setState({
			showPrivacyPolicy: true
		})
	}

	closePrivacyPolicyModalHandler = () => {
		this.setState({
			showPrivacyPolicy: false
		})
	}

	openLoginModalHandler = () => {
		this.closeDrawerHandler()

		this.setState({
			showLoginModal: true
		})
	}

	closeLoginModalHandler = () => {
		this.setState({
			showLoginModal: false
		})
	}

	openRegistrationModalHandler = () => {
		this.closeDrawerHandler()
		
		this.setState({
			showRegistrationModal: true
		})
	}

	closeRegistrationModalHandler = () => {
		this.setState({
			showRegistrationModal: false
		})
	}

	openRegistrationFromLoginModal = () => {
		this.setState({
			showLoginModal: false,
			showRegistrationModal: true
		})
	}

	openLoginFromRegistrationModal = () => {
		this.setState({
			showRegistrationModal: false,
			showLoginModal: true
		})
	}

	openKnowMore = () => {
		this.closeDrawerHandler()

		this.setState({
			showKnowMore: true
		})
	}

	closeKnowMore = () => {
		this.setState({
			showKnowMore: false
		})
	}

	backToDrawerMenu = () => {
		this.setState({
			showAboutModal: false,
			showQuestionsModal: false,
			showContactUsModal: false,
			showProfileModal: false,
			showPaymentModal: false,
			showTermsOfUse: false,
			showPrivacyPolicy: false,
			showLoginModal: false,
			showRegistrationModal: false,
			showKnowMore: false,
			isDrawerOpened: true,
		})
	}

	toggleBackgroundSong = () => {
		this.internalSongsRef.current.toggleBackgroundSong()
	}

	setIsSongDisabled = (isDisabled) => {
		this.setState({
			isSongDisabled: isDisabled
		})
	}

	updateSubscriberData = (data) => {
		this.setState({ subscriberData: data })
		this.checkIfUserHasScubscription()

		window.localStorage.setItem('@cov/nome', data.nome);
		window.localStorage.setItem('@cov/telefone', data.telefone);
		window.localStorage.setItem('@cov/email', data.email);
	}

	updateLoginProvider = (provider) => {
		console.log('Login provider', provider)
		this.setState({
			loginProvider: provider
		})

		window.localStorage.setItem('@cov/loginProvider', provider)
	}

	render() {
		return (
			<div className="App" id="App">
				{
					IS_REGULATION
						?
						<Regulamento />
						:
						<>
							<SimpleLoader appState={this.state.appState} />

							<Error404 appState={this.state.appState} />

							<AuthPage
								appState={this.state.appState}
								startDelay={START_DELAY}
								payload={{}}
								onRefresh={this.loadFromCredentialsPayload}
								onError={this.loadFromError}
							/>


							{this.state.gameManagerUri && this.state.showGames ? (
								<div id="game-manager-wrapper" className='section_background_landingpage'>
									<div
										className='game-manager-wrapper'
										style={{
											paddingTop: window.native_app_status_bar_height ?? 16,
											paddingBottom: 48
										}}
									>
										<button
											onClick={this.closeGameHandler}
											className={`fixed z-50 border-0 bg-slate-100 p-2 rounded-full right-4 flex flex-1 justify-center items-center shadow-lg`}
											style={{
												top: window.native_app_status_bar_height ?? 16
											}}
										>
											<BsX className='w-6 h-6' />
										</button>
										<iframe
											id="game-manager"
											ref={this.iframeRef}
											className='game-manager'
											title='Gerenciador de games'
											src={`${this.state.gameManagerUri}?product=L&token=${DATA_MANAGER.token}`}
											style={{ height: this.state.iframeHeight }}
										/>
										{/* <div className='game_manager_info_button'>
											<InfoButtons isLoggedIn={this.state.isLoggedIn} logout={this.logout} openLogin={this.openLoginHandler} closeGameManager={this.closeGameHandler} appState={this.state.appState} />
										</div> */}

										<div className='game_manager_footer'>
											<Footer />
										</div>
									</div>
								</div>
							) : null}

							<div id="ladingpage">
								<LandingPage
									openGames={this.openGameHandler}
									changeReader={this.changeReader}
									appState={this.state.appState}
									isReading={!!this.state.reader}
									changeState={this.loadFromSurpriseToLandingpage}
									changeToLandingPage={this.loadFromBoatToLandingpageDirect}
									changeToLogin={this.changeToLogin}
									changeToRegister={this.changeToRegister}
									startDelay={START_DELAY}
									payload={DATA_MANAGER}
									handleOpenReader={this.handleOpenReader}
									showGameBanner={!!this.state.gameManagerUri}
									offlineBooksIds={Array.from(this.state.offlineBooksIds)}
									downloadBook={this.downloadBook}
									downloadingBooksIds={Array.from(this.state.downloadingBooksIds)}
									lastDownloadedBooksIds={Array.from(this.state.lastDownloadedBooksIds)}
									changeCurrentBookId={this.changeCurrentBookId}
									onLoginSuccess={this.loadFromCredentialsPayload}
									openBookFromDownloaded={this.openBookFromDownloaded}
									changeCurrentSlideId={this.changeCurrentSlideId}
									currentSlideId={this.state.currentSlideId}
									isDrawerOpened={this.state.isDrawerOpened}
									showAboutModal={this.state.showAboutModal}
									showQuestionsModal={this.state.showQuestionsModal}
									showContactUsModal={this.state.showContactUsModal}
									showProfileModal={this.state.showProfileModal}
									showPaymentModal={this.state.showPaymentModal}
									showTermsOfUse={this.state.showTermsOfUse}
									showPrivacyPolicy={this.state.showPrivacyPolicy}
									showLoginModal={this.state.showLoginModal}
									showRegistrationModal={this.state.showRegistrationModal}
									showKnowMore={this.state.showKnowMore}
									openDrawerHandler={this.openDrawerHandler}
									closeDrawerHandler={this.closeDrawerHandler}
									openAboutModalHandler={this.openAboutModalHandler}
									closeAboutModalHandler={this.closeAboutModalHandler}
									openQuestionsModalHandler={this.openQuestionsModalHandler}
									closeQuestionsModalHandler={this.closeQuestionsModalHandler}
									openContactUsModalHandler={this.openContactUsModalHandler}
									closeContactUsModalHandler={this.closeContactUsModalHandler}
									openProfileModalHandler={this.openProfileModalHandler}
									closeProfileModalHandler={this.closeProfileModalHandler}
									openPaymentModalHandler={this.openPaymentModalHandler}
									closePaymentModalHandler={this.closePaymentModalHandler}
									openTermsOfUseModalHandler={this.openTermsOfUseModalHandler}
									closeTermsOfUseModalHandler={this.closeTermsOfUseModalHandler}
									openPrivacyPolicyModalHandler={this.openPrivacyPolicyModalHandler}
									closePrivacyPolicyModalHandler={this.closePrivacyPolicyModalHandler}
									openLoginModalHandler={this.openLoginModalHandler}
									closeLoginModalHandler={this.closeLoginModalHandler}
									openRegistrationModalHandler={this.openRegistrationModalHandler}
									closeRegistrationModalHandler={this.closeRegistrationModalHandler}
									openRegistrationFromLoginModal={this.openRegistrationFromLoginModal}
									openLoginFromRegistrationModal={this.openLoginFromRegistrationModal}
									openKnowMore={this.openKnowMore}
									closeKnowMore={this.closeKnowMore}
									logout={this.logout}
									isLoggedIn={this.state.isLoggedIn}
									openingBookId={this.state.openingBookId}
									onPortfolioLoaded={this.onPortfolioLoaded}
									backToDrawerMenu={this.backToDrawerMenu}
									isSongDisabled={this.state.isSongDisabled}
									toggleBackgroundSong={this.toggleBackgroundSong}
									removeDownloadedBook={this.removeDownloadedBook}
									updateSubscriberData={this.updateSubscriberData}
									subscriberData={this.state.subscriberData}
									hasUserASubscription={this.state.hasUserASubscription}
									loginProvider={this.state.loginProvider}
									updateLoginProvider={this.updateLoginProvider}
									checkIfUserHasScubscription={this.checkIfUserHasScubscription}
									isUserInFreeTrial={this.state.isUserInFreeTrial}
								/>
							</div>

							<Reader
								reader={this.state.reader}
								changeReader={this.changeReader}
								offlineBook={this.state.offlineBook}
							/>

							<InternalSongs
								ref={this.internalSongsRef}
								appState={this.state.appState}
								isReading={!!this.state.reader}
								isSongDisabled={this.state.isSongDisabled}
								setIsSongDisabled={this.setIsSongDisabled}
								isShowingGames={this.state.gameManagerUri && this.state.showGames}
							/>
							<Toaster containerClassName={clsx("text-sm")} containerStyle={{ top: window.native_app_status_bar_height ?? 16 }} />
							<Tutorial />
						</>
				}
			</div>
		);
	}
}

const App = (props) => {
	const { updateOnlineStatus } = useNetworkStatus()

	return <AppOld {...props} updateOnlineStatus={updateOnlineStatus} />
}

export default App;
