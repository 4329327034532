import 'react-credit-cards-2/dist/es/styles-compiled.css';

import { useFormik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react'
import Cards from 'react-credit-cards-2';
import { toPattern, toNumber } from 'vanilla-masker'
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion'
import cardType from 'credit-card-type'
import Loader from 'react-loader-spinner'
import api from '../../../../services/api';
import axios from 'axios';
import { PRODUCT } from '../../../../consts/product';
import toast from 'react-hot-toast';

const CreditCardSection = (props) => {
  const [sectionToShow, setSectionToShow] = useState("card")
  const [focused, setFocused] = useState("number")

  const slideVariants = {
    hidden: { opacity: 0, transition: { duration: 0.2, delay: 0 } },
    visible: { opacity: 1, transition: { duration: 0.2, delay: 0.5 } },
    exit: { opacity: 0, transition: { duration: 0.2, delay: 0 } },
  };

  const changeSectionToShow = (sectionName) => () => {
    setSectionToShow(sectionName)
  }

  const { values, errors, touched, isValid, dirty, handleSubmit, setFieldValue, handleChange, handleBlur, isSubmitting } = useFormik({
    initialValues: {
      number: '',
      expiry: '',
      cvc: '',
      name: '',
      msisdn: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true)
        if (props.hasUserASubscription) {
          const hash = window.localStorage.getItem('@cov/hash')
          await api.delete(`/app/plano/${hash}`)
        }

        const [exp_month, exp_year] = values.expiry.split('/')
        const { data: cardResponse } = await axios.post('https://api.pagar.me/core/v5/tokens', {
          type: 'card',
          card: {
            number: values.number,
            holder_name: values.holderName,
            holder_document: values.taxpayer,
            exp_month: exp_month,
            exp_year: exp_year,
            cvv: values.cvc
          }
        }, {
          params: {
            appId: process.env.REACT_APP_PAGAR_ME_PUBLIC_API
          }
        })

        await api.post('/app/plano', {
          email: props.subscriberData.email,
          cardtoken: cardResponse.id,
          cpfcnpj: values.taxpayer,
          msisdn: `55${values.msisdn}`,
          valor: props.plan.valor,
          plano: props.plan.plano,
          produto: PRODUCT,
          billing_address: {
            line_1: `${values.publicPlace}, ${values.placeNumber}, ${values.neighborhood}`,
            zip_code: values.zipcode,
            city: values.city,
            state: values.state,
            country: "BR"
          }
        })
        
        await props.checkIfUserHasScubscription()

        if (props.hasUserASubscription) {
          toast.success("Cartão atualizado com sucesso! Nas próximas cobranças, utilizaremos o novo cartão.")
        } else {
          toast.success("Sua assinatura foi reativada com sucesso!")
        }
      } catch {
        if (props.hasUserASubscription) {
          toast.error("Ocorreu um problema ao atualizar seu cartão. Por favor, tente novamente mais tarde.")
        } else {
          toast.error("Ocorreu um problema ao tentar reativar sua assinatura. Por favor, tente novamente mais tarde.")
        }
      } finally {
        setSubmitting(false)
      }
    }
  })

  const onChangeCardNumber = (e) => {
    const value = toNumber(e.target.value ?? '')
    setFieldValue("number", value)
  }

  const maskedCardNumber = useMemo(() => {
    const cardNumber = values.number ?? ''
    const brand = cardType(cardNumber)?.[0]?.type

    if (brand === "diners-club") {
      return toPattern(cardNumber, "9999 999999 9999")
    }

    if (brand === "american-express") {
      return toPattern(cardNumber, "9999 999999 99999")
    }

    return toPattern(cardNumber, "9999 9999 9999 9999")
  }, [values.number])

  const onChangeExpiresDate = (e) => {
    setFieldValue("expiry", e.target.value ?? '')
  }

  const maskedExpiresDate = useMemo(() => {
    return toPattern(values.expiry ?? '', "99/99")
  }, [values.expiry])

  const onChangPlaceNUmber = (e) => {
    const value = toNumber(e.target.value ?? '')
    setFieldValue("placeNumber", value)
  }

  const onChangZipcode = (e) => {
    const value = toNumber(e.target.value ?? '')
    setFieldValue("zipcode", value)
  }

  const maskedZipcode = useMemo(() => {
    return toPattern(values.zipcode ?? '', "99999-999")
  }, [values.zipcode])

  const handleFocus = (e) => {
    setFocused(e.target.name)
  }

  const isNextDisabled = sectionToShow === "address" && !(isValid && dirty)

  const renderButtons = useCallback((isSubmitting) => {
    return (
      <div className='w-full flex flex-row items-center justify-between mt-4 space-x-4'>
        <button
          disabled={sectionToShow === "card" || isSubmitting}
          onClick={changeSectionToShow("card")}
          className={clsx(
            "w-full h-10 text-green-primary bg-transparent border border-green-primary focus:ring-0 rounded-full text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none font-bold",
            sectionToShow === "card" && "opacity-40 cursor-default",
            sectionToShow !== "card" && "opacity-100 cursor-pointer"
          )}
        >
          Voltar
        </button>

        <button
          disabled={isNextDisabled || isSubmitting}
          type={sectionToShow === "card" ? "button" : "submit"}
          onClick={sectionToShow === "card" ? changeSectionToShow("address") : undefined}
          className={clsx(
            "w-full h-10 text-white bg-green-primary focus:ring-0 rounded-full text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none font-bold",
            isNextDisabled && "opacity-40 cursor-default",
            !isNextDisabled && "cursor-pointer",
          )}
        >
          {isSubmitting ? (
            <Loader
              type="Oval"
              visible={true}
              color="white"
              width={16}
              height={16}
              className="flex flex-1 justify-center items-center"
            />
          ) : (
            <>
              {sectionToShow === "card" ? "Avançar" : "Enviar"}
            </>
          )}
        </button>
      </div>
    )
  }, [isNextDisabled, sectionToShow])

  const maskedTaxpayer = useMemo(() => {
    return toPattern(values.taxpayer ?? '', "999.999.999-99")
  }, [values.taxpayer])

  const onChangeTaxpayer = (e) => {
    const value = toNumber(e.target.value ?? '')
    setFieldValue("taxpayer", value)
  }

  return (
    <div
      className='w-full mt-6'
      style={{
        paddingBottom: window.native_app_navigation_bar_height ?? 16
      }}
    >
      <h2 className='font-semibold'>Atualização de meio de pagamento</h2>
      <p className='text-sm'>Se desejar alterar o cartão de crédito utilizado para o pagamento da assinatura, informe os dados do novo cartão, que será utilizado nas próximas cobranças.</p>

      <AnimatePresence mode="wait">
        <motion.div
          key={sectionToShow}
          variants={slideVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          custom={0.2}
          className="w-full flex flex-col gap-y-4 mt-4"
        >
          <form className='w-full mt-4'  onSubmit={handleSubmit}>
            {sectionToShow === "card" ? (
              <div className='w-full space-y-4'>
                <Cards
                  number={values.number}
                  expiry={values.expiry}
                  cvc={values.cvc}
                  name={values.name}
                  focused={focused}
                />

                <div className="w-full">
                  <input
                    className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                    value={maskedCardNumber}
                    name="number"
                    placeholder="Número do cartão"
                    required
                    inputmode="numeric"
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onChange={onChangeCardNumber}
                  />
                  {errors.number && touched.number && (
                    <p className="text-xs text-red-500">{errors.number}</p>
                  )}
                </div>

                <div className='w-full grid grid-cols-2 gap-x-4'>
                  <div className="w-full">
                    <input
                      className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                      name="expiry"
                      value={maskedExpiresDate}
                      placeholder="Data de válidade"
                      required
                      maxLength={5}
                      inputmode="numeric"
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      onChange={onChangeExpiresDate}
                    />
                    <p className='text-xs text-slate-400'>Ex.: 12/35</p>
                    {errors.expiry && touched.expiry && (
                      <p className="text-xs text-red-500">{errors.expiry}</p>
                    )}
                  </div>

                  <div className="w-full">
                    <input
                      className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                      name="cvc"
                      value={values.cvc}
                      placeholder="CVV"
                      required
                      maxLength={4}
                      pattern="[0-9]+"
                      inputmode="numeric"
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      onChange={handleChange}
                    />
                    {errors.cvc && touched.cvc && (
                      <p className="text-xs text-red-500">{errors.cvc}</p>
                    )}
                  </div>
                </div>

                <div className="w-full">
                  <input
                    className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                    name="holderName"
                    value={values.holderName}
                    placeholder="Nome do titular"
                    required
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onChange={handleChange}
                  />
                  {errors.holderName && touched.holderName && (
                    <p className="text-xs text-red-500">{errors.holderName}</p>
                  )}
                </div>

                <div className="w-full">
                  <input
                    className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                    name="taxpayer"
                    placeholder="CPF do titular"
                    required
                    value={maskedTaxpayer}
                    onBlur={handleBlur}
                    onChange={onChangeTaxpayer}
                  />
                  {errors.taxpayer && touched.taxpayer && (
                    <p className="text-xs text-red-500">{errors.taxpayer}</p>
                  )}
                </div>

                <div className="w-full">
                  <input
                    className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                    required
                    name="msisdn"
                    value={values.msisdn}
                    placeholder="Telefone"
                    pattern="^[0-9]{11}$"
                    inputmode="numeric"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.msisdn && touched.msisdn && (
                    <p className="text-xs text-red-500">{errors.msisdn}</p>
                  )}
                </div>

                {renderButtons()}
              </div>
            ) : null}

            {sectionToShow === "address" ? (
              <div className='w-full space-y-4'>
                <div className="w-full">
                  <input
                    className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                    name="zipcode"
                    value={maskedZipcode}
                    placeholder="CEP"
                    required
                    inputmode="numeric"
                    onBlur={handleBlur}
                    onChange={onChangZipcode}
                  />
                  {errors.zipcode && touched.zipcode && (
                    <p className="text-xs text-red-500">{errors.zipcode}</p>
                  )}
                </div>
                
                <div className="w-full">
                  <input
                    className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                    name="publicPlace"
                    value={values.publicPlace}
                    placeholder="Logradouro"
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.publicPlace && touched.publicPlace && (
                    <p className="text-xs text-red-500">{errors.publicPlace}</p>
                  )}
                </div>
                
                <div className="w-full">
                  <input
                    className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                    name="placeNumber"
                    value={values.placeNumber}
                    placeholder="Número"
                    required
                    pattern="[0-9]+"
                    inputmode="numeric"
                    onBlur={handleBlur}
                    onChange={onChangPlaceNUmber}
                  />
                  {errors.placeNumber && touched.placeNumber && (
                    <p className="text-xs text-red-500">{errors.placeNumber}</p>
                  )}
                </div>
                
                <div className="w-full">
                  <input
                    className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                    name="neighborhood"
                    value={values.neighborhood}
                    placeholder="Bairro"
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.neighborhood && touched.neighborhood && (
                    <p className="text-xs text-red-500">{errors.neighborhood}</p>
                  )}
                </div>

                <div className="w-full">
                  <input
                    className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                    name="city"
                    value={values.city}
                    placeholder="Cidade"
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.city && touched.city && (
                    <p className="text-xs text-red-500">{errors.city}</p>
                  )}
                </div>
                
                <div className="w-full">
                  <input
                    className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                    name="state"
                    value={values.state}
                    placeholder="Estado"
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.state && touched.state && (
                    <p className="text-xs text-red-500">{errors.state}</p>
                  )}
                </div>

                {renderButtons(isSubmitting)}
              </div>
            ) : null}
          </form>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

export default CreditCardSection
