/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { default_server } from "./../../services/api";
import TweenOne from "rc-tween-one";
import Drawer from "react-drag-drawer";
import "./../../assets/styles/BookDetail.css";
import Copyrights from "../copyrights";
import { playSong, pauseSong } from '../../utils/song'
import songsName from "../../enums/songsName";
import { useNetworkStatus } from "../../hooks/useNetworkStatus";
import Spinner from "../spinner";
import toast from "react-hot-toast";
import { SUBSCRIBER_PROVIDER_NAME } from "../../consts/common";

const BookDetail = props => {
  let BOOK = 0;

  const { isOnline } = useNetworkStatus()

  const isSurprise = props.appState === "firsttimevisit" ? true : false;
  const currentBookID = props.currentBookID;
  for (let i = 0; i < props.payload.listData.length; i++) {
    const element = props.payload.listData[i];
    if (element.bookId === currentBookID) {
      BOOK = i;
    }
  }

  const TOKEN = props.payload.token;
  const ITEM = props.payload.listData[BOOK];
  const GENDER = ""; //ITEM.bookAuthorGender === "F"? "Autora: " : "Autor: "
  const ILLUSTRATOR =
    ITEM.bookIllustrator.trim() === ITEM.bookAuthor.trim()
      ? ""
      : ITEM.bookIllustrator || "";
  const AUTHOR = ITEM.bookAuthor || "";
  const TITLE = ITEM.bookTitle || "";
  const content = `${GENDER} ${AUTHOR} \n  ${ILLUSTRATOR} `;

  const [show_download, toggle_download] = useState(false);

  const axios = require("axios");
  const postDownloadCounter = type => {
    axios.post(
      `${default_server}/quembaixou`,
      {
        livro: TITLE,
        token: TOKEN,
        produto: "L",
        tipo: type
      },
      {
        timeout: 10000,
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  };

  return (
    <TweenOne
      paused={isSurprise}
      animation={{ opacity: 1 }}
      id="book_detail"
      className={`book_detail_container`}
    >
      <p className="book_detail_infos">
        <p className="text-red text-center">{TITLE}</p>
        <p>{content}</p>
      </p>
      <br />
      <div className="flex flex-col justify-center items-center w-full px-4">
        <button
          id="how-to-read"
          disabled={(!isOnline && !props.offlineBooksIds?.includes(currentBookID)) || props.openingBookId}
          onMouseEnter={() => {
            playSong(songsName.TAP)
          }}
          onClick={() => {
            if (props.openingBookId) {
              return
            }

            if (!props.isLoggedIn) {
              props.showExpanded()

              return
            }

            if (props.isLoggedIn && props.loginProvider === SUBSCRIBER_PROVIDER_NAME && !props.hasUserASubscription && !props.offlineBooksIds.includes(currentBookID)) {
              toast("É necessário possuir uma assinatura para ler este livro")
      
              return
            }

            playSong(songsName.TAP)
            playSong(songsName.BUBBLES)
            props.changeReader(ITEM);
            postDownloadCounter(2);
          }}
        >
          LER LIVRO
        </button>

        {window.in_native_app ? (
          <button
            className="download secondary"
            onClick={async () => {
              playSong(songsName.TAP)

              if (!props.isLoggedIn) {
                props.showExpanded()
  
                return
              }

              if (props.isLoggedIn && props.loginProvider === SUBSCRIBER_PROVIDER_NAME && !props.hasUserASubscription && !props.offlineBooksIds.includes(currentBookID)) {
                toast("É necessário possuir uma assinatura para baixar os livros")
        
                return
              }

              pauseSong(songsName.MAIN_BACKGROUND)
              playSong(songsName.DOWNLOAD)

              props.downloadBook(currentBookID, ITEM)

              setTimeout(() => {
                playSong(songsName.MAIN_BACKGROUND)
              }, 5000);
            }}
            onMouseEnter={() => {
              playSong(songsName.TAP)
            }}
            disabled={!(isOnline && !props.offlineBooksIds?.includes(currentBookID))}
          >
            {props.downloadingBooksIds?.includes(currentBookID) ? <Spinner /> : "BAIXAR"}
          </button>
        ) : null}
      </div>

      <Drawer dontApplyListeners={true} open={show_download}>
        <Copyrights />
      </Drawer>
    </TweenOne>
  );
};

export default BookDetail;
