import "./../../assets/styles/OthersBooks.css";
import Drawer from "react-drag-drawer";
import LoadingIndicator from 'react-loader-spinner'

import React, { useMemo, useState } from "react";
import { playSong } from '../../utils/song'
import songsName from "../../enums/songsName";
import { MdCloudDownload } from "react-icons/md";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import clsx from "clsx";
import { useNetworkStatus } from "../../hooks/useNetworkStatus";
import Button from "../button";
import toast from "react-hot-toast";
import { SUBSCRIBER_PROVIDER_NAME } from "../../consts/common";

const OthersBooks = (props) => {
  const [orderDesc, setOrderDesc] = useState(true)
  const [showAllBooks, setShowAllBooks] = useState(true)

  const [bookForRemove, setBookForRemove] = useState()

  const { isOnline } = useNetworkStatus()
  
  const books = useMemo(() => {
    let items = props.payload.listData

    if (orderDesc) {
      items = [...props.payload.listData].reverse()
    }

    if (showAllBooks) {
      return items
    }

    const downloadBook = items.filter(item => props.offlineBooksIds?.includes(item.bookId))
    return downloadBook
  }, [orderDesc, props.offlineBooksIds, props.payload.listData, showAllBooks])

  const handleClick = (list) => e => {
    const isDownlodedBook = props.offlineBooksIds?.includes(e.target.id)
    if (isDownlodedBook) {
      props.openBookFromDownloaded(e.target.id)
      return 
    }

    playSong(songsName.TAP)
    const totalItens = list.length;
    const id = e.target.id;

    for (let j = 0; j < totalItens; j++) {
      const element = list[j];
      if (id === element.bookId) {
        setTimeout(() => {
          // aciona o scroll pro topo
          window.scrollTo(0,0)
          props.onSlideChange(id);
        }, 200);
        // finaliza o "for"
        j = totalItens;
      }
    }
  }

  const closeConfirmationModal = () => {
    setBookForRemove(undefined)
  }

  const confirmExcludeHandler = () => {
    props.removeDownloadedBook(bookForRemove)
    closeConfirmationModal()
  }

  const checkIfBookIsExpired = (bookAvailability) => {
    const [day, month, year] = bookAvailability.split('/')

    const today = new Date().getTime()
    const expiresIn = new Date(`${month}/${day}/${year}`).getTime()

    return expiresIn < today
  }

  return (
    <>
    <div className="w-full mx-auto -mb-64">
      {window.in_native_app ? (
        <div className="w-full flex flex-row justify-center items-center mb-6">
          <div className="border-2 border-white flex flex-row text-sm rounded-full">
            <div
              onClick={() => setShowAllBooks(true)}
              className={
                clsx(
                  "w-36 h-8 rounded-full flex justify-center items-center",
                  props.isLoggedIn && "cursor-pointer",
                  !props.isLoggedIn && "cursor-default",
                  showAllBooks && "bg-white text-green-primary",
                  !showAllBooks && "bg-transparent text-white"
                )
              }
            >
              <p className="mt-1">TODOS</p>
            </div>
            <div
              onClick={() => setShowAllBooks(false)}
              className={
                clsx(
                  "w-36 h-8 rounded-full flex justify-center items-center",
                  props.isLoggedIn && "cursor-pointer",
                  !props.isLoggedIn && "cursor-default",
                  !showAllBooks && "bg-white text-green-primary",
                  showAllBooks && "bg-transparent text-white"
                )
              }
            >
              <p className="mt-1">BAIXADOS</p>
            </div>
          </div>
        </div>
      ): null}

      {books?.length ? (
        <div className="w-full flex flex-col">
          <button className="ml-auto mr-4 mb-4" onClick={() => setOrderDesc(prev => !prev)}>
            {orderDesc ? (
              <BsSortUp size={32} color="#F2F2F2" />
            ) : (
              <BsSortDown size={32} color="#F2F2F2" />
            )}
          </button>
          <div className="w-full grid grid-cols-2 gap-x-4 gap-y-4 lg:gap-x-6 pt-2 px-4 lg:px-6">
            {books?.map(item => {
              const isDownloded = props?.offlineBooksIds?.includes(item.bookId)
              const isDownloading = props.downloadingBooksIds?.includes(item.bookId)
              const couldShowDownloadButton = !isDownloded && isOnline && props.isLoggedIn && window.in_native_app

              const shouldShowBorder = isDownloded && showAllBooks

              return (
                <div className="w-full">
                  <div
                    className={clsx(
                      "mx-auto bg-cover flex flex-col justify-center items-center relative other-book-wrapper",
                      shouldShowBorder && "border-4 border-white",
                    )}
                    key={item.bookId}
                  >
                    {props.openingBookId === item.bookId || isDownloading ? (
                      <div className="absolute flex flex-1 w-full h-full justify-center items-center z-30 bg-black-60">
                        <LoadingIndicator type="Oval" width={32} height={32} color="#ffffff" />
                      </div>
                    ) : null}

                    <div
                      id={item.bookId}
                      onClick={props.openingBookId ? undefined : handleClick(books)}
                      className="cursor-pointer w-full h-40 bg-cover other-book-cover"
                      style={{ backgroundImage: `url(${item.bookCover})` }}
                    />
                    <div className={`w-full text-xxxs flex h-11 justify-center items-center ${shouldShowBorder ? 'book_footer_downloaded' : ''}`}>
                      {shouldShowBorder ? (
                        <div className="w-full text-green font-bold">
                          BAIXADO
                        </div>
                        ) : (
                        <div className="flex flex-row items-center">
                          {isDownloded ? (
                            <button
                              className="text-white text-sm"
                              onClick={() => {
                                setBookForRemove(item)
                              }}
                            >
                              Remover
                            </button>
                          ) : (
                            <div className="flex flex-row items-center justify-center w-full">
                              <div className={clsx("text-white", window.in_native_app && "ml-6")}>
                                <p id="availability">Baixe até {item.bookAvailability}</p>
                              </div>

                              {couldShowDownloadButton ? (
                                <button
                                  className="ml-1 mt-3 p-2"
                                  onClick={() => {
                                    playSong(songsName.TAP)

                                    if (props.isLoggedIn && props.loginProvider === SUBSCRIBER_PROVIDER_NAME && !props.hasUserASubscription) {
                                      toast("É necessário possuir uma assinatura para baixar os livros")
                              
                                      return
                                    }
                      
                                    props.downloadBook(item.bookId, item)
                                  }}
                                  onMouseEnter={() => {
                                    playSong(songsName.TAP)
                                  }}
                                  disabled={isDownloading}
                                >
                                  <MdCloudDownload className="text-xl text-white" />
                                </button>
                              ) : null}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        <>
          {!showAllBooks ? (
            <div className="w-full flex flex-1 flex-col justify-center items-center text-white px-8 text-sm">
              <p>Você ainda não tem livro baixados</p>
            </div>
          ) : null}
        </>
      )}
    </div>

    {bookForRemove ? (
      <Drawer
        dontApplyListeners={true}
        open={true}
        onRequestClose={closeConfirmationModal}
      >
        <div className="mx-auto max-w-xl p-4 bg-white rounded-lg" style={{ width: '90%' }}>
          <p className="text-lg font-bold">
            {checkIfBookIsExpired(bookForRemove.bookAvailability) ? "Tem certeza que deseja excluir este livro? Depois da exclusão não será possível baixá-lo novamente" : `Você pode baixar este livro novamente até ${bookForRemove.bookAvailability} para mantê-lo em sua biblioteca. Após esta data, a exclusão será permanente`}
          </p>

          <div className="w-full flex flex-row justify-center items-center mt-4">
            <button
              onClick={closeConfirmationModal}
              type="button"
              className="w-44 underline text-red-500 bg-transparent focus:ring-0 rounded-full text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none font-bold"
            >
              Cancelar
            </button>

            <div className='w-44'>
              <Button onClick={confirmExcludeHandler}>Excluir</Button>
            </div>
          </div>
        </div>
      </Drawer>
    ) : null}
    </>
  ) 
}

export default OthersBooks