import clsx from 'clsx'
import React from 'react'
import Loader from "react-loader-spinner";

const Button = ({ loading, className, disabled, children, ...rest }) => {
  return (
    <button
      type="button"
      disabled={disabled}
      {...rest}
      className={clsx("w-full h-10 text-white bg-green-primary focus:ring-0 rounded-full text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none font-bold", disabled && 'opacity-60', !disabled && 'hover:bg-green-secondary', className)}
    >
      {loading ? (
        <Loader
          type="Oval"
          visible={true}
          color="white"
          width={24}
          height={24}
          className="flex flex-1 justify-center items-center"
        />
      ) : children}
    </button>
  )
}

export default Button
