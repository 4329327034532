import React from 'react'
import Drawer from "react-drag-drawer";
import { playSong } from '../../utils/song'
import songsName from '../../enums/songsName'
import { FiChevronLeft, FiX } from 'react-icons/fi';

const QuestionsModal = (props) => {
  const closeHandler = () => {
    playSong(songsName.TAP)
    props.close()
  }

  return (
    <Drawer
      open={props.isOpened}
      onRequestClose={props.close}
      direction="left"
      modalElementClass="absolute top-0 left-0"
    >
      <div
        className='w-screen max-w-lg h-screen bg-modal flex flex-col justify-start items-start px-4 text-lg overflow-y-auto'
        style={{
          paddingTop: window.native_app_status_bar_height ?? 16,
          paddingBottom: window.native_app_navigation_bar_height ?? 16
        }}
      >
        <div className='w-full flex flex-row items-center justify-between mb-6'>
          <button
            type="button"
            className='text-black bg-transparent hover:bg-slate-200 rounded-lg text-2xl mb-1 -ml-2 mr-2'
            onClick={props.backToDrawerMenu}
          >
            <FiChevronLeft />
          </button>

          <div className='flex flex-1 flex-row items-center'>
            <h2 className='font-bold'>DÚVIDAS</h2>
          </div>

          <button
            type="button"
            onMouseEnter={() => {
              playSong(songsName.TAP)
            }}
            onClick={closeHandler}
            className="text-black bg-transparent hover:bg-slate-200 rounded-lg text-2xl lg:text-xl ml-auto"
          >
            <FiX />
          </button>
        </div>

        <div className='text-sm'>
          <h3 className='font-semibold'>Como faço para ler os livros?</h3>
          <p>
            Você pode ler online, se estiver conectado à internet, e ainda ouvir a narração da história. Você pode ainda fazer o download do livro para ler offline, sem usar a internet. Para isso, você vai precisar de um leitor digital (e-reader). Há muitas opções gratuitas em sua loja de aplicativos. Recomendamos o Play Livros, para celulares e tablets Android, o Livros, para celulares e tablets iOS e o Readium ou Adobe Digital Editions, para computadores. Se o livro não for carregado diretamente para a biblioteca do seu leitor digital, procure-o na área de downloads do seu dispositivo e faça o carregamento manual.
          </p>

          <br />

          <h3 className='font-semibold'>Vou receber os livros na minha casa?</h3>
          <p>
            Seus livros virão sempre na forma de um e-book, ou seja, um livro digital para ser lido online, aqui no Conta Outra Vez!, ou offline, no leitor digital de sua preferência. Você não receberá o livro físico.
          </p>

          <br />

          <h3 className='font-semibold'>Quando chegam meus livros?</h3>
          <p>
            Seus novos livros chegam sempre nos primeiros dias de cada mês.
          </p>

          <br />

          <h3 className='font-semibold'>Quantos livros eu vou receber?</h3>
          <p>
            Você receberá sempre dos livros infantis no formato e-book no início de cada mês enquanto fizer parte do Conta Outra Vez!.
          </p>

          <br />

          <h3 className='font-semibold'>
            Quanto tempo tenho para ler ou baixar um livro?
          </h3>
          <p>
            Os livros ficam disponíveis para download por um ano após a disponibilização na plataforma. Uma vez que você baixe o livro, ele é seu em definitivo, mesmo que você não acesse mais o Conta Outra Vez!, desinstale o atalho do seu celular ou troque de aparelho. Por isso é importante fazer o download antes dos 12 meses. Neste período, você também pode ler online e ouvir a narração da história sempre que quiser.
          </p>

          <br />

          <h3 className='font-semibold'>
            Baixei um livro, mas não aparece no meu leitor digital...
          </h3>
          <p>
            Cada leitor digital apresenta um comportamento diferente. Em alguns, o livro é carregado automaticamente para a biblioteca quando você faz o download. Em outros, você precisa localizar o livro na área de downloads do seu dispositivo e carregá-lo manualmente para o leitor. Geralmente, ao localizar o arquivo na pasta de downloads e clicar sobre ele para abri-lo, o dispositivo apresenta os programas aptos a fazê-lo. Você deve, então, selecionar o seu leitor digital.
          </p>

          <br />

          <h3 className='font-semibold'>Posso escolher os livros que vou receber?</h3>
          <p>
            Os livros que você recebe todos os meses são uma surpresa que preparamos para nossos leitores e são escolhidos por curadores especializados em psicologia, educação infantil ou literatura. A ideia é que nossos leitores não recebam só aquilo que conhecem e estão acostumados, mas tenham acesso a diferentes autores e universos, por isso não é possível trocar ou escolher os títulos.
          </p>

          <br />

          <h3 className='font-semibold'>Posso trocar os livros que recebi?</h3>
          <p>
            Um dos propósitos do Conta Outra Vez! é encorajar nossos leitores e mediadores a conhecer novos autores e suas escritas, para mergulhar em diferentes universos. Por isso, pedimos que você se aventure a ler os títulos que separamos para você com todo cuidado.
          </p>

          <br />

          <h3 className='font-semibold'>Se eu não baixar um livro num mês, posso baixá-lo depois?</h3>
          <p>
            Pode! Você tem 12 meses a partir da disponibilização do livro na plataforma para fazer o download. Observe que todos os livros da plataforma apresentam a data limite para baixar e ler online.
          </p>

          <br />

          <h3 className='font-semibold'>Por que não encontro mais um livro na plataforma?</h3>
          <p>
            Os livros ficam disponíveis para download e leitura online por 12 meses a partir da data de disponibilização na plataforma, esse é o tempo que você tem para baixá-lo e mantê-lo em definitivo, ou ler online quantas vezes quiser.
          </p>

          <br />
          <h3 className='font-semibold'>O livro baixado aparece com problemas no meu leitor online...</h3>
          <p>
            Diferentes leitores digitais, de diferentes desenvolvedores, podem ter particularidades e eventualmente dificuldade para suportar determinados formatos de e-books. Pode estar havendo incompatibilidade do livro baixado com o leitor que você escolheu. Recomendamos que você experimente um novo leitor ou utilize o Play Livros, em dispositivos Android, o Livros, em dispositivos iOS, ou o Readium ou Adobe Digital Editions em computadores, todos gratuitos.
          </p>

          <br />

          <h3 className='font-semibold'>Não consigo acessar os áudios do livro baixado</h3>
          <p>
            Os áudios, ou seja, a narração da história, só está disponível na leitura online. Estes áudios não acompanham o download do livro, pois a maioria dos leitores digitais não suporta este recurso.
          </p>

          <br />

          <h3 className='font-semibold'>Se eu trocar meu celular perco os livros já baixados?</h3>
          <p>
            Você pode transferir os livros de um dispositivo para outro, desde que ambos sejam de seu uso pessoal. Pode também manter seus arquivos em mais de um dispositivo, como computador e celular, sem nunca os compartilhar com terceiros. Dependendo do seu dispositivo e do sistema operacional usando, é possível que sua biblioteca seja transferida automaticamente para o novo dispositivo na troca do celular. Se não for o caso, você pode fazer a transferência manual ou ainda fazer download novamente no dispositivo novo, desde que os livros ainda estejam disponíveis na plataforma.
          </p>

          <br />

          <h3 className='font-semibold'>Para que público os livros do Conta Outra Vez! são recomendados?</h3>
          <p>
            Os livros do Conta Outra Vez! são apropriados para todas as idades, mas são melhor aproveitados pelo público de 3 a 10 anos. Recomendamos que o adulto mediador, que é o usuário titular da plataforma, avalie sempre a conveniência e a melhor forma de apresentar o livro para a criança. A análise do especialista não apenas orienta o adulto mediador, mas também sugere a faixa etária a que o livro se destina
          </p>

          <br />

          <h3 className='font-semibold'>Se os livros são meus, então posso compartilhá-los?</h3>
          <p>
            Os livros são de sua propriedade, mas compartilhá-los equivale a distribuir fotocópias dos seus livros impressos. Assim como os livros físicos, os livros digitais estão protegidos por direitos autorais, e por isso é proibido compartilhar, reproduzir, copiar, distribuir e comercializar. Queremos que você compartilhe histórias, não livros! Quem respeita o autor respeita seus direitos.
          </p>

          <br />

          <h3 className='font-semibold'>Como posso entrar em contato com o autor, o ilustrador ou a editora?</h3>
          <p>
            Envie uma mensagem para o Conta Outra Vez! por meio do Fale Conosco, solicitando o contato com determinado autor, ilustrador ou editora, ou ainda com a mensagem que deseja que chegue a eles. Garantimos encaminhar sua mensagem e dados de contato, mas a resposta fica à critério do destinatário.
          </p>

          <br />

          <h3 className='font-semibold'>Não encontrou sua pergunta aqui? Então envie para a gente!</h3>
          <p>
            <a href="mailto:contato@contaoutravez.com.br">
              contato@contaoutravez.com.br
            </a>
          </p>
        </div>
      </div>
    </Drawer>
  )
}

export default QuestionsModal