//export const dev_env_path = 'https://apidev.cov.n3r.com.br';
//export const prod_env_path = 'https://api.cov.n3r.com.br';
//export const default_server = 'https://api.cov.n3r.com.br';
import axios from 'axios'

import { PRODUCT } from '../consts/product';

export const default_server =  process.env.REACT_APP_DEFAULT_SERVER;

const api = axios.create({
  baseURL: process.env.REACT_APP_DEFAULT_SERVER
})

api.interceptors.request.use(config => {
  config.params = {
    ...(config.params ?? {}),
    produto: PRODUCT
  }

  return config
})

const mock_api = axios.create({
  baseURL: 'https://contaoutravez-mock.onrender.com'
})

mock_api.interceptors.request.use(config => {
  config.params = {
    ...(config.params ?? {}),
    produto: PRODUCT
  }

  return config
})

export {
  mock_api
}

export default api
