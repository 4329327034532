import React, { useEffect, useRef, useState } from 'react'
import Drawer from "react-drag-drawer";
import { playSong } from '../../utils/song'
import songsName from '../../enums/songsName'
import { FiChevronLeft, FiX } from 'react-icons/fi';
import clsx from 'clsx';
import Loading from '../loading';
import GeneralSection from './component/general-section';
import DeleteAccount from './component/delete-account';

const ProfileModal = (props) => {
  const isOpenedRef = useRef(false)
  
  const [isLoading, setIsLoading] = useState(true)

  const [shouldShowDeleteConfirmation, setShouldShowDeleteConfirmation] = useState(false)

  useEffect(() => {
      if (props.isOpened && !isOpenedRef.current) {
        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      }
  
      isOpenedRef.current = props.isOpened
    }, [props.isOpened])

  const closeHandler = () => {
    playSong(songsName.TAP)
    props.close()
  }

  return (
    <Drawer
      open={props.isOpened}
      onRequestClose={props.close}
      direction="left"
      modalElementClass="absolute top-0 left-0 z-10"
    >
      <div
        className='w-screen max-w-lg h-screen bg-modal flex flex-col justify-start items-start px-4 pb-4 text-lg overflow-y-auto'
        style={{
          paddingTop: window.native_app_status_bar_height ?? 16,
          paddingBottom: window.native_app_navigation_bar_height ?? 16
        }}
      >
        <div className='w-full flex flex-row items-center justify-between mb-6'>
          <button
            type="button"
            className='text-black bg-transparent hover:bg-slate-200 rounded-lg text-2xl mb-1 -ml-2 mr-2'
            onClick={props.backToDrawerMenu}
          >
            <FiChevronLeft />
          </button>

          <div className='flex flex-1 flex-row items-center'>
            <h2 className='font-bold'>PERFIL</h2>
          </div>

          <button
            type="button"
            onMouseEnter={() => {
              playSong(songsName.TAP)
            }}
            onClick={closeHandler}
            className="text-black bg-transparent hover:bg-slate-200 rounded-lg text-2xl lg:text-xl ml-auto"
          >
            <FiX />
          </button>
        </div>

        <div className={
          clsx(
            'flex flex-1 w-full flex-col',
            isLoading && 'justify-center',
            isLoading && 'items-center',
          )
        }>
          {isLoading ? (
            <div className='flex flex-1 w-full flex-col justify-center items-center'>
              <Loading />
            </div>
          ) : (
            <div className='w-full flex flex-col gap-y-6'>
              {!shouldShowDeleteConfirmation ? <GeneralSection subscriberData={props.subscriberData} /> : null}
              <DeleteAccount logout={props.logout} subscriberData={props.subscriberData} shouldShowDeleteConfirmation={shouldShowDeleteConfirmation} setShouldShowDeleteConfirmation={setShouldShowDeleteConfirmation} close={props.close} />
            </div>
          )}
        </div>
      </div>
    </Drawer>
  )
}

export default ProfileModal