import { useFormik } from 'formik'
import React from 'react'
import Button from '../../../button'
import * as yup from 'yup'
import api from '../../../../services/api'
import { PRODUCT } from '../../../../consts/product'
import toast from 'react-hot-toast'

const validationSchema = yup.object({
  email: yup
    .string()
    .required('O e-mail é obrigatório')
    .email('O e-mail informado é inválido')
    .test('same-email', 'O e-mail atual não pode ser igual ao novo e-mail', (value, context) => {
      return context.parent.newemail !== value
    }),
  newemail: yup
    .string()
    .required('A senha atual é obrigatória')
    .email('O e-mail informado é inválido')
    .test('same-email', 'O novo e-mail não pode ser igual ao e-mail atual', (value, context) => {
      return context.parent.email !== value
    }),
})

const ChangeEmail = () => {
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: '',
      newemail: '',
    },
    validationSchema,
    onSubmit: async ({ email, newemail }, { resetForm }) => {
      try {
        await api.post('/vamole/app/email', {
          email,
          newemail,
          produto: PRODUCT
        })

        toast("E-mail alterado com sucesso")

        resetForm({
          email: '',
          newemail: '',
        })
      } catch {
        toast("Tivemos um erro ao tentar alterar seu e-mail, por favor tente mais tarde")
      }
    }
  })

  return (
    <div className='w-full mb-6'>
      <h3 className='font-bold text-lg mb-4'>Alterar e-mail</h3>

      <form className='w-full flex flex-col space-y-4' onSubmit={handleSubmit}>
        <div className="w-full">
          <input
            className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
            type="email"
            placeholder='E-mail atual'
            name="email"
            value={values.email}
            required
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.email && touched.email && (
            <p className="text-sm text-red-500">{errors.email}</p>
          )}
        </div>

        <div className="w-full">
          <input
            className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
            type="email"
            name="newemail"
            placeholder='Nova e-mail'
            value={values.newemail}
            required
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.newemail && touched.newemail && (
            <p className="text-sm text-red-500">{errors.newemail}</p>
          )}
        </div>

        <div className='w-40'>
          <Button loading={isSubmitting} disabled={isSubmitting || !(isValid && dirty)} type="submit">
            Alterar
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ChangeEmail
