import React, { useState, useRef } from 'react';
import "./../../assets/styles/Section.css"


const Section = props => {
    const appState = props.parent.appState || null;
    const ref = useRef(null);

    const passPropsToChildren = (element, props) => {
        if (!React.isValidElement(element)) return element;

        // Clone the current element and pass the props
        return React.cloneElement(element, props,
            React.Children.map(element.props.children, child =>
                passPropsToChildren(child, props) // Recursive call for nested children
            )
        );
    };

    const childrens = props.children.map((child, idx) =>
        passPropsToChildren(child, {
            key: idx,
            payload: props.parent.payload,
            appState: appState,
            currentBookID: props.parent.currentSlideId,
            onSlideChange: slideIdx => {
                props.parent.changeCurrentSlideId(slideIdx)

                if (props.parent.changeCurrentBookId) {
                    props.parent.changeCurrentBookId(slideIdx)
                }

                if (window.in_native_app) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        type: 'GET_OFFLINE_BOOK',
                        payload: {
                            id: slideIdx
                        }
                    }))
                }
            },
            onSurpriseComplete: () => props.parent.changeState(),
            onBoatComplete: () => props.parent.changeToLandingPage(),
        })
    );

    return (
        <section ref={ref}>
            <div className={`section_background ${props.parent.appState === 'landingpage' ? 'section_background_landingpage' : ''}`}>
                <div id="frontground" className={`w-full max-w-md section_frontground ${props.parent.appState === 'landingpage' ? 'section_backgrounds' : 'section_bgColor'}`}>
                    {childrens}
                </div>
            </div>
        </section>
    );
}

export default Section;
