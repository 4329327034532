import React from 'react'
import clsx from 'clsx';
import BgImage from '../../assets/images/bg-app-01.png'
import { BsX } from 'react-icons/bs'

const NoAuthModal = (props) => {
  const openRegistrationHandler = () => {
    if (window.in_native_app) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
				type: 'OPEN_REGISTRATION',
			}))

      return
    }

    props.changeToRegister()
  }

  return (
    <div
      className={clsx(
        "fixed z-30 left-0 p-4 w-full transition-colors duration-300",
        props.shouldExpand && "h-full z-50 bg-black-60"
      )}
      style={{
        bottom: props.shouldExpand ? 0 : window.native_app_navigation_bar_height ?? 0
      }}
    >
      <div
        className={
          clsx(
            'w-full max-w-2xl mx-auto rounded-2xl shadow-md py-4 px-2 text-sm transition-all duration-300 relative overflow-hidden',
            props.shouldExpand && "h-2/3 flex flex-col justify-end items-cente pb-32",
          )
        }
        style={{
          top: props.shouldExpand ? '18%' : undefined
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            opacity: 0.9,
            top: 0,
            left: 0,
            right: 0,
            bottom: '-20%',
            backgroundImage: `url(${BgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
            zIndex: -1,
          }}
        />
        {props.shouldExpand ? (
          <button onClick={props.closeExpanded} className='absolute top-2 right-2 bg-slate-100 p-2 rounded-full flex flex-1 justify-center items-center shadow-lg'>
            <BsX className='w-8 h-8' />
          </button>
        ) : null}

        <h1 className={
          clsx(
            props.shouldExpand && "text-xl font-bold mb-6 text-center",
            !props.shouldExpand && "text-white"
          )
        }>
          {props.shouldExpand ? "Entre ou faça uma assinatura para ter este e outros livros na sua biblioteca e ler quando quiser.": "Tem um mar de histórias esperando por você!"}
        </h1>
        <p
          className={clsx(
            'text-sm font-bold',
            !props.shouldExpand && "text-white"
          )}
        >
          <button
            onClick={props.changeToLogin}
            className={clsx(
              'underline underline-offset-1 mr-2 font-bold',
              props.shouldExpand && "text-lg font-semibold mb-6"
            )}
          >
            Entrar
          </button>
          
          <span className={clsx(
            props.shouldExpand && "text-lg"
          )}>
            ou
          </span>

          <button
            onClick={openRegistrationHandler}
            className={clsx(
              'underline underline-offset-1 ml-2 font-bold',
              props.shouldExpand && "text-lg font-semibold mb-6"
            )}
          >
            Assinar
          </button>
        </p>
      </div>
    </div>
  )
}

export default NoAuthModal
