import React, { useState } from "react";
import Drawer from "react-drag-drawer";
import FormCallUs from "./faleconosco-form/index";
import { playSong } from "../../utils/song";
import songsName from "../../enums/songsName";
import { FiChevronLeft, FiX } from "react-icons/fi";

const CallUsModal = ({
  show_callus,
  toggle_callus,
  appState,
  backToDrawerMenu
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const closeHandler = () => {
      playSong(songsName.TAP)
      toggle_callus()
    }

  return (
    <Drawer
      open={show_callus}
      onRequestClose={() => toggle_callus(false)}
      direction="left"
      modalElementClass="absolute top-0 left-0"
    >
      <div
        className={`w-screen max-w-lg h-screen bg-modal flex flex-col justify-start items-start px-4 pb-4 text-lg overflow-y-auto`}
        style={{
          paddingTop: window.native_app_status_bar_height ?? 16
        }}
      >
        <div className='w-full flex flex-row items-center justify-between mb-6'>
          <button
            type="button"
            className='text-black bg-transparent hover:bg-slate-200 rounded-lg text-2xl mb-1 -ml-2 mr-2'
            onClick={backToDrawerMenu}
          >
            <FiChevronLeft />
          </button>

          <div className='flex flex-1 flex-row items-center'>
            <h2 className='font-bold'>FALE CONOSCO</h2>
          </div>

          <button
            type="button"
            onMouseEnter={() => {
              playSong(songsName.TAP)
            }}
            onClick={closeHandler}
            className="text-black bg-transparent hover:bg-slate-200 rounded-lg text-2xl lg:text-xl ml-auto"
          >
            <FiX />
          </button>
        </div>

        <p className="text-sm">
          Ficou com alguma dúvida ou quer dar sua opinião? Então banque o autor e escreva para nós!
        </p>
        
        <FormCallUs
          toggle_callus={toggle_callus}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          appState={appState}
        />
        <p className="text-sm mt-4">
          A gente vai responder o mais rápido que puder.
        </p>
      </div>

      {/* <div className="modal modal-callus">
        <div className="modal-container-callus">
          <h2 style={{ padding: 10, marginBlockEnd: 0 }}>FALE CONOSCO</h2>
          <p style={{ padding: 10, margin: 0 }}>
            Ficou com alguma dúvida ou quer dar sua opinião? Então banque o autor e escreva para nós!
          </p>
          <FormCallUs
            toggle_callus={toggle_callus}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isSuccess={isSuccess}
            setIsSuccess={setIsSuccess}
            appState={appState}
          />
          <p style={{ padding: 10 }}>
            A gente vai responder o mais rápido que puder.
          </p>
        </div>
      </div> */}
    </Drawer>
  );
};
export default CallUsModal;