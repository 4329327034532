import React, { useState } from 'react'
import Carousel from 'nuka-carousel';
import clsx from 'clsx';

const Tutorial = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [showSlide, setShowSlide] = useState(window.in_native_app && window?.native_app_tutorial_images?.length)

  if (!showSlide) {
    return null
  }

  const handleCompleteTutorial = () => {
    if (window.in_native_app) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        type: 'FINISHED_TUTORIAL',
      }))

      window.show_tutorial = false
      window.native_app_tutorial_images = []

    }
    
    setShowSlide(false)
  }

  return (
    <div className='fixed z-20 top-0 left-0 w-screen h-screen'>
      <Carousel
        swiping
        disableEdgeSwiping
        slideIndex={currentIndex}
        scrollDistance="screen"
        className='w-screen h-screen'
        afterSlide={(index) => {
          setCurrentIndex(index)
          if (window.native_app_tutorial_images.length - 1 === index) {
            setTimeout(() => {
              handleCompleteTutorial()
            }, 3000)
          }
        }}
      >
        {window.native_app_tutorial_images.map((item, index) => (
          <img  
            key={item}
            src={item}
            className='w-screen h-screen object-cover'
            alt={`Slide ${index}`} />
        ))}
      </Carousel>

      <div
        className='w-full flex flex-row justify-center items-center space-x-4 absolute left-0 right-0'
        style={{
          bottom: window.native_app_navigation_bar_height ?? 16
        }}
        >
        {window.native_app_tutorial_images.map((_, index) => (
          <div
          onClick={() => {
            setCurrentIndex(index)
          }}
          className={clsx(
            'w-2 h-2 rounded-full',
              index === currentIndex && "bg-slate-200",
              index !== currentIndex && "bg-slate-500"
            )}
          />
        ))}
      </div>

      <button
        onClick={handleCompleteTutorial}
        className='absolute right-4 underline z-40 text-white text-xs'
        style={{
          top: window.native_app_status_bar_height ?? 16
        }}
      >
        Pular tutorial
      </button>
    </div>
  )
}

export default Tutorial
