import React from 'react'
import ChangePassword from './change-password'
import ChangeEmail from './change-email'

const GeneralSection = (props) => {
  return (
    <div className='w-full'>
      <div className='flex flex-col space-y-2 mb-6'>
        <p>
          <span className='font-semibold mr-1'>Nome:</span>
          {props.subscriberData.nome}
        </p>
        <p>
          <span className='font-semibold mr-1'>E-mail:</span>
          {props.subscriberData.email}
        </p>
        <p>
          <span className='font-semibold mr-1'>Telefone:</span>
          {props.subscriberData.telefone}
        </p>
      </div>

      {/* <ChangeEmail /> */}
      <ChangePassword />
    </div>
  )
}

export default GeneralSection
