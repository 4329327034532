import React from 'react';
import TweenOne from 'rc-tween-one';
import './../../assets/styles/OthersTitle.css'


const OthersTitle = props => {
    const isSurprise = (props.appState === 'firsttimevisit')
        ? true
        : false
    ;
    return (
        <TweenOne id="others_title" className={`others_title_container ${isSurprise ? 'hide' : ''}`} paused={isSurprise} animation={{ opacity: 1 }}>
            <p>BIBLIOTECA</p>
        </TweenOne>
    );
}

export default OthersTitle;