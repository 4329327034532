import React from 'react'
import * as yup from 'yup'
import InputMask from "react-input-mask";

const regexTel =
    /^\(\d{2}\) \d{5}-\d{4}|\((?:1[2-9]|[2-9]\d)\) [5-9]\d{3}-\d{4}$/;
  const regexCnpjCpf =
    /^([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})$/;

export const getFields = (
    field,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isInRegistration = false
  ) => {
    if (field.name === "cpfcnpj") {
      if (field.validation !== "cpf_cnpj") {
        return (
          <div className="w-full">
            <input
              className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
              value={values.cpfcnpj}
              name={field.name}
              key={field.name}
              placeholder={field.placeholder}
              pattern={!new RegExp(field.validation)}
              required
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.cpfcnpj && touched.cpfcnpj && (
              <p className="text-xs text-red-500">{errors.cpfcnpj}</p>
            )}
          </div>
        );
      } else {
        return (
          <div className="w-full">
            <InputMask
              className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
              value={values.cpfcnpj}
              name={field.name}
              key={field.name}
              mask={
                values.cpfcnpj.length <= 14
                  ? "999.999.999-9999999"
                  : "99.999.999/9999-99"
              }
              placeholder="CPF / CNPJ"
              maskChar={null}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.cpfcnpj && touched.cpfcnpj && (
              <p className="text-sm text-red-500">{errors.cpfcnpj}</p>
            )}
          </div>
        );
      }
    }

    if (field.name === "msisdn") {
      return (
        <div className="w-full">
          <InputMask
            className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
            type="tel"
            mask="(99) 99999-9999"
            maskChar={null}
            name={field.name}
            key={field.name}
            value={values.msisdn}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={field.placeholder}
            required
          />
          {errors.msisdn && touched.msisdn && (
            <p className="text-xs text-red-500">{errors.msisdn}</p>
          )}
        </div>
      );
    }

    if (field.name === "username") {
      return (
        <div className="w-full">
          <input
            className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
            type="text"
            name={field.name}
            placeholder={field.placeholder}
            key={field.name}
            value={values.username}
            required
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.username && touched.username && (
            <p className="text-xs text-red-500">{errors.username}</p>
          )}
        </div>
      );
    }

    if (field.name === "email") {
      return (
        <div className="w-full">
          <input
            className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
            type="email"
            name={field.name}
            placeholder={field.placeholder}
            key={field.name}
            value={values.email}
            required
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.email && touched.email && (
            <p className="text-xs text-red-500">{errors.email}</p>
          )}
        </div>
      );
    }

    if (field.name === "password") {
      return (
        <div className="w-full">
          <input
            className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
            key={field.name}
            type="password"
            name="password"
            placeholder={field.placeholder}
            required
            onBlur={handleBlur}
            pattern={field.validation}
            value={values.password}
            onChange={handleChange}
          />
          {isInRegistration ? <p className='text-xs'>Para criar uma senha forte, utilize letras maiúsculas, letras minúsculas, números e caracteres especiais.</p> : null}
          {errors.password && touched.password && (
            <p className="text-xs text-red-500">{errors.password}</p>
          )}
        </div>
      );
    }

    return (
      <div className="w-full">
        <InputMask
          className="bg-white border border-gray-primary text-sm rounded-md block w-full p-2.5"
          key={field.name}
          type={field.type}
          name={field.name}
          placeholder={field.placeholder}
          required
          onBlur={handleBlur}
          value={values[field.name]}
          pattern={field.validation}
          inputmode={field.inputmode}
          onChange={handleChange}
          mask={(() => {
            if (field.name === 'validade') {
              return "99/99"
            }

            if (field.name === 'cvv') {
              return "9999"
            }

            return null
          })()}
          maskChar={null}
        />
        {errors[field.name] && touched[field.name] && (
          <p className="text-xs text-red-500">{errors[field.name]}</p>
        )}
      </div>
    )
  };

export const getValidation = (field, value) => {
  if (field.name === "username") {
    if (!new RegExp(field.validation).test(value)) {
      return field.validation_message;
    }

    if (value === "") {
      return "Campo obrigatório";
    }
  }

  if (field.name === 'email') {
    if (field.validation === "email") {
      const schema = yup.string().email()
      const result = schema.isValidSync(value)

      if (!result) {
        return field.validation_message;
      }

      if (!value) {
        return "Campo obrigatório";
      }
    }
  }

  if (field.name === "cpfcnpj") {
    if (field.validation === "cpf_cnpj") {
      if (value.match(regexCnpjCpf) === null) {
        return field.validation_message;
      }
    } else {
      if (!new RegExp(field.validation).test(value)) {
        return field.validation_message;
      }

      if (value === "") {
        return "Campo obrigatório";
      }
    }
  }

  if (field.name === "password") {
    if (!new RegExp(field.validation).test(value)) {
      return field.validation_message;
    }

    if (value === "") {
      return "Campo obrigatório";
    }
  }

  if (field.name === "msisdn") {
    if (value.match(regexTel) === null) {
      return field.validation_message;
    }

    if (value === "") {
      return "Campo obrigatório";
    }
  }

  if (value === "") {
    return "Campo obrigatório";
  }

  if (field.validate) {
    if (!new RegExp(field.validation).test(value)) {
      return field.validation_message
    }
  }
}
