export const chunkArray = (array, size) => {
  const chunks = [];
  
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  
  return chunks;
}

export const getBase64FromUri = async (uri) => {
  const response = await fetch(uri)
  const blob = await response.blob()

  const base64 = await new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result)
    }

    reader.readAsDataURL(blob)
  })

  return base64
}

export const capitalizeText = (value) => {
  const text = value.toLowerCase()
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}
