import React, { useCallback, useEffect, useState } from 'react'
import Drawer from "react-drag-drawer";
import { playSong } from '../../utils/song'
import songsName from '../../enums/songsName'
import { FiChevronLeft, FiX } from 'react-icons/fi';
import Loading from '../loading';
import api from '../../services/api';
import ConfirmCancelPlanModal from './component/plans-section'
import CreditCardSection from './component/credit-card-section';

const PaymentModal = (props) => {
  const [loading, setIsLoading] = useState(true)
  const [plan, setPlan] = useState({})

  const [showConfirmCancelPlan, setShowConfirmCancelPlan] = useState(false)

  const getPlan = useCallback(async () => {
    const { data } = await api.get('/app/plano')
    setPlan(data?.planos?.[0])
  }, [])

  useEffect(() => {
    const bootstrap = async () => {
      try {
        setIsLoading(true)

        await getPlan()
      } finally {
        setIsLoading(false)
      }
    }

    bootstrap()
  }, [getPlan, props.isOpened])

  const closeHandler = () => {
    playSong(songsName.TAP)
    props.close()
    setShowConfirmCancelPlan(false)
  }

  return (
    <Drawer
      open={props.isOpened}
      onRequestClose={() => {
        props.close()
        setShowConfirmCancelPlan(false)
      }}
      direction="left"
      modalElementClass="absolute top-0 left-0"
    >
      <div
        className={`w-screen max-w-lg h-screen bg-modal flex flex-col justify-start items-start px-4 pb-4 text-lg overflow-y-auto`}
        style={{
          paddingTop: window.native_app_status_bar_height ?? 16
        }}
      >
        <div className='w-full flex flex-row items-center justify-between mb-6'>
          <button
            type="button"
            className='text-black bg-transparent hover:bg-slate-200 rounded-lg text-2xl mb-1 -ml-2 mr-2'
            onClick={() => {
              props.backToDrawerMenu()
              setShowConfirmCancelPlan(false)
            }}
          >
            <FiChevronLeft />
          </button>

          <div className='flex flex-1 flex-row items-center'>
            <h2 className='font-bold'>ASSINATURA</h2>
          </div>

          <button
            type="button"
            onMouseEnter={() => {
              playSong(songsName.TAP)
            }}
            onClick={closeHandler}
            className="text-black bg-transparent hover:bg-slate-200 rounded-lg text-2xl lg:text-xl ml-auto"
          >
            <FiX />
          </button>
        </div>

        {loading ? (
          <div className='flex flex-1 w-full flex-col justify-center items-center'>
            <Loading />
          </div>
        ) : (
          <div className='w-full overflow-y-scroll'>
            <ConfirmCancelPlanModal
              plan={plan}
              hasUserASubscription={props.hasUserASubscription}
              showConfirmCancelPlan={showConfirmCancelPlan}
              setShowConfirmCancelPlan={setShowConfirmCancelPlan}
              isUserInFreeTrial={props.isUserInFreeTrial}
            />
            {!showConfirmCancelPlan ? (
              <CreditCardSection hasUserASubscription={props.hasUserASubscription} plan={plan} checkIfUserHasScubscription={props.checkIfUserHasScubscription} subscriberData={props.subscriberData} />
            ) : null}
          </div>
        )}
      </div>
    </Drawer>
  )
}

export default PaymentModal