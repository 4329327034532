import React from 'react'
import imgLoading from "./../../assets/images/p-loader.gif";

const Loading = () => {
  return (
    <img
      src={imgLoading}
      alt={"Loading..."}
      className='w-64 h-64'
    />
  )
}

export default Loading