import React, { createContext, useContext, useState } from "react";

const NetworkStatus = createContext()

function NetworkStatusProvider({ children }) {
  const [isOnline, setIsOnline] = useState(true)

  const updateOnlineStatus = (nextStatus) => {
    setIsOnline(nextStatus)
  }

  return (
    <NetworkStatus.Provider value={{ isOnline, updateOnlineStatus }}>
      {children}
    </NetworkStatus.Provider>
  )
}

export function useNetworkStatus() {
  const context = useContext(NetworkStatus);

  if (!context) {
    throw new Error('Should be use the useNetworkStatus inside of NetworkStatusProvider');
  }

  return context;
}

export default NetworkStatusProvider
