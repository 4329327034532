import React, { memo, useEffect, useState } from 'react';
import firebase from 'firebase';
import Section from '../_section';
import MainTitle from '../main-title';
import Carrossel from '../carrossel';
import BookDetail from '../book-detail';
import Review from '../review';
import OthersTitle from '../others-title';
import OthersBooks from '../others-books';
import Footer from '../footer';
import PwaScreen from '../pwa-screen';
import PushNotificationModal from '../pushNotificationModal';
import songsName from '../../enums/songsName';
import { pauseSong, playSong } from '../../utils/song';
import imgPassatempos from '../../assets/images/passatempos_1.png'
import { useNetworkStatus } from '../../hooks/useNetworkStatus'
import toast from 'react-hot-toast';
import NoAuthModal from '../noauthmodal'
import { FiMenu } from "react-icons/fi"
import DrawerMenu from '../drawer-menu';
import AboutModal from '../about-modal';
import QuestionsModal from '../questions-modal';
import CallUsModal from "../faleconosco/faleconosco-modal";
import ProfileModal from '../profile-modal';
import PaymentModal from '../payment-modal';
import LoginModal from '../login-modal';
import RegistrationModal from '../registration-modal';
import KnowMoreModal from '../know-more';
import TermsOfUse from '../terms-of-use';
import PrivacyPolicy from '../privacy-policy';
// import AdsModal from "../ads-modal/index";
// import { askUserPermission } from '../../push-notification';

const LandingPage = (props) => {
	const [shouldExpand, setShouldExpand] = useState(false)
	const [isOpenSurprise, setIsOpenSurprise] = useState(null);

	const isAppInitialized = props.appState === 'firsttimevisit' || props.appState === 'landingpage' ? true : false;
	const isLandingPage = props.appState === 'landingpage'

	const { isOnline } = useNetworkStatus()

	useEffect(() => {
		if (firebase.messaging.isSupported()) {
			if (window.Notification.permission === 'granted') {
				firebase.messaging().getToken().then(hash => {
					if (hash) console.log('HASH: ', hash);
				});
			}
		}
	}, []);

	useEffect(() => {
		if (props.appState === 'firsttimevisit' && !props.isReading) {
			setTimeout(() => {
				playSong(songsName.MAIN_BACKGROUND)
			}, 999);
		}

		if (props.appState === 'landingpage' && !props.isReading) {
			playSong(songsName.MAIN_BACKGROUND)
		}

		if (props.isReading) {
			pauseSong(songsName.MAIN_BACKGROUND)
		}
	}, [props.appState, props.isReading]);

	// const registerServiceWorker = () => {
	// 	if (firebase.messaging.isSupported()) {
	// 		return navigator.serviceWorker.register('/firebase-messaging-sw.js');
	// 	}
	// }

	if (!isAppInitialized) {
		return <div />;
	}

	const changeReader = props.changeReader;

	const onChangeReader = (props) => {
		playSong(songsName.TAP)
		changeReader(props)
	}

	const showExpanded = () => {
		setShouldExpand(true)
	}

	const closeNotExpanded = () => {
    setShouldExpand(false)
	}

	return (
		<>
			<PushNotificationModal showEnable={props.appState === 'landingpage'} />
			<PwaScreen />

			<Section parent={props}>
				<PwaScreen isLoggedIn={props.isLoggedIn} />

				{props.appState === 'landingpage' ? (
					<button
						onClick={props.openDrawerHandler}
						className={`fixed z-40 border-0 bg-slate-100 p-2 rounded-full right-4 flex flex-1 justify-center items-center shadow-lg`}
						style={{
							top: window.native_app_status_bar_height ?? 16
						}}
					>
						<FiMenu className='w-6 h-6' />
					</button>
				) : null}

				<DrawerMenu
					isOpened={props.isDrawerOpened}
					close={props.closeDrawerHandler}
					openLoginModal={props.openLoginModalHandler}
					openRegistrationModal={props.openRegistrationModalHandler}
					openAboutModal={props.openAboutModalHandler}
					openQuestionsModal={props.openQuestionsModalHandler}
					openContactUsModal={props.openContactUsModalHandler}
					openProfileModal={props.openProfileModalHandler}
					openPaymentModal={props.openPaymentModalHandler}
					openTermsOfUseModal={props.openTermsOfUseModalHandler}
					openPrivacyPolicyModal={props.openPrivacyPolicyModalHandler}
					openKnowMore={props.openKnowMore}
					logout={props.logout}
					isLoggedIn={props.isLoggedIn}
					isSongDisabled={props.isSongDisabled}
					hasUserASubscription={props.hasUserASubscription}
					toggleBackgroundSong={props.toggleBackgroundSong}
					loginProvider={props.loginProvider}
				/>

				<div
					style={{
						height: window.native_app_status_bar_height ?? 24
					}}
				/>
				<MainTitle
					isLoggedIn={props.isLoggedIn}
					isOpenSurprise={isOpenSurprise}
					setIsOpenSurprise={setIsOpenSurprise}
					subscriberData={props.subscriberData}
				/>
				<Carrossel
					isOpenSurprise={isOpenSurprise}
					setIsOpenSurprise={setIsOpenSurprise}
					changeToLandingPage={props.changeToLandingPage}
					changeReader={onChangeReader}
					offlineBooksIds={props.offlineBooksIds}
					showExpanded={showExpanded}
					isLoggedIn={props.isLoggedIn}
					openingBookId={props.openingBookId}
					loginProvider={props.loginProvider}
					hasUserASubscription={props.hasUserASubscription}
				/>
				<BookDetail
					handleOpenReader={props.handleOpenReader}
					changeReader={onChangeReader}
					downloadBook={props.downloadBook}
					offlineBooksIds={props.offlineBooksIds}
					downloadingBooksIds={props.downloadingBooksIds}
					showExpanded={showExpanded}
					isLoggedIn={props.isLoggedIn}
					openingBookId={props.openingBookId}
					loginProvider={props.loginProvider}
					hasUserASubscription={props.hasUserASubscription}
				/>
				<Review />

				<div className='content-top-bg' />
				<div className='content-wrapper'>
					{props.showGameBanner ? (
						<div
							className='passatempos-banner-wrapper'
							onClick={() => {
								if (!isOnline) {
									toast('Não é possível acessar os jogos offline')
									return
								}

								playSong(songsName.TAP)
								window.scrollTo(0, 0);
								props.openGames()
							}}
						>
							<img className='w-96 h-72 mr-10' src={imgPassatempos} alt='passatempos' />
						</div>
					) : <div />}

					<OthersTitle />
					<OthersBooks
						downloadBook={props.downloadBook}
						offlineBooksIds={props.offlineBooksIds}
						downloadingBooksIds={props.downloadingBooksIds}
						lastDownloadedBooksIds={props.lastDownloadedBooksIds}
						openBookFromDownloaded={props.openBookFromDownloaded}
						isLoggedIn={props.isLoggedIn}
						openingBookId={props.openingBookId}
						removeDownloadedBook={props.removeDownloadedBook}
						loginProvider={props.loginProvider}
						hasUserASubscription={props.hasUserASubscription}
					/>

					<div
						className='footer-wrapper'
						style={{
							paddingBottom: window.native_app_navigation_bar_height ?? 0
						}}
					>
						{/* <InfoButtons appState={props.appState} changeToLogin={props.changeToLogin} /> */}
						{/* <InfoSmallButtons /> */}
						<Footer ignoreLogo />
					</div>
				</div>

				{props.appState === 'landingpage' && !props.isLoggedIn ? (
					<NoAuthModal
						changeToLogin={props.openLoginModalHandler}
						changeToRegister={props.openRegistrationModalHandler}
						shouldExpand={shouldExpand}
						closeExpanded={closeNotExpanded}
					/>
				) : null}

				{
					isLandingPage ? (
						<>
							<ProfileModal isOpened={props.showProfileModal} close={props.closeProfileModalHandler} backToDrawerMenu={props.backToDrawerMenu} subscriberData={props.subscriberData} logout={props.logout} />
							<PaymentModal isOpened={props.showPaymentModal} close={props.closePaymentModalHandler} backToDrawerMenu={props.backToDrawerMenu} hasUserASubscription={props.hasUserASubscription} checkIfUserHasScubscription={props.checkIfUserHasScubscription} subscriberData={props.subscriberData} isUserInFreeTrial={props.isUserInFreeTrial} />
							<AboutModal isOpened={props.showAboutModal} close={props.closeAboutModalHandler} backToDrawerMenu={props.backToDrawerMenu} />
							<QuestionsModal isOpened={props.showQuestionsModal} close={props.closeQuestionsModalHandler} backToDrawerMenu={props.backToDrawerMenu} />
							<KnowMoreModal isOpened={props.showKnowMore} close={props.closeKnowMore} backToDrawerMenu={props.backToDrawerMenu} />
							<TermsOfUse isOpened={props.showTermsOfUse} close={props.closeTermsOfUseModalHandler} backToDrawerMenu={props.backToDrawerMenu} />
							<PrivacyPolicy isOpened={props.showPrivacyPolicy} close={props.closePrivacyPolicyModalHandler} backToDrawerMenu={props.backToDrawerMenu} />

							<LoginModal isOpened={props.showLoginModal} close={props.closeLoginModalHandler} openRegistration={props.openRegistrationFromLoginModal} onLoginSuccess={props.onLoginSuccess} onPortfolioLoaded={props.onPortfolioLoaded} updateSubscriberData={props.updateSubscriberData} updateLoginProvider={props.updateLoginProvider} backToDrawerMenu={props.backToDrawerMenu} />
							<RegistrationModal isOpened={props.showRegistrationModal} close={props.closeRegistrationModalHandler} openLogin={props.openLoginFromRegistrationModal} backToDrawerMenu={props.backToDrawerMenu} />

							<CallUsModal
								show_callus={props.showContactUsModal}
								toggle_callus={props.closeContactUsModalHandler}
								appState={props.appState}
								backToDrawerMenu={props.backToDrawerMenu}
							/>
						</>
					) : null
				}

				{/* <AdsModal
					open={showAdsModal}
					onRequestClose={setShowAdsModal}
					changeToLandingPage={props.changeToLandingPage}
				/> */}
			</Section>
		</>
	);
};

export default memo(LandingPage);
