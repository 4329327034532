import { useFormik } from 'formik'
import React from 'react'
import Button from '../../../button'
import * as yup from 'yup'
import api from '../../../../services/api'
import { PRODUCT } from '../../../../consts/product'
import toast from 'react-hot-toast'

const validationSchema = yup.object({
  email: yup
    .string()
    .required('O e-mail é obrigatório')
    .email('O e-mail informado é inválido'),
  oldpwd: yup
    .string()
    .required('A senha atual é obrigatória')
    .test('same-password', 'A senha atual não pode ser igual a nova senha', (value, context) => {
      return context.parent.newpwd !== value
    }),
  newpwd: yup
    .string()
    .required('A nova senha é obrigatória')
    .matches(/(\w){3,20}/g, 'A nova senha deve conter entre três e vinte caracteres')
    .test('same-password', 'A nova senha não pode ser igual a senha atual', (value, context) => {
      return context.parent.oldpwd !== value
    }),
})

const ChangePassword = () => {
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: '',
      oldpwd: '',
      newpwd: '',
    },
    validationSchema,
    onSubmit: async ({ email, oldpwd, newpwd }, { resetForm }) => {
      try {
        await api.post('/vamole/app/password', {
          email,
          oldpwd,
          newpwd,
          produto: PRODUCT
        })

        toast("Senha alterada com sucesso")

        resetForm({
          email: '',
          oldpwd: '',
          newpwd: '',
        })
      } catch {
        toast("Tivemos um erro ao tentar alterar sua senha, por favor tente mais tarde")
      }
    }
  })

  return (
    <div className='w-full'>
      <h3 className='font-bold text-lg mb-4'>Alterar senha</h3>

      <form className='w-full flex flex-col space-y-4' onSubmit={handleSubmit}>
        <div className="w-full">
          <input
            className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
            type="email"
            placeholder='E-mail'
            name="email"
            value={values.email}
            required
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.email && touched.email && (
            <p className="text-sm text-red-500">{errors.email}</p>
          )}
        </div>

        <div className="w-full">
          <input
            className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
            type="password"
            placeholder='Senha atual'
            name="oldpwd"
            value={values.oldpwd}
            required
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.oldpwd && touched.oldpwd && (
            <p className="text-sm text-red-500">{errors.oldpwd}</p>
          )}
        </div>

        <div className="w-full">
          <input
            className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
            type="password"
            name="newpwd"
            placeholder='Nova senha'
            value={values.newpwd}
            required
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.newpwd && touched.newpwd && (
            <p className="text-sm text-red-500">{errors.newpwd}</p>
          )}
        </div>

        <div className='w-40'>
          <Button loading={isSubmitting} disabled={isSubmitting || !(isValid && dirty)} type="submit">
            Alterar
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ChangePassword
