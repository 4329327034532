import React, { useState } from 'react'
import { FaRegTrashAlt } from 'react-icons/fa'
import Button from '../../../button'
import toast from 'react-hot-toast'
import api from '../../../../services/api'
import { PRODUCT } from '../../../../consts/product'
import Loader from "react-loader-spinner";

const DeleteAccount = (props) => {
  const [isDeletingAccount, setIsDeletingAccount] = useState(false)

  const closeDeleteAccountConfirmation = () => {
    props.setShouldShowDeleteConfirmation(false)
  }

  const deleteAccountHandler = async () => {
    try {
      setIsDeletingAccount(true)
      await api.delete('/app/conta', {
        data: {
          email: props.subscriberData.email,
          produto: PRODUCT
        }
      })

      if (window.in_native_app) {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          type: 'CLEAR_DATABASE',
        }))
      }

      toast.error("Sua conta foi excluída com sucesso.", {
        duration: 4000
      })

      props.close()
      props.logout()
    } catch {
      toast.error("Ocorreu um problema ao tentar excluir sua conta. Por favor, tente novamente mais tarde.", {
        duration: 4000
      })
    } finally {
      setIsDeletingAccount(false)
    }
  }

  if (props.shouldShowDeleteConfirmation) {
    return (
      <div>
        <p className='text-center font-bold text-lg mt-2'>Tem certeza que deseja excluir sua conta?</p>
        <p className='text-left text-sm mt-2'>Ao excluir sua conta, todos os livros baixados serão excluídos permanentemente e não poderão ser recuperados. Para acessá-los novamente, será necessário adquirir uma nova assinatura e baixá-los novamente, caso ainda estejam disponíveis.</p>
        
        <div className='w-full flex flex-row justify-center items-center mt-8 space-x-4'>
          <div className='w-44'>
            <Button
              onClick={closeDeleteAccountConfirmation}
            >
              Não
            </Button>
          </div>

          <button
            loading={isDeletingAccount}
            disabled={isDeletingAccount}
            onClick={deleteAccountHandler}
            type="button"
            className="w-44 flex flex-row items-center justify-center underline text-red-400 bg-transparent focus:ring-0 rounded-full text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none font-bold"
          >
            {isDeletingAccount ? (
              <Loader type="Oval" color="#000000" height={24} width={24} />
            ) : "Sim"}
          </button>

        </div>
      </div>
    )
  }

  return (
    <div>
      <button className='flex flex-row items-center underline text-red-600 text-sm' onClick={() => props.setShouldShowDeleteConfirmation(true)}>
        <FaRegTrashAlt className='w-5 h-5' />
        <span className='mt-0.5 ml-2'>Excluir conta</span>
      </button>
      <p className='text-left text-xs mt-2'>Ao excluir sua conta, todos os livros baixados serão excluídos permanentemente e não poderão ser recuperados. Para acessá-los novamente, será necessário adquirir uma nova assinatura e baixá-los novamente, caso ainda estejam disponíveis.</p>
    </div>
  )
}

export default DeleteAccount