import React, { useCallback, useEffect, useRef, useState } from 'react'
import Drawer from "react-drag-drawer";
import { FiX, FiLock } from 'react-icons/fi';
import { playSong } from '../../utils/song'
import songsName from '../../enums/songsName'
import api from '../../services/api';
import vanillaMasker, { toNumber, toPattern } from 'vanilla-masker'
import Loading from '../loading';
import { Formik } from 'formik';
import Button from '../button'
import { getFields, getValidation } from '../../utils/form'
import { capitalizeText } from '../../utils/common';
import clsx from 'clsx';
import Loader from 'react-loader-spinner'
import axios from 'axios';
import creditCardType from 'credit-card-type';
import toast from 'react-hot-toast';
import { PRODUCT } from '../../consts/product';


const RegistrationModal = (props) => {
  const isOpenedRef = useRef(false)
  const [isLoading, setIsLoading] = useState(true)

  const [plan, setPlan] = useState({})
  const [formFields, setFormFields] = useState({
    general: [],
    payment: []
  })

  const loadPlan = useCallback(async () => {
    const { data } = await api.get('/app/plano')
    setPlan(data?.planos?.[0])
  }, [])

  const loadRegistrationFormFields = useCallback(async () => {
    const { data } = await api.get('/vamole/registrationformfields')
    setFormFields(data)
  }, [])

  useEffect(() => {
    if (props.isOpened && !isOpenedRef.current) {
      setIsLoading(true)

      Promise.all([
        loadPlan(),
        loadRegistrationFormFields(),
      ]).finally(() => {
        setIsLoading(false)
      })
    }
  
    isOpenedRef.current = props.isOpened
  }, [loadPlan, loadRegistrationFormFields, props.isOpened])

  const closeHandler = () => {
    playSong(songsName.TAP)
    props.close()
  }

  const getInitialFormFieldValues = () => {
    const values = {}

    formFields.general.forEach(field => {
      values[field.name] = ''
    })
    formFields.payment.forEach(field => {
      values[field.name] = ''
    })

    return values
  }

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className='flex flex-1 w-full flex-col justify-center items-center'>
          <Loading />
        </div>
      )
    }

    return (
      <div className='w-full flex flex-col justify-start items-start'>
        <Formik
          initialValues={getInitialFormFieldValues()}
          validate={(values) => {
            const errors = {};

            formFields.general.forEach(field => {
              const message = getValidation(field, values[field.name])

              if (message?.length) {
                errors[field.name] = capitalizeText(message)
              }
            })

            const paymentFields = ['msisdn', 'number', 'cvc', 'holderName', 'expiry', 'taxpayer', 'publicPlace', 'placeNumber', 'zipcode', 'neighborhood', 'city', 'state']

            paymentFields.forEach((fieldName) => {
              if (!values[fieldName]) {
                errors[fieldName] = capitalizeText("Campo obrigatório")
              }
            })

            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setSubmitting(true)
              const [exp_month, exp_year] = values.expiry.split('/')
  
              const { data: cardResponse } = await axios.post('https://api.pagar.me/core/v5/tokens', {
                type: 'card',
                card: {
                  number: values.number,
                  holder_name: values.holderName,
                  holder_document: values.taxpayer,
                  exp_month: exp_month,
                  exp_year: exp_year,
                  cvv: values.cvc
                }
              }, {
                params: {
                  appId: process.env.REACT_APP_PAGAR_ME_PUBLIC_API
                }
              })

              await api.post('/app/conta', {
                email: values.email,
                nome: values.name,
                msisdn: values.msisdn,
                pwd: values.password,
                produto: PRODUCT
              })

              await api.post('/app/plano', {
                email: values.email,
                cardtoken: cardResponse.id,
                cpfcnpj: values.taxpayer,
                valor: plan.valor,
                plano: plan.plano,
                produto: PRODUCT,
                msisdn: `55${values.msisdn}`,
                billing_address: {
                  line_1: `${values.publicPlace}, ${values.placeNumber}, ${values.neighborhood}`,
                  zip_code: values.zipcode,
                  city: values.city,
                  state: values.state,
                  country: "BR"
                }
              })

              toast.success("Assinatura realizada com sucesso.", { duration: 4000 })
            } catch {
              toast.error("Tivemos um erro ao tentar fazer sua assinatura, por favor tente novamente mais tarde.", { duration: 4000 })
            } finally {
              setSubmitting(false)
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => {
            const onChangeCardNumber = (e) => {
              const value = toNumber(e.target.value ?? '')
              setFieldValue("number", value)
            }
          
            const maskedCardNumber = () => {
              const cardNumber = values.number ?? ''
              const brand = creditCardType(cardNumber)?.[0]?.type
          
              if (brand === "diners-club") {
                return toPattern(cardNumber, "9999 999999 9999")
              }
          
              if (brand === "american-express") {
                return toPattern(cardNumber, "9999 999999 99999")
              }
          
              return toPattern(cardNumber, "9999 9999 9999 9999")
            }
          
            const onChangeExpiresDate = (e) => {
              setFieldValue("expiry", e.target.value)
            }

            const onChangeTaxpayer = (e) => {
              const value = toNumber(e.target.value ?? '')
              setFieldValue("taxpayer", value)
            }
          
            const maskedExpiresDate = toPattern(values.expiry ?? '', "99/99")

            const maskedTaxpayer = toPattern(values.taxpayer ?? '', "999.999.999-99")
          
            const onChangPlaceNUmber = (e) => {
              const value = toNumber(e.target.value ?? '')
              setFieldValue("placeNumber", value)
            }
          
            const onChangZipcode = (e) => {
              const value = toNumber(e.target.value ?? '')
              setFieldValue("zipcode", value)
            }
          
            const maskedZipcode = toPattern(values.zipcode ?? '', "99999-999")

            console.log(isSubmitting ,errors)

            return (
              <form className='w-full flex flex-col justify-start items-start' onSubmit={handleSubmit}>
                <p className='font-bold mb-2'>Preencha os dados de cadastro</p>
                <div className='w-full space-y-2'>
                  {formFields.general?.map((field) =>
                    getFields(
                      field,
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleBlur,
                      true
                    )
                  )}

                  <div className="w-full">
                    <input
                      className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                      required
                      name="msisdn"
                      value={values.msisdn}
                      placeholder="Telefone"
                      pattern="^[0-9]{11}$"
                      inputmode="numeric"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.msisdn && touched.msisdn && (
                      <p className="text-xs text-red-500">{errors.msisdn}</p>
                    )}
                  </div>
                </div>

                <p className='font-bold mt-6 mb-2'>Plano</p>
                <div className="w-full grid grid-cols-1 gap-x-4 text-sm mt-4">
                  <div className={clsx("w-full bg-slate-100 border border-slate-300 rounded-lg flex flex-col justify-start items-stretch p-4")}>
                    <div className='flex flex-col justify-center items-center space-y-2'>
                      <div className="flex flex-row justify-center items-center">
                        <span className="font-bold text-lg mr-1">
                          {vanillaMasker.toMoney(plan.valor, {
                            unit: 'R$'
                          })}
                        </span>
                        <span className="font-bold text-sm">{`- ${plan.plano}`}</span>
                      </div>

                      <p className='text-xs'>A cobrança é mensal e você pode cancelar a qualquer momento.</p>
                    </div>

                    {plan?.beneficios?.length ? (
                      <div className="text-left mt-3">
                        <p className="text-lg font-bold">Benefícios</p>

                        <div className='grid grid-cols-2 gap-x-4'>
                          {plan?.beneficios?.map(benefit => (
                            <div key={benefit.beneficio}>
                              <p className='text-xs'>{benefit.beneficio}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <p className='font-bold mt-6 mb-2'>Preencha os dados de pagamento</p>
                <div className='w-full space-y-2 mb-6'>
                  <div className="w-full">
                    <input
                      className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                      value={maskedCardNumber()}
                      name="number"
                      placeholder="Número do cartão"
                      required
                      inputmode="numeric"
                      onBlur={handleBlur}
                      onChange={onChangeCardNumber}
                    />
                    {errors.number && touched.number && (
                      <p className="text-xs text-red-500">{errors.number}</p>
                    )}
                  </div>

                  <div className='w-full grid grid-cols-2 gap-x-4'>
                    <div className="w-full">
                      <input
                        className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                        name="expiry"
                        value={maskedExpiresDate}
                        placeholder="Data de válidade"
                        required
                        maxLength={5}
                        inputmode="numeric"
                        onBlur={handleBlur}
                        onChange={onChangeExpiresDate}
                      />
                      <p className='text-xs text-slate-400'>Ex.: 12/35</p>
                      {errors.expiry && touched.expiry && (
                        <p className="text-xs text-red-500">{errors.expiry}</p>
                      )}
                    </div>

                    <div className="w-full">
                      <input
                        className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                        name="cvc"
                        value={values.cvc}
                        placeholder="CVV"
                        required
                        maxLength={4}
                        inputmode="numeric"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {errors.cvc && touched.cvc && (
                        <p className="text-xs text-red-500">{errors.cvc}</p>
                      )}
                    </div>
                  </div>

                  <div className="w-full">
                    <input
                      className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                      name="holderName"
                      value={values.holderName}
                      placeholder="Nome do titular"
                      required
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.holderName && touched.holderName && (
                      <p className="text-xs text-red-500">{errors.holderName}</p>
                    )}
                  </div>

                  <div className="w-full">
                    <input
                      className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                      name="taxpayer"
                      placeholder="CPF do titular"
                      required
                      value={maskedTaxpayer}
                      onBlur={handleBlur}
                      onChange={onChangeTaxpayer}
                    />
                    {errors.taxpayer && touched.taxpayer && (
                      <p className="text-xs text-red-500">{errors.taxpayer}</p>
                    )}
                  </div>

                  <div className='w-full space-y-2'>
                    <div className="w-full">
                      <input
                        className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                        name="zipcode"
                        value={maskedZipcode}
                        placeholder="CEP"
                        required
                        inputmode="numeric"
                        onBlur={handleBlur}
                        onChange={onChangZipcode}
                      />
                      {errors.zipcode && touched.zipcode && (
                        <p className="text-xs text-red-500">{errors.zipcode}</p>
                      )}
                    </div>
                    
                    <div className="w-full">
                      <input
                        className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                        name="publicPlace"
                        value={values.publicPlace}
                        placeholder="Logradouro"
                        required
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {errors.publicPlace && touched.publicPlace && (
                        <p className="text-xs text-red-500">{errors.publicPlace}</p>
                      )}
                    </div>
                    
                    <div className="w-full">
                      <input
                        className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                        name="placeNumber"
                        value={values.placeNumber}
                        placeholder="Número"
                        required
                        pattern="[0-9]+"
                        inputmode="numeric"
                        onBlur={handleBlur}
                        onChange={onChangPlaceNUmber}
                      />
                      {errors.placeNumber && touched.placeNumber && (
                        <p className="text-xs text-red-500">{errors.placeNumber}</p>
                      )}
                    </div>
                    
                    <div className="w-full">
                      <input
                        className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                        name="neighborhood"
                        value={values.neighborhood}
                        placeholder="Bairro"
                        required
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {errors.neighborhood && touched.neighborhood && (
                        <p className="text-xs text-red-500">{errors.neighborhood}</p>
                      )}
                    </div>

                    <div className="w-full">
                      <input
                        className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                        name="city"
                        value={values.city}
                        placeholder="Cidade"
                        required
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {errors.city && touched.city && (
                        <p className="text-xs text-red-500">{errors.city}</p>
                      )}
                    </div>
                    
                    <div className="w-full">
                      <input
                        className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                        name="state"
                        value={values.state}
                        placeholder="Estado"
                        required
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {errors.state && touched.state && (
                        <p className="text-xs text-red-500">{errors.state}</p>
                      )}
                    </div>
                  </div>
                </div>

                <Button
                  type="submit"
                  disabled={(isSubmitting || Object.keys(errors).length)}
                  onMouseEnter={() => {
                    playSong(songsName.TAP)
                  }}
                  onClick={() => {
                    playSong(songsName.TAP)
                  }}
                >
                  {isSubmitting ? (
                    <Loader
                      type="Oval"
                      visible={true}
                      color="white"
                      width={16}
                      height={16}
                      className="flex flex-1 justify-center items-center"
                    />
                  ) : "Enviar"}
                </Button>

                <div className='w-full flex flex-col justify-start items-center mt-6'>
                  <FiLock size={24} />
                  <p className='text-xs mt-2'>Todos os dados são criptografados</p>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    )
  }
    
  return (
    <Drawer
      dontApplyListeners={true}
      open={props.isOpened}
      onRequestClose={props.close}
      direction="left"
      modalElementClass="absolute top-0 left-0"
    >
      <div
        className={clsx('w-screen max-w-lg h-screen bg-modal flex flex-col justify-start items-start px-4 text-lg overflow-y-auto')}
        style={{
          paddingTop: window.native_app_status_bar_height ?? 16,
          paddingBottom: window.native_app_navigation_bar_height ?? 16
        }}
      >
        <div className='w-full flex flex-row items-center justify-between mb-6'>
          <h2 className='font-bold uppercase'>Assinar</h2>

          <button
            type="button"
            onMouseEnter={() => {
              playSong(songsName.TAP)
            }}
            onClick={closeHandler}
            className="text-black bg-transparent hover:bg-slate-200 rounded-lg text-2xl lg:text-xl ml-auto"
          >
            <FiX />
          </button>
        </div>

        {renderContent()}
      </div>
    </Drawer>
  )
}

export default RegistrationModal