export const subscribers = {
  auth: 1,
  dicalogin: "Se você tem uma assinatura ou recebeu um acesso de cortesia, digite o telefone informado com DDD e os três primeiros dígitos do seu CPF para entrar",
  icon: "https://www.contaoutravez.com.br/images/icons/graviola.png",
  idp: "ales",
  operadora: "Assinantes",
  formfieldslogin: [
    {
      label: "E-MAIL",
      name: "email",
      placeholder: "Seu e-mail",
      type: "email",
      validation: "email",
      validation_message: "E-MAIL INVÁLIDO"
    },
    {
      label: "SENHA",
      name: "pwd",
      placeholder: "Sua senha",
      type: "password",
      validation_message: "SENHA É OBRIGATÓRIA"
    }
  ]
}