import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import InputMask from "react-input-mask";
import Drawer from "react-drag-drawer";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

import { mock_api } from "./../../services/api";
import "./../../assets/styles/AuthMethodLogin.css";
import RadioButton from "../radio-button/index";
import robo from "../../assets/images/robo.png";
import imgLoading from "./../../assets/images/p-loader.gif";
import { playSong, stopSong } from '../../utils/song'
import songsName from "../../enums/songsName";
import * as yup from 'yup'
import { capitalizeText } from "../../utils/common";
import { PRODUCT } from "../../consts/product";

const RenderGif = () => {
  useEffect(() => {
    playSong(songsName.PAGE_FLIP)
    return () => {
      stopSong(songsName.PAGE_FLIP)
    }
  }, [])

  return (
    <img
      src={imgLoading}
      alt={"Loading..."}
      className="auth_method_list_loading"
    />
  )
}

const AuthMethodLogin = (props) => {
  const [method, setMethod] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showRegistrationForm, setShowRegistrationForm] = useState(props.appState === 'registration')
  const [registrationFormFields, setRegistrationFormFields] = useState({
    general: [],
    payment: []
  })
  const [plans, setPlans] = useState([])

  const screeHeight = window.screen.height;

  const buttonLabelDefault = "ENTRAR";
  const [BUTTON_LABEL, setButtonLabel] = useState(buttonLabelDefault);
  const regexTel =
    /^\(\d{2}\) \d{5}-\d{4}|\((?:1[2-9]|[2-9]\d)\) [5-9]\d{3}-\d{4}$/;
  const regexCnpjCpf =
    /^([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})$/;
  const [errorMsg, setErrorMessage] = useState("");
  const { phoneInput, toggle_callus } = props;
  const [radioCheck, setRadio] = useState(false);

  useEffect(() => {
    const buttonCPF = document.getElementById("button-cpf");
    if (!phoneInput && buttonCPF) {
      buttonCPF.disabled = !radioCheck;
    }
  }, [phoneInput, radioCheck]);

  useEffect(() => {
    phoneInput && setErrorMessage("");
  }, [phoneInput]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      mock_api.get('/access'),
      mock_api.get('/getRegistrationFormFields'),
      mock_api.get('/getPlans'),
    ])
    .then(([accessResponse, registrationFormFieldsResponse, plansResponse]) => {
      setData(accessResponse.data);
      setRegistrationFormFields(registrationFormFieldsResponse.data);
      setPlans(plansResponse.data);
    })
    .finally(() => {
      setLoading(false);
    })
  }, []);

  const getFields = (
    field,
    values,
    touched,
    errors,
    handleChange,
    handleBlur
  ) => {
    if (field.name === "cpfcnpj") {
      if (field.validation !== "cpf_cnpj") {
        return (
          <div className="inputWrapper">
            <input
              className="input-cpf"
              value={values.cpfcnpj}
              name={field.name}
              key={field.name}
              placeholder={field.placeholder}
              pattern={!new RegExp(field.validation)}
              required
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.cpfcnpj && touched.cpfcnpj && (
              <p className="auth_login_error">{errors.cpfcnpj}</p>
            )}
          </div>
        );
      } else {
        return (
          <div className="inputWrapper">
            <InputMask
              value={values.cpfcnpj}
              name={field.name}
              key={field.name}
              mask={
                values.cpfcnpj.length <= 14
                  ? "999.999.999-9999999"
                  : "99.999.999/9999-99"
              }
              placeholder="CPF / CNPJ"
              maskChar={null}
              onChange={handleChange}
              className="input-cpf"
              onBlur={handleBlur}
            />
            {errors.cpfcnpj && touched.cpfcnpj && (
              <p className="auth_login_error">{errors.cpfcnpj}</p>
            )}
          </div>
        );
      }
    }

    if (field.name === "msisdn") {
      return (
        <div className="inputWrapper">
          <InputMask
            type="tel"
            mask="(99) 99999-9999"
            maskChar={null}
            name={field.name}
            key={field.name}
            value={values.msisdn}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={field.placeholder}
            required
            onClick={() => window.scrollTo(0, screeHeight * 0.25)}
          />
          {errors.msisdn && touched.msisdn && (
            <p className="auth_login_error">{errors.msisdn}</p>
          )}
        </div>
      );
    }

    if (field.name === "username") {
      return (
        <div className="inputWrapper">
          <input
            type="text"
            name={field.name}
            placeholder={field.placeholder}
            key={field.name}
            value={values.username}
            required
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.username && touched.username && (
            <p className="auth_login_error">{errors.username}</p>
          )}
        </div>
      );
    }

    if (field.name === "email") {
      return (
        <div className="inputWrapper">
          <input
            type="email"
            name={field.name}
            placeholder={field.placeholder}
            key={field.name}
            value={values.email}
            required
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.email && touched.email && (
            <p className="auth_login_error">{errors.email}</p>
          )}
        </div>
      );
    }

    if (field.name === "password") {
      return (
        <div className="inputWrapper">
          <input
            key={field.name}
            type="password"
            name="password"
            placeholder={field.placeholder}
            required
            onBlur={handleBlur}
            pattern={field.validation}
            value={values.password}
            onChange={handleChange}
            onClick={() => window.scrollTo(0, screeHeight * 0.25)}
          />
          {errors.password && touched.password && (
            <p className="auth_login_error">{errors.password}</p>
          )}
        </div>
      );
    }

    return (
      <div className="inputWrapper">
        <InputMask
          key={field.name}
          type={field.type}
          name={field.name}
          placeholder={field.placeholder}
          required
          onBlur={handleBlur}
          value={values[field.name]}
          pattern={field.validation}
          inputmode={field.inputmode}
          onChange={handleChange}
          mask={(() => {
            if (field.name === 'validade') {
              return "99/99"
            }

            if (field.name === 'cvv') {
              return "9999"
            }

            return null
          })()}
          maskChar={null}
        />
        {errors[field.name] && touched[field.name] && (
          <p className="auth_login_error">{errors[field.name]}</p>
        )}
      </div>
    )
  };

  const getValidation = (field, value) => {
    if (field.name === "username") {
      if (!new RegExp(field.validation).test(value)) {
        return field.validation_message;
      }

      if (value === "") {
        return "Campo obrigatório";
      }
    }

    if (field.name === 'email') {
      if (field.validation === "email") {
        const schema = yup.string().email()
        const result = schema.isValidSync(value)

        if (!result) {
          return field.validation_message;
        }

        if (!value) {
          return "Campo obrigatório";
        }
      }
    }

    if (field.name === "cpfcnpj") {
      if (field.validation === "cpf_cnpj") {
        if (value.match(regexCnpjCpf) === null) {
          return field.validation_message;
        }
      } else {
        if (!new RegExp(field.validation).test(value)) {
          return field.validation_message;
        }

        if (value === "") {
          return "Campo obrigatório";
        }
      }
    }

    if (field.name === "password") {
      if (!new RegExp(field.validation).test(value)) {
        return field.validation_message;
      }

      if (value === "") {
        return "Campo obrigatório";
      }
    }

    if (field.name === "msisdn") {
      if (value.match(regexTel) === null) {
        return field.validation_message;
      }

      if (value === "") {
        return "Campo obrigatório";
      }
    }

    if (value === "") {
      return "Campo obrigatório";
    }

    if (field.validate) {
      if (!new RegExp(field.validation).test(value)) {
        return field.validation_message
      }
    }
  }

  const getInitialFormFieldValues = () => {
    const values = {}

    registrationFormFields.general.forEach(field => {
      values[field.name] = ''
    })
    registrationFormFields.payment.forEach(field => {
      values[field.name] = ''
    })

    return values
  }

  if (showRegistrationForm) {
    return (
      <div className="auth_login_container">
        <div
          className="auth_method_header"
          onMouseEnter={() => {
            playSong(songsName.TAP)
          }}
          onClick={() => {
            playSong(songsName.TAP)
            setShowRegistrationForm(false)
          }}
        >
          <div className="auth_method_item_infos">
            <MdKeyboardArrowLeft size={40} color="#000000AA" />
            <span>Assinar</span>
          </div>
        </div>

        {loading ? (
            <RenderGif />
          ) : (
          <Formik
            initialValues={getInitialFormFieldValues()}
            validate={(values) => {
              const errors = {};

              if (!values.planId?.length) {
                errors["planId"] = "Selecione um plano"
              }

              registrationFormFields.general.forEach(field => {
                const message = getValidation(field, values[field.name])

                if (message?.length) {
                  errors[field.name] = capitalizeText(message)
                }
              })

              registrationFormFields.payment.forEach(field => {
                const message = getValidation(field, values[field.name])

                if (message?.length) {
                  errors[field.name] = capitalizeText(message)
                }
              })

              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              // Create account
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              const clickHandler = (id) => () => {
                setFieldValue('planId', id)
              }

              return (
                <form onSubmit={handleSubmit}>
                  {registrationFormFields.general?.map((field) =>
                    getFields(
                      field,
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleBlur
                    )
                  )}

                  <div className="plans_wrapper">
                    {plans?.map(plan => {
                      const isSelected = values.planId === plan.id
                      return (
                        <div className={`plan_wrapper ${isSelected ? 'selected' : ''}`}>
                          <div>
                            <p className="plan_book_month">
                              <b className="mr-1">{plan.books}</b>{`${plan.books >= 2 ? 'Livros por mês' : 'Livro por mês'}`}
                            </p>
                            <p className="plan_price_wrapper">
                              <span className="font-bold text-lg">{`${plan.price}/${plan.per}`}</span>
                            </p>

                            <button
                              disabled={isSelected}
                              className={isSelected ? "disabled" : ""}
                              type="button"
                              onClick={clickHandler(plan.id)}
                            >
                              Selecionar
                            </button>
                          </div>

                          <div className="benefits mt-3">
                            <p className="benefits_title font-bold">Benefícios</p>

                            {plan.benefits.map(benefit => (
                              <div>  
                                <p>{benefit.title}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  {registrationFormFields.payment?.map((field) =>
                    getFields(
                      field,
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleBlur
                    )
                  )}

                  <button
                    id="button-cpf"
                    type="submit"
                    disabled={(isSubmitting || Object.keys(errors).length)}
                    className="auth_login_button_login"
                    onMouseEnter={() => {
                      playSong(songsName.TAP)
                    }}
                    onClick={() => {
                      playSong(songsName.TAP)
                    }}
                  >
                    {isSubmitting ? '• • •' : 'Enviar'}
                  </button>
                </form>
              )
            }}
          </Formik>
        )}
      </div>
    )
  }

  if (!method) {
    return (
      <div className="auth-method-login">
        <p className="auth_method_title">
          ESCOLHA A FORMA DE COMEÇAR A NAVEGAR
        </p>
        <div className="auth_method_list">
          {loading ? (
            <RenderGif />
          ) : (
            <>
              {data.map((item, index) => {
                return (
                  <div
                    className="auth_method_item"
                    onMouseEnter={() => {
                      playSong(songsName.TAP)
                    }}
                    onClick={() => {
                      playSong(songsName.TAP)
                      setMethod(item)
                    }}
                    key={index}
                  >
                    <div className="auth_method_item_infos">
                      <img src={item.icon} alt={`item${index}`} />
                      <span>{item.operadora}</span>
                    </div>
                    <MdKeyboardArrowRight size={40} color="#000000AA" />
                  </div>
                );
              })}
            </>
          )}
        </div>

        {!loading ? (
          <div className="create_account_section">
            <p>Ainda não possui uma conta?</p>
            <p
              className="registration_link"
              onClick={() => {
                setShowRegistrationForm(true)
              }}
            >
              Cadastrar-se agora
            </p>
          </div>
        ): null}

        <div className="auth_method_contact">
          <button
            onMouseEnter={() => {
              playSong(songsName.TAP)
            }}
            onClick={() => {
              playSong(songsName.TAP)
              toggle_callus(true)
            }}
            id="contact"
            className="auth_method_contact_button"
          >
            FALE CONOSCO
          </button>
        </div>
      </div>
    );
  }

  if (method && method?.patterns?.length) {
    return (
      <div className="auth-method-login">
        <p className="auth_method_title">
          ESCOLHA A FORMA DE COMEÇAR A NAVEGAR
        </p>
        <div className="auth_method_list">
          {loading ? (
            <RenderGif />
          ) : (
            <>
              <div
                className="auth_method_item"
                onMouseEnter={() => {
                  playSong(songsName.TAP)
                }}
                onClick={() => {
                  playSong(songsName.TAP)
                  setMethod(!method)
                }}
              >
                <div className="auth_method_item_infos">
                  <MdKeyboardArrowLeft size={40} color="#000000AA" />
                  <img src={method.icon} alt={method.operadora} />
                  <span>{method.operadora}</span>
                </div>
              </div>

              {method?.patterns?.map((item, index) => {
                return (
                  <div
                    className="auth_method_item"
                    onMouseEnter={() => {
                      playSong(songsName.TAP)
                    }}
                    onClick={() => {
                      playSong(songsName.TAP)
                      setMethod(item)
                    }}
                    key={index}
                  >
                    <div className="auth_method_item_infos">
                      <img src={item.icon} alt={`item${index}`} />
                      <span>{item.operadora}</span>
                    </div>
                    <MdKeyboardArrowRight size={40} color="#000000AA" />
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div className="auth_method_contact">
          <button
            onMouseEnter={() => {
              playSong(songsName.TAP)
            }}
            onClick={() => {
              playSong(songsName.TAP)
              toggle_callus(true)
            }}
            id="contact"
            className="auth_method_contact_button"
          >
            FALE CONOSCO
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="auth_login_container">
      <div
        className="auth_method_header"
        onMouseEnter={() => {
          playSong(songsName.TAP)
        }}
        onClick={() => {
          playSong(songsName.TAP)
          setMethod(!method)
        }}
      >
        <div className="auth_method_item_infos">
          <MdKeyboardArrowLeft size={40} color="#000000AA" />
          <img src={method.icon} alt={method.operadora} />
          <span>{method.operadora}</span>
        </div>
      </div>
      <Formik
        initialValues={{ msisdn: "", password: "", cpfcnpj: "", username: "" }}
        validate={(values) => {
          const errors = {};

          method.formfieldslogin.map((field) => {
            if (field.name === "username") {
              if (!new RegExp(field.validation).test(values.username)) {
                errors.username = field.validation_message;
              }

              if (values.username === "") {
                errors.username = "Campo obrigatório";
              }
            }

            if (field.name === 'email') {
              if (field.validation === "email") {
                const schema = yup.string().email()
                const result = schema.isValidSync(values.email)

                if (!result) {
                  errors.email = field.validation_message;
                }

                if (!values.email) {
                  errors.email = "Campo obrigatório";
                }
              }
            }

            if (field.name === "cpfcnpj") {
              if (field.validation === "cpf_cnpj") {
                if (values.cpfcnpj.match(regexCnpjCpf) === null) {
                  errors.cpfcnpj = field.validation_message;
                }
              } else {
                if (!new RegExp(field.validation).test(values.cpfcnpj)) {
                  errors.cpfcnpj = field.validation_message;
                }

                if (values.cpfcnpj === "") {
                  errors.cpfcnpj = "Campo obrigatório";
                }
              }
            }

            if (field.name === "password") {
              if (!new RegExp(field.validation).test(values.password)) {
                errors.password = field.validation_message;
              }

              if (values.password === "") {
                errors.password = "Campo obrigatório";
              }
            }

            if (field.name === "msisdn") {
              if (values.msisdn.match(regexTel) === null) {
                errors.msisdn = field.validation_message;
              }

              if (values.msisdn === "") {
                errors.msisdn = "Campo obrigatório";
              }
            }
          });

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          props.onSubmit();
          setButtonLabel("• • •");
          const { idp, auth } = method;
          const data = {
            idp,
            auth: auth.toString(),
            produto: "L",
            tipo: "3",
          };

          const msisdn =
            values.msisdn && "55" + values.msisdn.replace(/[^a-zA-Z0-9]/g, "");

          if (!!msisdn) {
            data.msisdn = msisdn;
          }

          const password = values.password;

          if (!!password) {
            data.password = password;
          }

          const cpfcnpj = values.cpfcnpj;
          if (!!cpfcnpj) {
            data.cpfcnpj = cpfcnpj.replace(/[^0-9]+/g, "");
          }

          const username = values.username;

          if (!!username) {
            data.username = username;
          }

          mock_api.post('/getportfolio', data)
            .then((response) => {
              setTimeout(() => props.onComplete(response.data), 1000);
            })
            .catch((error) => {
              setTimeout(() => {
                playSong(songsName.SEAGULLS)
                props.onSubmitError()
              }, 1000);
            })
            .finally(() => {
              setTimeout(() => {
                try {
                  setSubmitting(false);
                } catch (error) { }
                try {
                  setButtonLabel(buttonLabelDefault);
                } catch (error) { }
              }, 1000);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              {method.formfieldslogin.map((field) =>
                getFields(
                  field,
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur
                )
              )}

              {!phoneInput && (
                <div className="checkbox-wrapper">
                  <RadioButton radioCheck={radioCheck} setRadio={setRadio} />

                  <p>Não sou um robô</p>
                  <img src={robo} />
                </div>
              )}
              <button
                id="button-cpf"
                type="submit"
                className="auth_login_button_login"
                onMouseEnter={() => {
                  playSong(songsName.TAP)
                }}
                onClick={() => {
                  playSong(songsName.TAP)
                }}
              >
                {BUTTON_LABEL}
              </button>
              <button
                id="button-cpf"
                className="auth_login_button_other"
                onMouseEnter={() => {
                  playSong(songsName.TAP)
                }}
                onClick={() => {
                  playSong(songsName.TAP)
                  setMethod(!method)
                }}
              >
                VOLTAR
              </button>
              <div className="button-help text-white" onClick={() => setModalOpen(true)}>
                <AiOutlineQuestionCircle size={30} />
              </div>
              {!phoneInput && <p className="auth_login_error">{errorMsg}</p>}
              <div className="auth_method_contact">
                <button
                  onMouseEnter={() => {
                    playSong(songsName.TAP)
                  }}
                  onClick={() => {
                    playSong(songsName.TAP)
                    toggle_callus(true)
                  }}
                  id="contact"
                  className="auth_method_contact_button"
                >
                  FALE CONOSCO
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
      <Drawer dontApplyListeners={true} open={modalOpen} onRequestClose={() => setModalOpen(false)}>
        <div className="modal">
          <div className="modal-container">
            <p style={{ textAlign: "left" }}>{method.dicalogin}</p>
          </div>
          <div className="modal-button-container">
            <button
              onMouseEnter={() => {
                playSong(songsName.TAP)
              }}
              onClick={() => {
                playSong(songsName.TAP)
                setModalOpen(false)
              }}
            >
              FECHAR
            </button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default AuthMethodLogin;
