import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Drawer from "react-drag-drawer";
import { FiX } from 'react-icons/fi';
import { playSong } from '../../utils/song'
import songsName from '../../enums/songsName'
import api from '../../services/api';
import Loading from '../loading';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useFormik } from 'formik';
import Button from '../button'
import { getFields } from '../../utils/form'
import * as yup from 'yup'
import RecoveryPassword from './components/reset-password';
import toast from 'react-hot-toast';
import clsx from 'clsx';
import Loader from 'react-loader-spinner'
import { AnimatePresence, motion } from 'framer-motion'
import { PRODUCT } from '../../consts/product';
import { subscribers } from './consts';
import { sleep } from '../../utils/helper';
import { SUBSCRIBER_PROVIDER_NAME } from '../../consts/common';

const slideVariants = {
  hidden: { opacity: 0, x: "100vw", transition: { duration: 0.2, delay: 0 } },
  visible: { opacity: 1, x: "0vw", transition: { duration: 0.2, delay: 0.5 } },
  exit: { opacity: 0, x: "-100vw", transition: { duration: 0.2, delay: 0 } },
};

const regexTel =
    /^\(\d{2}\) \d{5}-\d{4}|\((?:1[2-9]|[2-9]\d)\) [5-9]\d{3}-\d{4}$/;
  const regexCnpjCpf =
    /^([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})$/;

const LoginModal = (props) => {
  const isOpenedRef = useRef(false)
  const [isLoading, setIsLoading] = useState(true)

  const [access, setAccess] = useState([])
  const [method, setMethod] = useState(null)

  const {
    values,
    errors,
    touched,
    resetForm,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: { email: "", msisdn: "", password: "", cpfcnpj: "", username: "" },
    validate: (values) => {
      const errors = {};

      method.formfieldslogin.forEach((field) => {
        if (field.name === "username") {
          if (!new RegExp(field.validation).test(values.username)) {
            errors.username = field.validation_message;
          }

          if (values.username === "") {
            errors.username = "Campo obrigatório";
          }
        }

        if (field.name === 'email') {
          if (field.validation === "email") {
            const schema = yup.string().email()
            const result = schema.isValidSync(values.email)

            if (!result) {
              errors.email = field.validation_message;
            }

            if (!values.email) {
              errors.email = "Campo obrigatório";
            }
          }
        }

        if (field.name === "cpfcnpj") {
          if (field.validation === "cpf_cnpj") {
            if (values.cpfcnpj.match(regexCnpjCpf) === null) {
              errors.cpfcnpj = field.validation_message;
            }
          } else {
            if (!new RegExp(field.validation).test(values.cpfcnpj)) {
              errors.cpfcnpj = field.validation_message;
            }

            if (values.cpfcnpj === "") {
              errors.cpfcnpj = "Campo obrigatório";
            }
          }
        }

        if (field.name === "password") {
          if (!new RegExp(field.validation).test(values.password)) {
            errors.password = field.validation_message;
          }

          if (values.password === "") {
            errors.password = "Campo obrigatório";
          }
        }

        if (field.name === "msisdn") {
          if (values.msisdn.match(regexTel) === null) {
            errors.msisdn = field.validation_message;
          }

          if (values.msisdn === "") {
            errors.msisdn = "Campo obrigatório";
          }
        }
      });

      return errors;
    },
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      const { idp, auth } = method;
      const data = {
        produto: "L",
      };

      const msisdn =
        values.msisdn && "55" + values.msisdn.replace(/[^a-zA-Z0-9]/g, "");

      if (!!msisdn) {
        data.msisdn = msisdn;
      }

      const password = values.password;

      if (!!password) {
        data.password = password;
      }

      const cpfcnpj = values.cpfcnpj;
      if (!!cpfcnpj) {
        data.cpfcnpj = cpfcnpj.replace(/[^0-9]+/g, "");
      }

      const username = values.username;

      if (!!username) {
        data.username = username;
      }

      if (!!values.email) {
        data.email = values.email
      }

      if (!!values.pwd) {
        data.pwd = values.pwd
      }

      if (method?.operadora === "Assinantes") {
        try {
          const response = await api.post('/app/signin', data)
          const hash = response.data.token
          
          await sleep(500)
          const portfolioResponse = await api.get(`/covportfolio/${hash || ''}`)
          props.onLoginSuccess(portfolioResponse.data)
          props.updateSubscriberData(response.data)
          props.onPortfolioLoaded(portfolioResponse.data)
          props.updateLoginProvider(method?.operadora)
  
          if (response.data.name) {
            toast.success(`Bem vindo ${response.data.name}`, {
              duration: 4000
            })
          }
  
          props.close()
          setMethod(undefined)
  
          resetForm({
            email: "",
            msisdn: "",
            password: "",
            cpfcnpj: "",
            username: ""
          })
        } catch {
          setTimeout(() => {
            playSong(songsName.SEAGULLS)
          
            toast.error("Credenciais inválidas. Por favor, verifique e tente novamente.")
          }, 1000);
        } finally {
          setSubmitting(false);
        }

        return
      }

      try {
        const response = await api.post('/covportfolio', data)
        props.onLoginSuccess(response.data)
        
        await sleep(500)
        const hash = window.localStorage.getItem('@cov/hash')
        const portfolioResponse = await api.get(`/covportfolio/${hash ?? ''}`)
        props.onPortfolioLoaded(portfolioResponse.data)
        props.updateLoginProvider(method?.operadora)
  
        props.close()
        setMethod(undefined)
  
        resetForm({
          email: "",
          msisdn: "",
          password: "",
          cpfcnpj: "",
          username: ""
        })
      } catch {
        setTimeout(() => {
          playSong(songsName.SEAGULLS)
        
          toast.error("Credenciais inválidas. Por favor, verifique e tente novamente.")
        }, 1000);
      } finally {
        setSubmitting(false);
      }

        // .then((response) => {
        //   setTimeout(() => {
        //     const hash = response.data.token
        //     api
        //       .get(`/covportfolio/${hash ?? ''}`)
        //       .then((portfolioResponse) => {
        //         props.updateSubscriberData(response.data)
        //         props.onLoginSuccess(portfolioResponse.data)
        //         props.onPortfolioLoaded(portfolioResponse.data)

        //         if (response.data.name) {
        //           toast.success(`Bem vindo ${response.data.name}`, {
        //             duration: 4000
        //           })
        //         }
        //       })
        //     props.close()
        //     setMethod(undefined)

        //     resetForm({
        //       email: "",
        //       msisdn: "",
        //       password: "",
        //       cpfcnpj: "",
        //       username: ""
        //     })
        //   }, 1000);
        // })
        // .catch((error) => {
        //   setTimeout(() => {
        //     playSong(songsName.SEAGULLS)
          
        //     toast("Credenciais inválidas. Por favor, verifique e tente novamente.")
        //   }, 1000);
        // })
        // .finally(() => {
        //   setTimeout(() => {
        //     try {
        //       setSubmitting(false);
        //     } catch (error) { }
        //   }, 1000);
        // });
    }
  })

  const isPatternSelectedForLogin = useMemo(() => {
    const patternsAccess = access?.find(item => !!item.patterns)
    return !!patternsAccess?.patterns?.find(pattern => pattern?.operadora === method?.operadora)
  }, [access, method])

  const [showRecoveryPasswordForm, setShowRecoveryPasswordForm] = useState(false)

  const loadAccess = useCallback(async () => {
    const { data: payload } = await api.get('/operadora', {
      params: {
        appaccess: 1
      }
    })

    const data = payload.categoria.map((item) => {
      if (item.convidados?.length) {
        return item.convidados[0]
      }

      if (item.assinantes?.length) {
        return item.assinantes[0]
      }

      return item
    })

    setAccess(data)
  }, [])

  useEffect(() => {
    setIsLoading(true)

    loadAccess().finally(() => {
      setIsLoading(false)
    })

    }, [loadAccess])

  const closeHandler = () => {
    playSong(songsName.TAP)
    props.close()

    setMethod(null)
    resetForm({ email: "", msisdn: "", password: "", cpfcnpj: "", username: "" })
  }

  const forgotPasswordHandler = useCallback(async () => {
    if (!values.email.length) {
      toast('Preencha o email para solicitar a recuperação da senha')
      return
    }

    try {
      await api.post("/vamole/app/password", {
        "email": values.email,
        "produto": PRODUCT
      })
      toast('Você receberá um código por e-mail se seu e-mail for encontrado em nossa base de dados')
      setShowRecoveryPasswordForm(true)
    } catch {
      toast('Não foi possível solicitar a troca da senha, por favor contate a equipe de suporte')
    }
  }, [values.email])

  const renderContent = useCallback(() => {
    if (isLoading) {
      return (
        <div className='flex flex-1 w-full flex-col justify-center items-center'>
          <Loading />
        </div>
      )
    }

    if (showRecoveryPasswordForm) {
      return <RecoveryPassword email={values.email} close={() => setShowRecoveryPasswordForm(false)} />
    }

    if (!method) {
      return (
        <div className='w-full flex flex-col gap-x-4'>
          {access.map((item, index) => {
            return (
              <div
                key={index}
                className='w-full flex flex-col cursor-pointer border-b border-b-black'
                onMouseEnter={() => {
                  playSong(songsName.TAP)
                }}
                onClick={() => {
                  playSong(songsName.TAP)
                  
                  if (item?.operadora === "Assinantes") {
                    setMethod(subscribers)
                    return
                  }

                  setMethod(item)
                }}
              >
                <div className="w-full flex flex-1 flex-row justify-start items-center py-4">
                  <div className="w-full flex flex-1 flex-row justify-start items-center mr-4">
                    <div className="w-9 h-10 rounded-lg overflow-hidden mr-2">
                      <img className='w-full h-full blend-mode-multiply object-contain' src={item.icon} alt={`item${index}`} />
                    </div>
                    <span>{item.operadora ?? item.item}</span>
                  </div>

                  <MdKeyboardArrowRight size={40} color="#000000AA" />
                </div>
              </div>
            );
          })}

          <div className='w-full flex flex-col justify-center items-center mt-6'>
            <p className='text-sm'>Ainda não possui uma assinatura?</p>
            <p
              className='text-sm underline cursor-pointer'
              onMouseEnter={() => {
                playSong(songsName.TAP)
              }}
              onClick={() => {
                playSong(songsName.TAP)
                props.openRegistration()
              }}
            >
              Assinar agora
            </p>
          </div>
        </div>
      )
    }

    if (method && method?.patterns?.length) {
      return (
        <div className='w-full h-full flex flex-col'>
          <div className='w-full h-full flex flex-col gap-x-4 overflow-auto'>
            {method?.patterns?.map((item, index) => {
              return (
                <div
                  key={index}
                  className='w-full flex flex-col cursor-pointer border-b border-b-black'
                  onMouseEnter={() => {
                    playSong(songsName.TAP)
                  }}
                  onClick={() => {
                    playSong(songsName.TAP)
                    setMethod(item)
                  }}
                >
                  <div className="w-full flex flex-1 flex-row justify-start items-center py-4">
                    <div className="w-full flex flex-1 flex-row justify-start items-center mr-4">
                    <div className="w-9 h-10 rounded-lg overflow-hidden mr-2">
                      <img className='w-full h-full blend-mode-multiply object-contain' src={item.icon} alt={`item${index}`} />
                    </div>
                      <span>{item.operadora ?? item.item}</span>
                    </div>

                    <MdKeyboardArrowRight size={40} color="#000000AA" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )
    }

    return (
      <form className='w-full flex flex-col gap-y-2' onSubmit={handleSubmit}>
        {method?.operadora === SUBSCRIBER_PROVIDER_NAME ? <p className='text-sm mb-2 font-medium'>Se você tem uma assinatura, digite o e-mail e sua senha para entrar</p> : null}
        {method.dicalogin && method?.operadora !== SUBSCRIBER_PROVIDER_NAME ? <p className='text-sm mb-2 font-medium'>{method.dicalogin}</p> : null}
        
        {method.formfieldslogin.map((field) =>
          getFields(
            field,
            values,
            touched,
            errors,
            handleChange,
            handleBlur
          )
        )}
        {method?.operadora === SUBSCRIBER_PROVIDER_NAME ? (
          <p
            onClick={forgotPasswordHandler}
            className='cursor-pointer underline text-sm text-right mb-4'
          >
              Esqueci minha senha
          </p>
        ) : null}

        <Button
          disabled={isSubmitting}
          type="submit"
          onMouseEnter={() => {
            playSong(songsName.TAP)
          }}
          onClick={() => {
            playSong(songsName.TAP)
          }}
        >
          {isSubmitting ? (
            <Loader
              type="Oval"
              visible={true}
              color="white"
              width={16}
              height={16}
              className="flex flex-1 justify-center items-center"
            />
          ) : "Enviar"}
        </Button>
      </form>
    )
  }, [access, errors, forgotPasswordHandler, handleBlur, handleChange, handleSubmit, isLoading, isSubmitting, method, props, showRecoveryPasswordForm, touched, values])

  const renderContentKey = useMemo(() => {
    if (isLoading) {
      return "loading"
    }

    if (showRecoveryPasswordForm) {
      return "showRecoveryPasswordForm"
    }

    if (!method) {
      return "access"
    }

    if (method && method?.patterns?.length) {
      return "patterns"
    }

    return "loginForm"
  }, [isLoading, method, showRecoveryPasswordForm])
    
  return (
    <Drawer
      dontApplyListeners={true}
      open={props.isOpened}
      onRequestClose={closeHandler}
      direction="left"
      modalElementClass="absolute top-0 left-0"
    >
      <div
        className={clsx('w-screen max-w-lg h-screen bg-modal flex flex-col justify-start items-start px-4 pb-4 text-lg overflow-y-auto')}
        style={{
          paddingTop: window.native_app_status_bar_height ?? 16
        }}
      >
        <div className={clsx('w-full flex flex-row items-center justify-between mb-6', method && 'items-center', !method && 'items-start')}>
          <div className='w-full flex flex-1 flex-row items-center'>
            {method ? (
              <>
                <div
                  className='flex flex-row justify-start items-center cursor-pointer'
                  onMouseEnter={() => {
                    playSong(songsName.TAP)
                  }}
                  onClick={() => {
                    playSong(songsName.TAP)

                    resetForm({ email: "", msisdn: "", password: "", cpfcnpj: "", username: "" })

                    if (showRecoveryPasswordForm) {
                      setShowRecoveryPasswordForm(false)

                      return
                    }

                    if (isPatternSelectedForLogin) {
                      const patternsAccess = access?.find(item => !!item.patterns)
                      setMethod(patternsAccess)

                      return
                    }

                    setMethod(null)
                  }}
                >
                  <MdKeyboardArrowLeft size={40} color="#000000AA" />
                </div>

                <div className="w-9 h-10 rounded-lg overflow-hidden mx-2">
                  <img className='w-full h-full blend-mode-multiply object-contain' src={method.icon} alt={method.operadora} />
                </div>
              </>
            ) : null}

            {!method ? (
              <div
                className='flex flex-row justify-start items-center cursor-pointer'
                onMouseEnter={() => {
                  playSong(songsName.TAP)
                }}
                onClick={() => {
                  playSong(songsName.TAP)

                  props.backToDrawerMenu()

                  setMethod(null)
                }}
              >
                <MdKeyboardArrowLeft size={40} color="#000000AA" />
              </div>
            ) : null}

            <h2 className='font-bold text-sm'>
              {method ? (method.operadora ?? method.item) : 'ESCOLHA A FORMA DE COMEÇAR A NAVEGAR'}
            </h2>
          </div>

          <button
            type="button"
            onMouseEnter={() => {
              playSong(songsName.TAP)
            }}
            onClick={closeHandler}
            className={`text-black bg-transparent hover:bg-slate-200 rounded-lg text-2xl lg:text-xl`}
          >
            <FiX />
          </button>
        </div>
        
        <AnimatePresence mode="wait">
          <motion.div
            key={renderContentKey}
            variants={slideVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            custom={0.2}
            className="w-full flex flex-col gap-y-4"
          >
            {renderContent()}
          </motion.div>
        </AnimatePresence>
      </div>
    </Drawer>
  )
}

export default LoginModal