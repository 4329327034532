import React from 'react'
import Drawer from "react-drag-drawer";
import { playSong } from '../../utils/song'
import songsName from '../../enums/songsName'
import { FiChevronLeft, FiX } from 'react-icons/fi';

const AboutModal = (props) => {
  const closeHandler = () => {
    playSong(songsName.TAP)
    props.close()
  }

  return (
    <Drawer
      dontApplyListeners={true}
      open={props.isOpened}
      onRequestClose={props.close}
      direction="left"
      modalElementClass="absolute top-0 left-0"
    >
      <div
        className={`w-screen max-w-lg h-screen bg-modal flex flex-col justify-start items-start px-4 pb-4 text-lg overflow-y-auto`}
        style={{
          paddingTop: window.native_app_status_bar_height ?? 16
        }}
      >
        <div className='w-full flex flex-row items-center justify-between mb-6'>
          <button
            type="button"
            className='text-black bg-transparent hover:bg-slate-200 rounded-lg text-2xl mb-1 -ml-2 mr-2'
            onClick={props.backToDrawerMenu}
          >
            <FiChevronLeft />
          </button>

          <div className='flex flex-1 flex-row items-center'>
            <h2 className='font-bold'>SOBRE NÓS</h2>
          </div>

          <button
            type="button"
            onMouseEnter={() => {
              playSong(songsName.TAP)
            }}
            onClick={closeHandler}
            className="text-black bg-transparent hover:bg-slate-200 rounded-lg text-2xl lg:text-xl ml-auto"
          >
            <FiX />
          </button>
        </div>

        <p className='text-sm'>
          O Conta Outra Vez! é uma plataforma de livros que conecta novos
          autores a novos e antigos leitores. Todos os meses entregamos
          títulos digitais para serem compartilhados entre adultos e
          crianças, numa relação de mediação e descoberta. Acreditamos na
          riqueza da diversidade de nossos autores e de suas escritas para
          transportar nossos leitores aos infinitos universos da literatura
          para a infância.
        </p>
      </div>
    </Drawer>
  )
}

export default AboutModal