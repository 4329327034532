import React, { useEffect, useState } from 'react'
import { FiX } from 'react-icons/fi'
import ReactDragDrawer from 'react-drag-drawer'
import { playSong } from '../../utils/song'
import songsName from '../../enums/songsName'
import {
  BsEnvelope,
  BsPerson,
  BsInfoCircle,
  BsQuestionCircle,
  BsPlayCircle,
  BsPauseCircle,
  BsBoxArrowRight,
  BsCash,
  BsFileEarmarkText,
  BsShieldLock,
  BsLightbulb,
  BsBoxArrowInRight,
  BsPersonPlus
} from 'react-icons/bs'
import LogoImage from '../../assets/images/new-cov-icon.png'
import clsx from 'clsx'
import { SUBSCRIBER_PROVIDER_NAME } from '../../consts/common'

const DrawerMenu = (props) => {
  const [showConfirmLogout, setShowConfirmLogout] = useState(false)

  useEffect(() => {
    if (!props.isOpened && showConfirmLogout) {
      setShowConfirmLogout(false)
    }
  }, [props.isOpened, showConfirmLogout])

  const closeHandler = () => {
    props.close()
    setShowConfirmLogout(false)
  }

  const logoutHandler = () => {
    playSong(songsName.BUBBLES)
    closeHandler()
    props.logout()
  }

  const openRegistrationHandler = () => {
    if (window.in_native_app) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
				type: 'OPEN_REGISTRATION',
			}))

      return
    }

    props.openRegistrationModal()
  }

  return (
    <ReactDragDrawer
      open={props.isOpened}
      onRequestClose={closeHandler}
      direction="left"
      modalElementClass="absolute top-0 left-0"
    >
      <div 
        className='w-screen max-w-lg h-screen bg-modal flex flex-col justify-start items-start px-4 text-lg'
        style={{
          paddingTop: window.native_app_status_bar_height ?? 16,
          paddingBottom: window.native_app_navigation_bar_height ?? 16
        }}
      >
        <div className='flex flex-row justify-between items-center'>
          <div className='flex flex-row items-center'>
            <img src={LogoImage} alt='Logo' className='w-12 h-12 rounded-lg' />
            <h1 className='ml-2 font-boldb'>Conta Outra Vez!</h1>
          </div>

          <button
            type="button"
            onClick={closeHandler}
            className={`text-black bg-transparent hover:bg-slate-200 rounded-lg absolute right-4 inline-flex items-center justify-center text-2xl lg:text-xl`}
          >
            <FiX />
          </button>
        </div>

        <hr className='w-full h-0.25 mt-4 mb-2 bg-slate-300' />

        <div className='w-full flex flex-1 flex-col justify-start items-start gap-y-1'>
        {!props.isLoggedIn ? (
            <>
              <button className='flex flex-row justify-center items-center py-2.5' type="button" onMouseEnter={() => { playSong(songsName.TAP) }} onClick={props.openLoginModal}>
                <BsBoxArrowInRight className='w-6 h-6' />
                <span className='ml-2 mt-0.5 text-14'>Entrar</span>
              </button>
              <button className='flex flex-row justify-center items-center py-2.5' type="button" onMouseEnter={() => { playSong(songsName.TAP) }} onClick={openRegistrationHandler}>
                <BsPersonPlus className='w-6 h-6' />
                <span className='ml-2 mt-0.5 text-14'>Assinar</span>
              </button>
            </>
          ) : null}
          
          {!showConfirmLogout ? (
            <>
              {props.isLoggedIn && props.loginProvider === SUBSCRIBER_PROVIDER_NAME ? (
                <>
                  <button className='flex flex-row justify-center items-center py-2.5' type="button" onMouseEnter={() => { playSong(songsName.TAP) }} onClick={props.openProfileModal}>
                    <BsPerson className='w-6 h-6' />
                    <span className='ml-2 mt-0.5 text-14'>Perfil</span>
                  </button>
                  <button className='flex flex-row justify-center items-center py-2.5' type="button" onMouseEnter={() => { playSong(songsName.TAP) }} onClick={props.openPaymentModal}>
                    <BsCash className='w-6 h-6' />
                    <span className='ml-2 mt-0.5 text-14'>Assinatura</span>
                  </button>
                </>
              ) : null}
              <button className='flex flex-row justify-center items-center py-2.5' type="button" onMouseEnter={() => { playSong(songsName.TAP) }} onClick={props.openKnowMore}>
                <BsLightbulb className='w-6 h-6' />
                <span className='ml-2 mt-0.5 text-14'>Como funciona</span>
              </button>
              <button className='flex flex-row justify-center items-center py-2.5' type="button" onMouseEnter={() => { playSong(songsName.TAP) }} onClick={props.openAboutModal}>
                <BsInfoCircle className='w-6 h-6' />
                <span className='ml-2 mt-0.5 text-14'>Sobre nós</span>
              </button>
              <button className='flex flex-row justify-center items-center py-2.5' type="button" onMouseEnter={() => { playSong(songsName.TAP) }} onClick={props.openQuestionsModal}>
                <BsQuestionCircle className='w-6 h-6' />
                <span className='ml-2 mt-0.5 text-14'>Dúvidas</span>
              </button>
              <button className='flex flex-row justify-center items-center py-2.5' type="button" onMouseEnter={() => { playSong(songsName.TAP) }} onClick={props.openContactUsModal}>
                <BsEnvelope className='w-6 h-6' />
                <span className='ml-2 mt-0.5 text-14'>Fale conosco</span>
              </button>
              <button className='flex flex-row justify-center items-center py-2.5' type="button" onMouseEnter={() => { playSong(songsName.TAP) }} onClick={props.openTermsOfUseModal}>
                <BsFileEarmarkText className='w-6 h-6' />
                <span className='ml-2 mt-0.5 text-14'>Termos de uso</span>
              </button>
              <button className='flex flex-row justify-center items-center py-2.5' type="button" onMouseEnter={() => { playSong(songsName.TAP) }} onClick={props.openPrivacyPolicyModal}>
                <BsShieldLock className='w-6 h-6' />
                <span className='ml-2 mt-0.5 text-14'>Política de Privacidade</span>
              </button>
              <button className='flex flex-row justify-center items-center py-2.5' type="button" onMouseEnter={() => { playSong(songsName.TAP) }} onClick={props.toggleBackgroundSong}>
                {props.isSongDisabled ? <BsPlayCircle className='w-6 h-6' /> : <BsPauseCircle className='w-6 h-6' />}
                <span className='ml-2 mt-0.5 text-14'>{props.isSongDisabled ? "Ativar música" : "Desativar música"}</span>
              </button>
            </>
          ) : null}

          {props.isLoggedIn ? (
            <div
              className={clsx(
                'w-full relative',
                !showConfirmLogout && 'mt-auto'
              )}
            >
              <button className='flex flex-row justify-center items-center py-2.5' type="button" onMouseEnter={() => { playSong(songsName.TAP) }} onClick={logoutHandler}>
                <BsBoxArrowRight className='w-6 h-6' />
                <span className='ml-2 mt-0.5 text-14'>Sair</span>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </ReactDragDrawer>
  )
}

export default DrawerMenu