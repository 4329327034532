import React from 'react'
import Drawer from "react-drag-drawer";
import { playSong } from '../../utils/song'
import songsName from '../../enums/songsName'
import { FiChevronLeft, FiX } from 'react-icons/fi';

const PrivacyPolicy = (props) => {
  const closeHandler = () => {
    playSong(songsName.TAP)
    props.close()
  }

  return (
    <Drawer
      dontApplyListeners={true}
      open={props.isOpened}
      onRequestClose={props.close}
      direction="left"
      modalElementClass="absolute top-0 left-0"
    >
      <div
        className={`w-screen max-w-lg h-screen bg-modal flex flex-col justify-start items-start px-4 pb-4 text-lg overflow-y-auto`}
        style={{
          paddingTop: window.native_app_status_bar_height ?? 16
        }}
      >
        <div className='w-full flex flex-row items-center justify-between mb-6'>
          <button
            type="button"
            className='text-black bg-transparent hover:bg-slate-200 rounded-lg text-2xl mb-1 -ml-2 mr-2'
            onClick={props.backToDrawerMenu}
          >
            <FiChevronLeft />
          </button>

          <div className='flex flex-1 flex-row items-center'>
            <h2 className='font-bold'>POLÍTICA DE PRIVACIDADE</h2>
          </div>

          <button
            type="button"
            onMouseEnter={() => {
              playSong(songsName.TAP)
            }}
            onClick={closeHandler}
            className="text-black bg-transparent hover:bg-slate-200 rounded-lg text-2xl lg:text-xl ml-auto"
          >
            <FiX />
          </button>
        </div>

        <div className='text-sm'>
          <div>
            <p className='font-bold'>1. OBJETIVO</p>
            <p className='mt-1'>A Graviola Digital garante aos Participantes Usuários do Conta Outra Vez! que está comprometida com o completo atendimento aos termos da Lei Geral de Proteção de Dados (LGPD), que estabelece diretrizes e regulamentações para a coleta, armazenamento, processamento e compartilhamento de dados pessoais. Este termo tem como objetivo dar transparência, esclarecer e divulgar as regras utilizadas pelo aplicativo em relação a informações, de modo que os Participantes Usuários possam escolher se querem ou não fornecer seus dados pessoais para assinar e utilizar o Conta Outra Vez!.</p>
          </div>

          <div className='mt-2'>
            <p className='font-bold'>2. INFORMAÇÕES COLETADAS E USO</p>
            <p className='mt-1'>Ao utilizar o Conta Outra Vez! poderão ser coletadas as informações e dados pessoais abaixo descritos, estritamente para o uso especificado.</p>
            
            <div className='ml-4'>
              <p className='mt-2 font-semibold'>2.1 Acesso sem login, simples navegação e uso dos jogos e passatempos</p>
              <p className='mt-1'>O acesso ao Conta Outra Vez! sem autenticação de usuário, navegação pelo conteúdo sem acesso efetivo aos livros e utilização dos jogos e passatempos não implicação solicitação e coleta de dados pessoais dos Participantes Usuários. Durante a navegação no Conta Outra Vez! poderão ser coletadas, como único objetivo de avaliar a usabilidade do aplicativo, informações gerais tais como tempo de navegação e áreas mais acessadas, sem distinção por usuário dos dados coletados.</p>
            </div>
            
            <div className='ml-4'>
              <p className='mt-2 font-semibold'>2.2 Associação a uma empresa parceira</p>
              <p className='mt-1'>Ao solicitar a associação a uma das empresas parceiras do Conta Outra Vez!, o Participante Usuário será solicitado a informar dados pessoais de acesso, definidos pela empresa parceira a que está vinculado, capazes de confirmar sua elegibilidade junto à empresa parceira. Estes dados poderão ser: CPF, telefone, e-mail, número/código de cliente, nome de usuário e senha cadastrados na empresa parceira. O Conta Outra Vez! informará estes dados à empresa parceira para que esta confirme a elegibilidade do Participante Usuário ao vínculo. Em caso negativo, o Conta Outra Vez! não armazenará nenhum destes dados.</p>
            </div>
            
            <div className='ml-4'>
              <p className='mt-2 font-semibold'>2.3 Assinatura</p>
              <p className='mt-1'>Caso opte pela assinatura do Conta Outra Vez!, a obtenção das seguintes informações será necessária:</p>
              
              <p className='ml-2 mt-2'>a) Dados pessoais do Participante Usuários, como nome, data de nascimento, telefone e CPF</p>
              <p className='ml-2 mt-2'>b) Número do Cartão de crédito: Coletamos o número do seu cartão de crédito para processar pagamentos de forma segura e eficiente.</p>
              <p className='ml-2 mt-2'>c) Nome Inscrito no Cartão: O nome exato registrado no cartão de crédito é necessário para correspondência com os detalhes do pagamento.</p>
              <p className='ml-2 mt-2'>d) Validade do Cartão: A data de validade do cartão é coletada para processar pagamentos precisos e atualizados.</p>
              <p className='ml-2 mt-2'>e) Código de Segurança (CVV): O código de segurança do cartão é coletado para garantir a segurança das transações e autenticar a titularidade do cartão.</p>

              <p className='mt-2'>O Conta Outra Vez! não compartilha informações com terceiros, exceto conforme especificado na seção "Compartilhamento de Informações de pagamento e terceiros envolvidos" desta Política de Privacidade. Todas as informações de pagamento são processadas por meio de um gateway de pagamento seguro, garantindo a proteção dos dados financeiros dos assinantes.</p>
            </div>

            <div className='ml-4'>
              <p className='mt-2 font-semibold'>2.4 Informações do dispositivo</p>
              <p className='mt-1'>Para melhorar a experiência do Participante Usuário, recursos e usabilidade, poderão ser coletadas informações como o tipo de dispositivo que utiliza o Conta Outra Vez!, o sistema operacional e sua versão. Nenhuma informação, acesso ou ação relativa ao dispositivo (acesso à galeria de imagens, envio de notificações, etc.) será coletada ou executada fora das permissões solicitadas na instalação do aplicativo.</p>
              <p className='mt-0.5'>É importante ressaltar que as informações coletadas são usadas exclusivamente para viabilizar e melhorar a experiência do Participante Usuário e eventualmente oferecer recursos personalizados.</p>
            </div>
          </div>

          <div className='mt-2'>
            <p className='font-bold'>3. COMPARTILHAMENTO DE INFORMAÇÕES DE PAGAMENTO E TERCEIROS ENVOLVIDOS</p>

            <p className='ml-4 mt-2'>3.1 Para processar os pagamentos, o Conta Outra Vez! utiliza um gateway de pagamento seguro. Ao optar por fazer um pagamento dentro do aplicativo, você estará sujeito aos termos e políticas de privacidade do provedor de serviços de pagamento, que podem ser consultados em https://privacidade.pagar.me/</p>
            <p className='ml-4 mt-2'>3.2 Ao optar por realizar pagamentos através do aplicativo, o Participante Usuário concorda ainda com a coleta e compartilhamento das informações necessárias para efetuar as transações mencionadas no item 2.3.</p>
            <p className='ml-4 mt-2'>3.3 As informações fornecidas para processar o pagamento serão usadas apenas para concluir a transação. Não armazenamos os detalhes completos do cartão de crédito em nossos servidores. As informações de pagamento são transferidas com segurança para o gateway de pagamento, onde são processadas de acordo com as melhores práticas de segurança do setor.</p>
            <p className='ml-4 mt-2'>3.4 É importante que o Participante Usuário tenha ciência que, a despeito da adoção de medidas rigorosas para garantir a segurança das informações de pagamento, nenhum método de transmissão de dados pela internet é completamente seguro. Ao fazer transações online, o Participante Usuário reconhece os riscos associados.</p>
          </div>

          <div className='mt-2'>
            <p className='font-bold'>4. DIVULGAÇÃO DE DADOS PESSOAIS PARA AUTORIDADES LEGAIS</p>
            <p className='mt-1'>O Conta Outra Vez!, quando solicitado, poderá divulgar seus dados pessoais para:</p>
            
            <p className='ml-4 mt-2'>a) Autoridades legais e regulatórias, mediante determinação ou para fins de relatar qualquer violação real ou potencial de lei ou regulamento aplicável;</p>
            <p className='ml-4 mt-2'>b) Qualquer parte relevante, agência de aplicação da lei ou tribunal, na medida necessária para o estabelecimento, exercício ou eficácia dos direitos legais;</p>
          </div>

          <div className='mt-2'>
            <p className='font-bold'>5. CONSENTIMENTO</p>
            <p className='mt-1'>Ao baixar e utilizar o Conta Outra Vez!, o Participante Usuário, além das permissões concedidas relativas aos acessos ao seu dispositivo, consente com a coleta e o uso de suas informações pessoais de acordo com esta Política de Privacidade. Informações são coletadas e processadas conforme necessário para proporcionar uma experiência de acesso e uso otimizada.</p>
          </div>

          <div className='mt-2'>
            <p className='font-bold'>6. ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE</p>
            <p className='mt-1'>Atualizações futuras nesta Política de Privacidade poderão ocorrer de acordo com alterações nas leis e regulamentações pertinentes. Recomendamos verificar periodicamente esta política para se manter informado(a) sobre eventuais mudanças.</p>
            <p className='mt-2'>Data da Última Atualização: 28/02/2025</p>
          </div>

          <div className='mt-2'>
            <p className='font-bold'>7. CONTATO</p>
            <p className='mt-1'>O Participante Usuário poderá solicitar correção, atualização ou a exclusão de seus dados pessoais, bem como solicitar uma cópia de seus dados pessoais armazenados no Conta Outra Vez! por meio do canal Fale Conosco, ressalvadas as hipóteses de guarda obrigatória de registros previstas em lei.</p>
          </div>

          <div className='mt-2'>
            <p className='font-bold'>8. LEI APLICÁVEL</p>
            <p className='mt-1'>Esta Política de Privacidade é regida e deverá ser interpretada de acordo com as leis da República Federativa do Brasil.</p>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default PrivacyPolicy