import React from 'react'
import Drawer from "react-drag-drawer";
import { playSong } from '../../utils/song'
import songsName from '../../enums/songsName'
import { FiChevronLeft, FiX } from 'react-icons/fi';

const KnowMoreModal = (props) => {
  const closeHandler = () => {
    playSong(songsName.TAP)
    props.close()
  }

  return (
    <Drawer
      open={props.isOpened}
      onRequestClose={props.close}
      direction="left"
      modalElementClass="absolute top-0 left-0"
    >
      <div
        className='w-screen max-w-lg h-screen bg-modal flex flex-col justify-start items-start px-4 text-lg overflow-y-auto'
        style={{
          paddingTop: window.native_app_status_bar_height ?? 16,
          paddingBottom: window.native_app_navigation_bar_height ?? 16
        }}
      >
        <div className='w-full flex flex-row items-center justify-between mb-6'>
          <button
            type="button"
            className='text-black bg-transparent hover:bg-slate-200 rounded-lg text-2xl mb-1 -ml-2 mr-2'
            onClick={props.backToDrawerMenu}
          >
            <FiChevronLeft />
          </button>

          <div className='flex flex-1 flex-row items-center'>
            <h2 className='font-bold'>COMO FUNCIONA</h2>
          </div>

          <button
            type="button"
            onMouseEnter={() => {
              playSong(songsName.TAP)
            }}
            onClick={closeHandler}
            className="text-black bg-transparent hover:bg-slate-200 rounded-lg text-2xl lg:text-xl ml-auto"
          >
            <FiX />
          </button>
        </div>

        <div className='text-sm'>
          <p className='mb-4'>Você pode ler seu livro online aqui mesmo na plataforma, se estiver conectado à internet, e pode também ouvir a narração da história.</p>
          <p className='mb-4'>Se preferir baixar para ler offline, você vai precisar de um leitor digital (e-reader). Há muitas opções gratuitas em sua loja de aplicativos. Recomendamos o Play Livros, para celulares e tablets Android, o Livros, para celulares e tablets iOS e o Readium ou Adobe Digital Editions, para computadores. Se o livro não for carregado diretamente para a biblioteca do seu leitor digital, procure-o na área de downloads do seu dispositivo e faça o carregamento manual.</p>
          <p>Você tem 12 meses, a partir da disponibilização do livro, para fazer o download. Durante este período, você pode ler e ouvir online sempre que quiser. Os livros baixados são seus, mas lembre-se que todo o material é protegido por direitos autorais, por isso é proibido enviar a terceiros, reproduzir, distribuir e comercializar.</p>
        </div>
      </div>
    </Drawer>
  )
}

export default KnowMoreModal