import React from 'react'
import Drawer from "react-drag-drawer";
import { playSong } from '../../utils/song'
import songsName from '../../enums/songsName'
import { FiChevronLeft, FiX } from 'react-icons/fi';

const TermsOfUse = (props) => {
  const closeHandler = () => {
    playSong(songsName.TAP)
    props.close()
  }

  return (
    <Drawer
      dontApplyListeners={true}
      open={props.isOpened}
      onRequestClose={props.close}
      direction="left"
      modalElementClass="absolute top-0 left-0"
    >
      <div
        className={`w-screen max-w-lg h-screen bg-modal flex flex-col justify-start items-start px-4 pb-4 text-lg overflow-y-auto`}
        style={{
          paddingTop: window.native_app_status_bar_height ?? 16
        }}
      >
        <div className='w-full flex flex-row items-center justify-between mb-6'>
          <button
            type="button"
            className='text-black bg-transparent hover:bg-slate-200 rounded-lg text-2xl mb-1 -ml-2 mr-2'
            onClick={props.backToDrawerMenu}
          >
            <FiChevronLeft />
          </button>

          <div className='flex flex-1 flex-row items-center'>
            <h2 className='font-bold'>TERMOS DE USO</h2>
          </div>

          <button
            type="button"
            onMouseEnter={() => {
              playSong(songsName.TAP)
            }}
            onClick={closeHandler}
            className="text-black bg-transparent hover:bg-slate-200 rounded-lg text-2xl lg:text-xl ml-auto"
          >
            <FiX />
          </button>
        </div>

        <div className='text-sm'>
          <div>
            <p className='font-bold'>1. OBJETO</p>
            <p className='mt-1'>Os Temos de Uso regulam as condições de acesso e uso do Conta Outra Vez!, aplicação de distribuição de livros infantis ilustrados operada pela Graviola Digital que permite a seus usuários, doravante Participantes Usuários, receber mensalmente um ou mais títulos por eles adquiridos, em formato eletrônico (livros digitais), que poderão ser baixados pelos Participantes Usuários para leitura em seus dispositivos eletrônicos com acesso à internet, como smartphones e tablets. </p>
            <p className='mt-1'>Os Termo de Uso ora apresentados seguem, dentre outros, os princípios da informação, transparência e boa-fé, observando as leis nº 8.078/1990 e nº 10.406/2002.</p>
            <p className='mt-1'>Os Participantes Usuários declaram que ao acessarem e utilizarem o Conta Outra Vez! leram integralmente e aceitaram as condições dos Termos de uso na forma como aqui expressos.</p>
          </div>

          <div className='mt-2'>
            <p className='font-bold'>2. ACESSO</p>

            <p className='mt-1'>2.1. O Conta Outra Vez! é de adesão voluntária pelos Participantes Usuários, e pode ser acessado por meio da plataforma web e por meio de aplicativo para smartphone.  É de eleição do Participante Usuário baixar e instalar o aplicativo em um aparelho compatível, de acordo com as políticas próprias das lojas. </p>
            <p className='mt-1'>2.2. Os Participantes Usuários que optarem pelo download e a instalação do aplicativo Conta Outra Vez! deverá estar ciente de que estas ações dependem de tráfego de dados, estando, pois, sujeitas à consumo de seu pacote de dados, tarifação e encargos pela operadora de banda larga ou telefonia móvel.</p>
            <p className='mt-1'>2.3. Os Participantes Usuários que utilizarem o Conta Outra Vez! deverão estar cientes que a navegação, a leitura online e o download de livros no app e plataforma web, poderão envolver tráfego de dados, estando, pois, sujeita ao consumo de seu pacote de dados, tarifação e encargos pela operadora de banda larga ou telefonia móvel.</p>
            <p className='mt-1'>2.4. Poderão ser Participantes Usuários somente as pessoas jurídicas e as pessoas físicas capazes maiores de 18 (dezoito) anos.            </p>
          </div>

          <div className='mt-2'>
            <p className='font-bold'>3. PERMISSÕES</p>

            <p className='mt-1'>3.1. Ao instalar o aplicativo do Conta Outra Vez!, o Participante Usuário deverá concordar com as permissões obrigatórias a ele solicitadas. Em acordo com a Política de Privacidade do Conta Outra Vez! e respeito à Lei Geral de Proteção de Dados, a Graviola Digital declara que somente fará uso das permissões concedidas para viabilizar e melhorar a experiência dos Participantes Usuários no uso do aplicativo. O aceite das permissões eletivas, tanto no aplicativo quanto na plataforma web, serão a critério do Participante Usuário. </p>
            <p className='mt-1'>3.2. A concessão das permissões obrigatórias solicitadas é requisito para a instalação e uso do aplicativo. A não concessão das permissões ou seu cancelamento impede o Participante Usuário de acessar ou continuar acessando o Conta Outra Vez!, por meio do aplicativo, porém não haverá restrições para o uso da plataforma web.</p>
            <p className='mt-1'>3.3. É direito dos Participantes Usuários que concederam permissão para o envio de notificação, no aplicativo ou plataforma web, a qualquer tempo e sem necessidade de justificativa prévia, solicitar a suspensão do envio de notificações pelo Conta Outra Vez!, removendo a permissão de seus dispositivos de acesso. A suspensão de comunicados não cancela a elegibilidade dos Participantes Usuários, que poderão, a qualquer momento, restaurar o envio de notificações pelo Conta Outra Vez!.</p>
          </div>
          
          <div className='mt-2'>
            <p className='font-bold'>4. USO DA APLICAÇÃO</p>

            <p className='mt-1'>4.1. Os Participantes Usuários, nos termos da legislação aplicável à espécie, declaram, desde já, que, a partir do momento em que se habilitam para o uso do Conta Outra Vez! seja por meio da plataforma web ou aplicativo, e consequente aceitação do Termos de Uso, são responsáveis pelos seus atos na utilização do Conta Outra Vez!, no caso de qualquer conduta ilícita, na forma das legislações vigentes, bem como pela eventual utilização indevida ou por propagação do conteúdo  adquirido, incluindo, mas não se limitando, a imagens, sons, vozes, textos, arquivos e códigos sem prévia e expressa autorização.</p>
            <p className='mt-1'>4.2. Após ciência de todos os termos dos Termo de Uso, o Participante Usuário poderá ter acesso ao conteúdo restrito caso seja elegível, por meio de assinatura ou de indicação de empresa parceira da Graviola Digital, sendo de sua inteira faculdade baixar os títulos oferecidos, observando os prazos previstos no item 8.3.</p>
          </div>

          <div className='mt-2'>
            <p className='font-bold'>5. ELEGIBILIDADE AO CONTEÚDO RESTRITO</p>

            <p className='mt-1'>5.1. A despeito do acesso ao Conta Outra Vez ser gratuito, a aquisição dos livros para leitura online e download é restrita e está condicionada à elegibilidade do Participante Usuário por meio de assinatura, disponível exclusivamente no aplicativo, ou indicação de elegibilidade por empresa parceira da Graviola Digital, a critério exclusivamente da empresa, e válida para a plataforma web e aplicativo indistintamente.</p>
            <p className='mt-1'>5.2. Os Participantes Usuários elegíveis ao conteúdo restrito por meio de associação com empresa parceira da Graviola Digital deverão informar a qual empresa estão vinculados, bem como suas credenciais de acesso, fornecidas pela empresa parceira, para ter acesso efetivo à aquisição de livros.</p>
            <p className='mt-1'>5.3. Poderá, a qualquer momento, a critério exclusivo e iniciativa da empresa parceira da Graviola Digital a qual o Participante Usuário está vinculado, haver o cancelamento da elegibilidade. O cancelamento acarretará o não recebimento de novos títulos pelo Participante Usuário.</p>
            <p className='mt-1'>5.4. O cancelamento da elegibilidade do Participante Usuário não afeta o acesso e a leitura dos conteúdos já adquiridos, observados os prazo e condições do item 8.3, sendo de propriedade do Participante Usuário os títulos por ele baixados a qualquer tempo. O cancelamento da elegibilidade do Participante Usuário não impede o download futuro, até a data especificada no Conta Outra Vez! para cada título, nunca inferior a um ano, dos livros disponibilizados durante o período de elegibilidade.  </p>
          </div>

          <div className='mt-2'>
            <p className='font-bold'>6. ASSINATURA</p>

            <p className='mt-1'>6.1. Os Participantes Usuários sem vínculo com empresas parceiras da Graviola Digital que desejarem ter acesso ao conteúdo restrito poderão fazer uma assinatura para aquisição mensal de livros, disponível exclusivamente no aplicativo, mas que permite o acesso aos livros adquiridos tanto no aplicativo quanto na plataforma web. </p>
            <p className='mt-1'>6.2. O Participante Usuário terá a opção de dois tipos de assinatura, adquirindo um ou dois livros mensalmente. A qualquer momento o usuário poderá alterar o tipo de assinatura efetuada.</p>
            <p className='mt-1'>6.3. Para efetivar a assinatura, o Participante Usuário deverá informar dados pessoais e de pagamento (dados do cartão de crédito). O processamento das cobranças será feito por um gateway de pagamentos seguro. </p>
            <p className='mt-1'>6.4. A Graviola Digital está comprometida com os termos da LGPD e com a proteção dos dados pessoais e de pagamento dos Participantes Usuários. Ao optar por fazer uma assinatura e informar dados, o Participante Usuário deverá estar ciente e de acordo com a Política de Privacidade do Conta Outra Vez!</p>
            <p className='mt-1'>6.5. O Participante Usuário assinante poderá fazer o cancelamento da assinatura a qualquer tempo no aplicativo. O cancelamento será processado imediatamente, observado os prazos limite de fechamento da fatura do cartão de crédito do Participante Usuário, o que poderá ensejar uma cobrança adicional depois do cancelamento caso a fatura já esteja fechada.</p>
            <p className='mt-1'>6.6. O cancelamento da assinatura pelo Participante Usuário não afeta o acesso e a leitura dos conteúdos já adquiridos, observados os prazo e condições do item 8.3, sendo de propriedade do Participante Usuário os títulos por ele baixados a qualquer tempo. O cancelamento da assinatura pelo Participante Usuário não impede o download futuro, até a data especificada no Conta Outra Vez! para cada título, nunca inferior a um ano, dos livros disponibilizados durante o período de assinatura.  </p>
          </div>

          <div className='mt-2'>
            <p className='font-bold'>7. DISPONIBILIDADE DE TÍTULOS</p>

            <p className='mt-1'>7.1. O Conta Outra Vez! pratica o modelo de curadoria, selecionando por meio de uma equipe especializada os títulos a serem adquiridos mensalmente pelos Participantes Usuários.</p>
            <p className='mt-1'>7.2. Ao se habilitarem ao uso do Conta Outra Vez! e exercerem a elegibilidade à aquisição dos livros, seja por assinatura ou associação a empresa parceira da Graviola Digital, os Participantes Usuários concordam em usufruir da curadoria e aceitar seus critérios para a seleção de títulos, adquirindo os livros por ela indicados.</p>
            <p className='mt-1'>7.3. A curadoria é baseada em fatores como relevância do conteúdo, aspectos estéticos, pedagógicos e sociais, linguagem, adequação de conteúdo e forma ao público infantil, adaptabilidade ao formato digital, potencial do interesse do leitor infantil, entre outros. A seleção de títulos é critério exclusivo do Conta Outra Vez!, não cabendo aos Participantes Usuários pleitear a troca dos títulos adquiridos por outros de sua eleição, bem como devolução, compensação ou indenização por títulos que não atendam às suas expectativas ou gostos pessoais.</p>
            <p className='mt-1'>7.4. A despeito da criteriosa curadoria de conteúdo, o Conta Outra Vez! não se responsabiliza, em hipótese alguma, por quaisquer danos que a exposição do conteúdo possa causar, particularmente sobre público inadequado, bem como o acesso a plataforma por menores de 18 (dezoito) anos, cabendo ao Participante Usuário, no papel de mediador, fazer a avaliação sobre a adequação e conveniência da exposição do conteúdo, sobretudo ao público infantil.</p>
          </div>

          <div className='mt-2'>
            <p className='font-bold'>8. DOWNLOAD DE LIVROS E RECURSOS ASSOCIADOS</p>

            <p className='mt-1'>8.1. Os títulos que Participante Usuário tiver adquirido poderão ser lidos online e baixados para leitura offline. Os Participantes devem ter ciência de que é necessário fazer o download dos livros até a data limite estabelecida no Conta Outra Vez! para cada livro, nunca inferior a um ano, para manter o acesso definitivo aos títulos adquiridos.</p>
            <p className='mt-1'>8.2. Antes de baixar qualquer título, o Participante Usuário deverá certificar-se de que está ciente e concorda com os Termos de Uso. Ao fazer o download, o Participante Usuário concordará automaticamente que houve expressa aceitação e concordância de todas as regras, termos e condições previstos neste Termo. Não será possível seguir com a utilização do Conta Outra Vez!, mesmo em ações que não envolva o download dos livros, caso haja qualquer discordância aos presentes termos. </p>
            <p className='mt-1'>8.3. Cada título adquirido pelos Participante Usuários estará disponível para download até a data limite indicada na plataforma web e no aplicativo, nunca inferior a um (1) ano a contar da data de disponibilização, nos termos do que dispõe o art. 3º, inciso II do Decreto nº 7.962 de 15 de março de 2013. Após este período, não será possível para o Participante Usuário baixar os títulos adquiridos. Os títulos oferecidos como cortesia ao Usuário Participante no momento da efetivação da sua elegibilidade, seja por conclusão de assinatura ou autenticação como usuário elegível de empresa parceira da Graviola Digital, poderão ter prazo limite para download inferior a um ano.</p>
            <p className='mt-1'>8.4. O Participante Usuário que optar por excluir o download de um livro poderá fazer o download novamente desde que a data limite, conforme o item 8.3, não esteja expirada. Caso a data limite já esteja expirada, não será possível fazer novamente o download, prevalecendo as condições dos itens 8.3. e 8.7.</p>
            <p className='mt-1'>8.5. O Participante Usuário elegível se declara proprietário dos exemplares a que tiver acesso na plataforma web Conta Outra Vez! ou aplicativo.</p>
            <p className='mt-1'>8.6. É de escolha e responsabilidade exclusivas dos Participantes Usuários fornecer acesso ao conteúdo oferecido aos menores de 18 anos de idade que assiste ou representa, nos termos da legislação civil brasileira, perante os quais o Participante Usuário desempenhará o papel de mediador, observando o disposto no art. 73 da lei nº 8.069/1990.</p>
            <p className='mt-1'>8.7.  Considerando os princípios da informação e transparência, a perda de acesso a títulos adquiridos não baixados pelo Participante Usuário até a expiração da data de disponibilidade não dará direito a nenhuma compensação, indenização ou reparação.</p>
          </div>

          <div className='mt-2'>
            <p className='font-bold'>9. PROPRIEDADE INTELECTUAL E LIMITAÇÕES DE USO</p>

            <p className='mt-1'>9.1. Todos os conteúdos disponibilizados pelo Conta Outra Vez!, incluindo a própria aplicação, estão protegidos pelas Leis de Direitos Autorais e Propriedade Intelectual.</p>
            <p className='mt-1'>9.2. O Participante Usuário é o proprietário dos livros por ele adquiridos, sendo que, por estarem estes protegidos pelas leis de direitos autorias, é expressamente proibido ao Participante Usuário reproduzir, ainda que parcialmente, transmitir, veicular, ceder, negociar ou distribuir qualquer título.</p>
            <p className='mt-1'>9.3. Ao fazer download de qualquer título, o Participante Usuário concorda em zelar pelos títulos baixados e respeitar os direitos autorais e a propriedade intelectual de autores e da aplicação, assumindo civil e criminalmente a responsabilidade por eventuais violações e danos de natureza moral e material causados a outrem, independente de natureza e extensão, inclusive nas hipóteses de regresso.</p>
            <p className='mt-1'>9.4. O Conta Outra Vez! é de uso pessoal e não comercial. Os Participantes Usuários, elegíveis ou não ao conteúdo restrito, poderão ser excluídos e/ou ter o acesso suspenso, definitiva ou temporariamente, pelos seguintes motivos, dentre outros:</p>
            
            <div className='ml-4'>
              <p className='mt-1'>a. copiar, publicar, compartilhar, ceder, comercializar, fazer uso não pessoal ou imprimir os títulos baixados;</p>
              <p className='mt-1'>b. extrair do aplicativo os títulos através dele baixados;</p>
              <p className='mt-1'>c. modificar, adaptar, licenciar, transferir, editar, publicar, traduzir, promover publicação em qualquer outro meio de comunicação, criar outras obras derivadas das disponibilizadas, ou de qualquer forma explorar ou tirar proveito do conteúdo digital do Conta Outra Vez!, incluindo mas não se limitando a imagens, jogos, vozes, sons, livros, textos e arquivos.</p>
              <p className='mt-1'>d. comercializar, locar ou transferir o acesso ao Conta Outra Vez!;</p>
              <p className='mt-1'>e. adaptar, modificar, comercializar, distribuir, realizar engenharia no código fonte da plataforma web ou arquivo, ou praticar qualquer ato que comprometa, total o parcialmente, seu funcionamento, disponibilidade e/ou desempenho;</p>
              <p className='mt-1'>f. identificação de qualquer indício ou prova de vício, fraude ou irregularidade na participação Participante Usuário;</p>
              <p className='mt-1'>g. por solicitação dos próprios Participantes Usuários ou de responsável devidamente habilitado;</p>
              <p className='mt-1'>h. por solicitação judicial ou de autoridade competente.</p>
              <p className='mt-1'>i. se apurado o mau uso do Conta Outra Vez!, além de infringir a legislação aplicável, especialmente de natureza cível e criminal, bem como afronta aos princípios éticos, morais e de boa-fé, ou caso haja violação de alguma das disposições contidas nos Termos de Uso.</p>
            </div>
          </div>

          <div className='mt-2'>
            <p className='font-bold'>10. PRIVACIDADE E PROTEÇÃO DE DADOS</p>

            <p className='mt-1'>10.1. A Graviola Digital declara que está comprometida com os termos da LGPD e zela pela segurança das informações dos Participantes Usuários. Os compromissos da Graviola Digital em relação à privacidade e proteção de dados dos Participantes, bem como o detalhamento completo sobre captura de dados, segurança da informação compartilhamento de dados pode ser consultado na Política de Privacidade do Conta Outra Vez!.</p>
            <p className='mt-1'>10.2. O Conta Outra Vez! não armazena nenhum dado pessoal dos Usuários Participantes que não seja fundamental para o acesso e uso do aplicativo e não compartilha ou cede a terceiros dados pessoais dos Participantes Usuários, exceto nos casos expressamente previstos na Política de Privacidade do aplicativo.</p>
          </div>

          <div className='mt-2'>
            <p className='font-bold'>11. DESCONTINUIDADE</p>

            <p className='mt-1'>11.1. A Graviola Digital reserva-se o direito de, a qualquer momento e a seu exclusivo critério, descontinuar, suspender ou terminar o Conta Outra Vez! ou uma de suas modalidades de acesso e uso como meio de aquisição de livros, ou ainda, alterar a quantidade, o formato e a periodicidade dos títulos disponibilizados, mantendo a disponibilidade prevista no item 8.3. </p>
            <p className='mt-1'>11.2. Para hipótese de encerramento do Conta Outra Vez!, ter-se-á prévia comunicação de pelo menos 30 (trinta) dias aos Participantes Usuários.  </p>
            <p className='mt-1'>11.3. Em caso de descontinuidade do Conta Outra Vez! será oferecido aos usuários meio alternativo de acesso e leitura aos títulos adquiridos e baixados de forma independente da aplicação. </p>
          </div>

          <div className='mt-2'>
            <p className='font-bold'>12.  DISPOSIÇÕES GERAIS</p>

            <p className='mt-1'>12.1. É direito dos Participantes Usuários, a qualquer tempo e sem necessidade de justificativa prévia, solicitar a suspensão do envio de comunicações pelo Conta Outra Vez! mediante comunicação expressa, diretamente ao Conta Outra Vez! por meio da seção Fale Conosco ou aos canais de relacionamento da empresa parceira a qual esteja vinculado. A suspensão de comunicados não cancela a elegibilidade dos Participantes Usuários, que poderão, a qualquer momento, enquanto elegíveis, solicitar restabelecimento do envio de comunicações do Conta Outra Vez!</p>
            <p className='mt-1'>12.1. Os Participantes Usuários declaram que tem conhecimento de que o Conta Outra Vez! não é responsável pelos conteúdos distribuídos, que são de responsabilidade única das editoras e dos autores dos títulos.</p>
            <p className='mt-1'>12.3. Os Participantes Usuários reconhecem o direito do Conta Outra Vez!, a qualquer tempo e por ato unilateral, de aditar, modificar ou atualizar as regras, termos e condições destes Termos de Uso. Qualquer aditamento, modificação ou atualização serão previamente comunicados aos Participantes Usuários, por meio da aplicação. Caso os Participantes Usuários continuem a usar o Conta Outra Vez! depois do aviso de aditamento, modificação ou atualização destes Termos Gerais, isso caracterizará a aceitação das alterações implementadas. Excluem-se as modificações que gerem ônus ou novas cobranças ao usuário.</p>
            <p className='mt-1'>12.4. O Conta Outra Vez! poderá, a qualquer tempo e por ato unilateral, ter sua gestão transferida mediante prévia comunicação aos Participantes Usuários. Em qualquer hipótese, será resguardada eventual operação comercial já concluída pelos Participantes Usuários até a data da suspensão, extinção ou modificação.</p>
            <p className='mt-1'>12.5. Os Participantes reconhecem a obrigatoriedade de observar as leis de proteção ao direito autoral e propriedade intelectual e concordam em fazê-lo, estando sujeitos, em contrário, às penalidades cíveis e criminais aplicáveis.</p>
            <p className='mt-1'>12.6. Os casos omissos nos Termos de Uso serão tratados diretamente entre os Participantes Usuários e a Graviola Digital, observando a legislação pertinente.</p>
            <p className='mt-1'>12.7. Qualquer tolerância ao descumprimento ou omissão na execução de seus direitos por parte da Graviola Digital, não constituirá renúncia, ineficácia ou novação dos direitos e obrigações ora pactuados, nem impedirá que a Graviola Digital ou qualquer dos autores e/ou editores de livros comercializados na aplicação, ainda que extemporaneamente, requeira o cumprimento de tais direitos e obrigações, sendo certo que qualquer exceção só se tornará válida- desde que expressamente concedida pela Graviola Digital ou qualquer dos parceiros disponibilizados por meio da plataforma.</p>
            <p className='mt-1'>12.8. A Graviola Digital não será considerada em mora ou inadimplente em relação a qualquer direito ou obrigação previstos nestes Termos de Uso se o motivo do descumprimento decorrer de caso fortuito ou força maior, na forma estabelecida pelo Código Civil Brasileiro, Lei. nº 10.406, de 10 de janeiro de 2002 e pelo Código de Proteção e Defesa do Consumidor, Lei nº 8.078 de 11 de setembro de 1990.</p>
            <p className='mt-1'>12.9. Na hipótese de qualquer das regras, termos ou condições aqui dispostos ser considerado, por qualquer motivo, por juízo ou árbitro competente, inválido, inexequível ou ilegal, no todo ou em parte, a validade e exequibilidade das demais regras, termos e condições, ou de partes deles, não serão afetadas, pois, trata-se de regras independentes entre si, nos termos do que dispõe o art. 51, § 2º da lei 8.078/90.</p>
            <p className='mt-1'>12.10. Independentemente do local no território, nacional ou internacional, que os Participantes Usuários tenham acesso à aplicação Conta Outra Vez!, estes Termos de Uso serão regidos, interpretados e executados de acordo com as leis da República Federativa do Brasil, independentemente dos conflitos dessas leis com leis de outros estados ou países, sendo competente a Justiça Brasileira para dirimir qualquer dúvida decorrente deste instrumento. O aceite ao presente termo estabelece a renúncia dos Participantes Usuários à competência de qualquer outra autoridade judiciária, FICANDO DESDE JÁ ELEITO O FORO DO RIO DE JANEIRO como competente para dirimir qualquer questão oriunda do presente Regulamento, por mais privilegiado que seja ou venha a ser.</p>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default TermsOfUse