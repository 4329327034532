import React, { useState, useRef, useEffect } from "react";
import TweenOne from "rc-tween-one";
import "./../../assets/styles/Review.css";
import reviewLogoPng from "./../../assets/images/review-logo-1.svg";

const Review = props => {

  let BOOK = 0;
  const isSurprise = props.appState === "firsttimevisit" ? true : false;
  const currentBookID = props.currentBookID;
  for (let i = 0; i < props.payload.listData.length; i++) {
    const element = props.payload.listData[i];
    if (element.bookId === currentBookID) {
      BOOK = i;
    }
  }
  const ITEM = props.payload.listData[BOOK];
  const REVIEW_CONTENT = ITEM.bookReviewText || "";
  const REVIEW_AUTHOR = ITEM.bookReviewAuthorName || "";
  const REVIEW_ABOUT = ITEM.bookReviewAuthorAbout || "";

  const [heightState, setHeightState] = useState(0);

  useEffect(() => {
    
    setTimeout(() => {
      const textContent1 = document.getElementById('review__text').clientHeight;
      const textContent2 = document.getElementById('review__author_title').clientHeight;
      const textContent3 = document.getElementById('review__author_desc').clientHeight;
      const totalHeight = textContent1+textContent2+textContent3;
      setHeightState(totalHeight);
    }, 0);
    
  }, [ITEM]);

  return (
    <TweenOne
      id="review"
      className="review_container" //{`review_container ${isSurprise ? "hide" : ""}`}
      paused={isSurprise}
      animation={{
        opacity: 1
      }}
    >

      <div className="review__section">

        <div className={`review`}>
          <img className="review__image" src={reviewLogoPng} />
        </div>

        <div
          style={{ maxHeight:heightState }}
          className="review__content"
        >
          <div
            id="review__text"
            className="review__text"
            dangerouslySetInnerHTML={{ __html: `<p>${REVIEW_CONTENT}</p>` }}
            />

          <div
            id="review__author_title"
            className="review__author_title"
            dangerouslySetInnerHTML={{ __html: `<p>${REVIEW_AUTHOR}</p>` }}
            />

          <div
            id="review__author_desc"
            className="review__author_desc"
            dangerouslySetInnerHTML={{ __html: `<p>${REVIEW_ABOUT}</p>` }}
          />

        </div>

      </div>

    </TweenOne>
  );
};

export default Review;
