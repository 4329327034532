import { Formik } from 'formik'
import React from 'react'
import Button from '../../button'
import { playSong } from '../../../utils/song'
import songsName from '../../../enums/songsName'
import toast from 'react-hot-toast'
import { PRODUCT } from '../../../consts/product'
import api from '../../../services/api'

const RecoveryPassword = (props) => {
  return (
    <>
      <div className='w-full flex flex-row justify-start items-center mb-4 cursor-pointer'>
        <span>Recuperar senha</span>
      </div>
    
      <Formik
        initialValues={{
          temppwd: '',
          newpwd: ''
        }}
        validate={(values) => {
          const errors = {}

          console.log({values})

          if (values.temppwd.length === 0) {
            errors.temppwd = "O código é obrigatório"
          } else if (!new RegExp(/[\w]{6}/).test(values.temppwd)) {
            errors.temppwd = "O código é composto por seis números"
          }

          if (!new RegExp(/^.{3,20}$/).test(values.newpwd)) {
            errors.newpwd = "A nova senha deve posuir entre três e vinte caracteres"
          }

          return errors
        }}
        onSubmit={async ({ temppwd, newpwd }) => {
          await api.post("/vamole/app/password", {
            "email": props.email,
            "temppwd": temppwd,
            "newpwd": newpwd,
            "produto": PRODUCT
          })

          toast("Recuperação da senha realizada com sucesso")
          props.close()
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className='w-full flex flex-col gap-y-2' onSubmit={handleSubmit}>
            <div className="w-full">
              <input
                className="bg-gray-200 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                name="code"
                disabled
                value={props.email}
                placeholder="E-mail"
              />
              {errors.email && touched.email && (
                <p className="text-sm text-red-500">{errors.email}</p>
              )}
            </div>

            <div className="w-full">
              <input
                className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                name="temppwd"
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                onChange={e => {
                  if (e.target.value.length <= 6) {
                    handleChange(e)
                  }
                }}
                onBlur={handleBlur}
                value={values.temppwd}
                placeholder="Código"
              />
              {errors.temppwd && touched.temppwd && (
                <p className="text-sm text-red-500">{errors.temppwd}</p>
              )}
            </div>

            <div className="w-full">
              <input
                className="bg-gray-50 border border-gray-primary text-sm rounded-lg block w-full p-2.5"
                name="newpwd"
                type='password'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newpwd}
                placeholder="Nova senha"
              />
              {errors.newpwd && touched.newpwd && (
                <p className="text-sm text-red-500">{errors.newpwd}</p>
              )}
            </div>

            <Button
              disabled={isSubmitting}
              type="submit"
              onMouseEnter={() => {
                playSong(songsName.TAP)
              }}
              onClick={() => {
                playSong(songsName.TAP)
              }}
            >
              Enviar
            </Button>
          </form>
        )}
      </Formik>
    </>
  )
}

export default RecoveryPassword